import cookies from 'ks-utilities/lib/cookies';

const installIdCookieName: string = 'installId';
const installIdIOSCookieName: string = 'installID';
export const emptyInstallId = '-1';

export function getInstallId(): string {
  return cookies.get(installIdCookieName) || cookies.get(installIdIOSCookieName) || '';
}

// PCM needs '-1' if no install id 
export function getInstallIdForPCM(): string {
  return getInstallId() || emptyInstallId;
}
