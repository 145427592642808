export enum BadgeType {
  LOAN = 'LOAN',
  BONUS = 'BONUS',
}

export enum BadgeSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export const sortConfigBadges = [
  BadgeType.LOAN,
  BadgeType.BONUS,
];

export const widthMobile = {
  [BadgeSize.LARGE]: 55,
  [BadgeSize.MEDIUM]: 40,
  [BadgeSize.SMALL]: 40,
};

export const widthDesktop = {
  [BadgeSize.LARGE]: 84,
  [BadgeSize.MEDIUM]: 65,
  [BadgeSize.SMALL]: 55,
};
