import { setTimeZone } from 'ks-utilities/lib/setTimeZone';

import { DeliveryOptions } from '../../const/DeliveryOptions';
import { localeDateService } from '../../services/localeDateStringService';

interface ISetDeliveryDateOfTeaser {
  deliveryOptions: Record<string, IOfferOption>;
  translation: IDeliveryDateTeaserTranslation;
  isBestPrice?: boolean;
}

interface IDeliveryDateTranslation {
  today: string;
  tomorrow: string;
}

export interface IDeliveryDateTeaserTranslation
  extends IDeliveryDateTranslation {
  have: string;
  deliveryTeaserTitle: string;
  pickupTeaserTitle: string;
  deliveryExpress: string;
}

interface IDeliveryDateTeaser {
  delivery: string;
  deliveryDate: string;
}

export function makeCapitalizeFirstLetter(deliveryText: string): string {
  return deliveryText.charAt(0).toUpperCase() + deliveryText.slice(1);
}

export function getItemCardDeliveryDate(
  delivery: string,
  t: IDeliveryDateTranslation
) {
  const dateISO = setTimeZone(delivery);

  return localeDateService.getFormattedDeliveryDate(dateISO, {
    today: t.today,
    tomorrow: t.tomorrow,
  });
}

export function convertDeliveryDateOfTeaser({
  deliveryOptions,
  translation,
  isBestPrice,
}: ISetDeliveryDateOfTeaser): IDeliveryDateTeaser {
  const result = {} as IDeliveryDateTeaser;
  const deliveryDate = deliveryOptions[DeliveryOptions.delivery]?.delivery;
  const pickupDate = deliveryOptions[DeliveryOptions.pickup]?.delivery;

  if (!deliveryDate && !pickupDate) {
    return result;
  }
  const deliveryOptionType = deliveryDate
    ? DeliveryOptions.delivery
    : DeliveryOptions.pickup;
  const date = deliveryDate || pickupDate;

  const deliveryTitle =
    deliveryOptionType === DeliveryOptions.delivery
      ? translation.deliveryTeaserTitle
      : translation.pickupTeaserTitle;

  result.deliveryDate = getItemCardDeliveryDate(date, translation);

  result.delivery = isBestPrice
    ? makeCapitalizeFirstLetter(deliveryTitle)
    : deliveryTitle;

  // add have before
  if (!isBestPrice) {
    result.delivery = `${translation.have} ${result.delivery}`;
  }

  return result;
}
