
export class CardModel {
  card: IItemCard;

  constructor(card = null) {
    this.card = card || this.createInitialState();
  }

  createInitialState() {
    const newCard = {
      id: '',
      title: '**********',
      priceFormatted: ' ',
      mods: '_skeleton',
      category: [
        '**********',
        '**********',
        '**********',
      ],
      previewImages: [
        {
          small: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="1" width="1"%3E%3C/svg%3E',
          medium: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="1" width="1"%3E%3C/svg%3E',
          large: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="1" width="1"%3E%3C/svg%3E'
        },
      ],
      unitPrice: null,
      reviewsQuantity: null,
      creditMonthlyPrice: null,
      monthlyInstallment: {
        id: null,
        formattedPerMonth: ' ',
      }
    };

    return newCard;
  }
}
