import { StartedByTypes } from '../const/StartedByTypes';

export function getPcmCrossSellData(data: IParamCrossSellBackdropData) {
  if (!data) {
    return;
  }

  return {
    cross_sell_backdrop_product_id: data.mainProduct?.id,
    cross_sell_backdrop_product_name: data.mainProduct?.name,
    cross_sell_backdrop_listing_position: data.listingPosition,
    cross_sell_backdrop_count: data.count,
    supplier: data.supplier?.name,
    supplier_id: data.supplier?.id,
    block_id: data.pcm?.blockId,
    block_name: data.pcm?.blockName,
    category_id: data.pcm?.categoryId,
    started_by: StartedByTypes.CrossSellBackdrop,
  };
}
