import { extendExtract } from 'ks-utilities/lib/extract';
import randomString from 'ks-utilities/lib/randomString';

import { IGetShareCartRequest } from '../common/apiProvider/ProductCartApiProvider';
import { BFF, GATEWAY } from '../common/const/Endpoint';
import { HTTPMethodTypes } from '../common/RequestManager/Request';

export enum OfferOptions {
  PRICE = 'PRICE',
  DELIVERY = 'DELIVERY',
}

export enum RefundProcessTypes {
  TOTAL = 'TOTAL',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum ReviewStatus {
  PENDING = 'PENDING',
  CHECKED = 'CHECKED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  VERIFICATION = 'VERIFICATION',
  VERIFIED = 'VERIFIED',
  LANGUAGE_VERIFICATION = 'LANGUAGE_VERIFICATION',
}

export enum ReviewTypes {
  MERCHANT = 'MERCHANT',
  PRODUCT = 'PRODUCT',
}

export interface IRefundsBody {
  paging: {
    limit: number;
    page: number;
  };
  orderId?: string;
}

export interface IRefundsListParams {
  limit: number;
  page: number;
}

export interface IReviewData {
  reviewId: string;
  orderCode: string;
  productSku: string;
  merchantId: string;
  reviewStatus: ReviewStatus;
  reviewTypes?: ReviewTypes;
  isCommented: boolean;
  orderExpired?: boolean;
}

export interface ICertificateParams {
  masterSku: string;
  merchantId: string;
  senderDto: {
    username: string;
    originalName: string;
    displayName: string;
    comment: string;
  };
  recipientDto: {
    phoneNumber: string;
  };
  dateOfReceipt: string;
  certificateDesignType: string;
  zoneIdentifiers?: string[];
}

export interface IOrderReviewsAvailability {
  orderExpired: boolean;
  reviews: {
    [key: IReviewData['productSku']]: IReviewData;
  };
}

interface IAddToCartProps {
  productCode: string;
  merchantId: string;
  quantity: number;
}

export class Api {
  m: any; // Mithril
  constructor(m) {
    this.m = m;
  }

  /**
   * Cart
   */

  /**
   * addToCart
   * @url The parameter building from class OffersAdapter.buildLinkAddToCart
   * TODO: Will need refactoring without build to GATEWAY.addToCart
   */
  addToCart(payload: {
    url: string;
    buyNow: boolean;
    quantity: number;
    zid?: string;
  }) {
    const params = {
      ...payload,
      random: randomString(),
    };

    return this.m.request({
      method: 'GET',
      url: payload.url,
      params,
    });
  }

  msMultipleAddToCart(body: { entries: IAddToCartProps[]; zid?: string }) {
    return this.m.request({
      url: GATEWAY.addToCart,
      method: 'POST',
      body,
    });
  }

  msChangeQuantityEntryIntoCart({ groupId, id, quantity, withXHR = false }) {
    return this.m.request({
      withXHR,
      url: GATEWAY.cartEntry,
      method: HTTPMethodTypes.post,
      body: {
        groupId,
        id,
        quantity,
      },
    });
  }

  msChangeQuantityEntryIntoCartWithRecount({
    groupId,
    id,
    quantity,
    withXHR = false,
  }) {
    return this.m.request({
      withXHR,
      url: GATEWAY.cartEntryWithRecount,
      method: HTTPMethodTypes.post,
      body: {
        groupId,
        id,
        quantity,
      },
    });
  }

  msGetCartData(props: { ds: boolean; zid?: string }) {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.cartData,
      params: {
        ...props,
        random: randomString(),
      },
    });
  }

  msDeleteEntryFromCart({ groupId, id, withXHR = false }) {
    return this.m.request({
      withXHR,
      url: GATEWAY.cartEntry,
      method: HTTPMethodTypes.delete,
      body: {
        groupId,
        id,
      },
    });
  }

  msDeleteEntryFromCartWithRecount({ groupId, id, withXHR = false }) {
    return this.m.request({
      withXHR,
      url: GATEWAY.cartEntryWithRecount,
      method: HTTPMethodTypes.delete,
      body: {
        groupId,
        id,
      },
    });
  }

  msMultipleDeleteFromCart({ entries, withXHR = false }) {
    return this.m.request({
      withXHR,
      url: GATEWAY.cartEntries,
      method: HTTPMethodTypes.delete,
      body: entries,
    });
  }

  msGetCartInfo() {
    return this.m.request({
      url: GATEWAY.cartInfo,
      method: 'GET',
      random: randomString(),
    });
  }

  msGetCartInfoPriorityMerchant() {
    return this.m.request({
      url: GATEWAY.cartInfoPriorityMerchant,
      method: 'GET',
    });
  }

  getInvoiceData(invoice: string) {
    return this.m.request({
      url: GATEWAY.invoiceData,
      method: HTTPMethodTypes.get,
      params: {
        invoice,
      },
    });
  }

  prepareInvoicePayment(invoice: string) {
    return this.m.request({
      url: GATEWAY.invoicePaymentPrepare,
      method: HTTPMethodTypes.post,
      params: {
        invoiceID: invoice,
      },
      extract: extendExtract,
    });
  }

  payInvoice(
    invoice: string,
    body: IPlacementRequestBody
  ): Promise<ICheckoutPlacementResponse> {
    return this.m.request({
      url: GATEWAY.invoicePayment,
      method: HTTPMethodTypes.post,
      params: {
        invoiceID: invoice,
      },
      extract: extendExtract,
      body,
    });
  }

  /**
   * content item
   */

  getProductDataMS(productCode: string): Promise<IItemProductData> {
    return this.m.request({
      url: GATEWAY.productData,
      params: {
        productCode,
        random: randomString(),
      },
    });
  }

  getProductConfiguratorMS(baseProductCode: string) {
    return this.m.request({
      url: GATEWAY.configuratorData,
      method: 'GET',
      params: {
        baseProductCode,
        random: randomString(),
      },
    });
  }

  getSizeTable(code: string) {
    return this.m.request({
      url: GATEWAY.sizeTableData,
      method: 'GET',
      params: { code },
    });
  }

  async getProductLimits(productCodes: string[]) {
    const codes = productCodes.join(',');
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.productLimits,
      params: {
        productCodes: codes,
      },
    });
  }

  async getProductMaxQuantity(productCode: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.productPromotedQuantity,
      params: {
        sku: productCode,
      },
    });
  }

  /**
   * product-view
   */
  getGoods(params: IGoodsParams) {
    const { productsSkus, ignorePriceFilter = false } = params;
    const code = productsSkus.map((id) => id).join(',');

    const paramsData: IGoodsParams = {
      code,
      mUid: params.mUid,
      i: params.i,
      u: params.u,
      ipf: String(ignorePriceFilter),
      zid: params.zid,
    };

    Object.keys(paramsData).forEach((key) => {
      if (!paramsData[key]) {
        delete paramsData[key];
      }
    });

    return this.m.request({
      url: GATEWAY.productGateway,
      params: paramsData,
    });
  }

  getOrdersProduct(params: IGoodsParams) {
    const { productsSkus } = params;
    const code = productsSkus.map((id) => id).join(',');

    const paramsData: IGoodsParams = {
      code,
      mUid: params.mUid,
      i: params.i,
      u: params.u,
      zid: params.zid,
    };

    Object.keys(paramsData).forEach((key) => {
      if (!paramsData[key]) {
        delete paramsData[key];
      }
    });

    return this.m.request({
      url: GATEWAY.ordersProduct,
      params: paramsData,
    });
  }

  getAvailabilityGoods(
    productsSkus: string[],
    merchantId?: string
  ): Promise<string[]> {
    const code = productsSkus.map((id) => id).join(',');

    return this.m.request({
      url: GATEWAY.productAvailability,
      params: {
        code,
        m: merchantId,
      },
    });
  }

  /**
   * offer-view
   */
  getOffersByFilterDelivery(payload: IGetOffersByFilterDeliveryPayload) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.offersByFilterGateway,
      body: payload,
    });
  }

  getOffersByDeliveryDuration(payload: IGetOffersByDeliveryDurationPayload) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.offersByFilterGateway,
      body: payload,
    });
  }

  getOffersByMerchant(payload: IGetOffersByMerchantPayload) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.offersByFilterGateway,
      body: payload,
    });
  }

  getOffersByMaster(params, body: IGetOffersByMasterPayload) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.offersByMasterGateway,
      params,
      body,
    });
  }

  /**
   * Merchant
   */

  getMerchantDetails(merchantId: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getMerchantDetails,
      params: {
        merchantId,
      },
    });
  }

  getMerchantSupport(merchantId: string, cityCode?: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getMerchantSupport,
      params: {
        merchantId,
        c: cityCode,
      },
    });
  }

  /**
   * Reviews
   */
  getReviewsMS({ productCode, orderCode = '', limit = 1, page = 0, id, sort }) {
    return this.m.request({
      url: GATEWAY.productReviews,
      params: {
        productCode,
        orderCode,
        limit,
        page,
        id,
        sort,
      },
    });
  }

  getReviewSummaryMS(productCode: string) {
    return this.m.request({
      url: GATEWAY.reviewSummary,
      params: { productCode },
    });
  }

  getReviewGroupSummaryMS(productCode: string) {
    return this.m.request({
      url: GATEWAY.reviewGroupSummary,
      params: { productCode },
    });
  }

  getMerchantReviewsMS({
    merchantId,
    orderCode = '',
    limit,
    page = 0,
    id,
    days = 90,
    sort,
  }) {
    return this.m.request({
      url: GATEWAY.merchantReviews,
      params: {
        merchantCode: merchantId,
        orderCode,
        limit,
        page,
        id,
        sort,
        days,
      },
    });
  }

  getMerchantGroupSummaryMS(merchantCode: string, days: number = 90) {
    return this.m.request({
      url: GATEWAY.merchantGroupSummary,
      params: { merchantCode, days },
    });
  }

  getReviewTeasersMS(): Promise<{ data: IReviewTeaser[] }> {
    return this.m.request({
      url: GATEWAY.reviewTeasers,
    });
  }

  getReviewProductByOrder(
    orderCode: string,
    productSku: string
  ): Promise<IReviewData> {
    return this.m.request({
      url: GATEWAY.reviewProductByOrder,
      params: {
        orderCode,
        productSku,
      },
    });
  }

  getReviewsByOrder(
    orderCode: string
  ): Promise<{ orderExpired: boolean; reviewList: IReviewData[] }> {
    return this.m.request({
      url: GATEWAY.reviewsByOrder,
      params: {
        orderCode,
      },
    });
  }

  getReviewTags(orderCode: string): Promise<{ data: IReviewTag[] }> {
    return this.m.request({
      url: GATEWAY.reviewTags,
      params: {
        orderCode,
      },
    });
  }

  /**
   * Postomat-book
   */
  getPostomatBook(cityId: string): Promise<IResponsePostomatBook[]> {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.getPostomatBook,
      params: {
        cityID: cityId,
      },
    });
  }

  createPostomatBook(
    body: IPayloadPostomatBook
  ): Promise<IResponsePostomatBook> {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.createPostomatBook,
      body,
    });
  }

  updatePostomatBook(posId: string, body: IPayloadPostomatBook) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.changePostomatBook,
      body,
      params: {
        posId,
      },
    });
  }

  deletePostomatBook(posId: string) {
    return this.m.request({
      method: 'DELETE',
      url: GATEWAY.changePostomatBook,
      params: {
        posId,
      },
    });
  }

  /**
   * address-book
   */
  getAddresses(
    cityId: string,
    merchantUid: string
  ): Promise<IResponseAddress[]> {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.getAddress,
      params: {
        cityID: cityId,
        m: merchantUid,
      },
    });
  }

  createAddress(body: IPayloadAddress): Promise<IResponseAddress> {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.createAddress,
      body,
    });
  }

  updateAddress(addressId: string, body: IPayloadAddress) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.changeAddress,
      body,
      params: {
        addressId,
      },
    });
  }

  deleteAddress(addressId: string) {
    return this.m.request({
      method: 'DELETE',
      url: GATEWAY.changeAddress,
      params: {
        addressId,
      },
    });
  }

  //   curl -X 'GET' \
  // 'https://uatks1.kaspi.kz/yml/ms/points/api/v1/zones/point?merchantUid=Magnum&lng=76.97341&lat=43.266022' \
  //   -H 'accept: application/json'

  // curl -X 'GET' \
  //   'https://uatks1.kaspi.kz/yml/ms/points/api/v1/zones/point?merchantUid=Magnum&lng=76.97341&lat=43.266022' \
  //   -H 'accept: application/json' \
  //   -H 'Cookie: ticket=TGT-8e386c35-712f-4ff3-9cb1-a20433e84d9e'

  // address find by merchant
  getAddressZoneInfo({
    i,
    cityId,
    merchantUid,
  }: {
    i: string;
    cityId: string;
    merchantUid: string;
  }) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getAddressZoneInfo,
      params: {
        i,
        cityId,
        merchantUid,
      },
    });
  }

  getZoneByMerchant(
    merchantUid: string,
    lat: string,
    lng: string,
    config?: (xhr: any) => void
  ) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getZoneByMerchant,
      params: {
        merchantUid,
        lng,
        lat,
      },
      config,
    });
  }

  /**
   * Checkout
   */

  msCleanCheckoutCart() {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.cleanCheckoutCart,
    });
  }

  getLoanForm(checkoutId: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.loanForm,
      params: {
        checkoutId,
      },
    });
  }

  submitLoanForm(body) {
    return this.m.request({
      method: HTTPMethodTypes.post,
      url: GATEWAY.loanForm,
      body,
      config: (xhr: any) => {
        xhr.ignoreHandlingError404 = true;
      },
    });
  }

  checkoutPrepare() {
    return this.m.request({
      method: HTTPMethodTypes.post,
      url: GATEWAY.checkoutPrepare,
    });
  }

  setCheckoutLoanPayment(body) {
    return this.m.request({
      method: HTTPMethodTypes.post,
      url: GATEWAY.checkoutLoanPayment,
      body,
    });
  }

  getCheckoutLoanPayment(data: { useBonus: boolean; useCertificate: boolean }) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.checkoutLoanPayment,
      params: {
        useBonus: data.useBonus,
        useCetrificate: data.useCertificate,
      },
    });
  }

  checkoutPlacement(body: IPlacementRequestBody) {
    return this.m.request({
      body,
      method: HTTPMethodTypes.post,
      url: GATEWAY.checkoutPlacement,
      config: (xhr) => {
        xhr.ignoreHandlingError500 = true;
      },
    });
  }

  checkoutDigitalPlacement(body: IPlacementRequestBody) {
    return this.m.request({
      body,
      method: HTTPMethodTypes.post,
      url: GATEWAY.checkoutDigitalPlacement,
      config: (xhr) => {
        xhr.ignoreHandlingError500 = true;
      },
    });
  }

  /**
   * Refund
   */

  checkIsOrderRefundable(orderId: string) {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.refundCheck,
      params: {
        orderId,
      },
    });
  }

  getRefundReasons(productSku: string, orderId: string) {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.refundReasons,
      params: {
        productSku,
        o: orderId,
      },
    });
  }

  sendRefund(data) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.refundSend,
      body: data,
    });
  }

  getRefunds(body: IRefundsBody) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.refundsList,
      body,
    });
  }

  getRefund(id: string) {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.refund,
      params: {
        id,
      },
    });
  }

  getRefundByCode(code: string) {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.refundByCode,
      params: {
        code,
      },
    });
  }

  getSlots(refundId: string) {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.refundSlots,
      params: {
        refundId,
      },
    });
  }

  createApprovalDispute(refundId: string) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.refundApprovalDispute,
      params: {
        refundId,
      },
    });
  }

  createDecisionDispute(refundId: string) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.refundDecisionDispute,
      params: {
        refundId,
      },
    });
  }

  getRefundsCount(process: RefundProcessTypes): Promise<number> {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.refundsCount,
      params: {
        process,
      },
      config: (xhr: any) => {
        xhr.ignoreHandlingError404 = true;
        xhr.ignoreHandlingError500 = true;
      },
    });
  }

  cancelRefundApplication(refundId: string): Promise<void> {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.refundCancelApplication,
      params: {
        refundId,
      },
    });
  }

  sendRefundDelivery(data, refundId: string) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.refundDelivery,
      params: {
        refundId,
      },
      body: data,
    });
  }

  /**
   * KL
   */
  async getCityPolygon(cityId: string): Promise<{ bounds: number[][] }> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getCityPolygon,
      params: {
        code: cityId,
      },
    });
  }

  /**
   * Merchant delivery
   */
  async getMerchantDeliverySlots(
    payload: IPayloadMerchantDeliverySlots
  ): Promise<IResponseMerchantDeliverySlots> {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.getAvailableSlots,
      params: payload,
    });
  }

  async getSpecificCategoriesSlots(): Promise<string[]> {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.getSpecificCategoriesSlots,
    });
  }

  async getShareCart(items: IGetShareCartRequest[]): Promise<any> {
    return this.m.request({
      method: HTTPMethodTypes.post,
      url: GATEWAY.cartShare,
      body: {
        items,
      },
    });
  }

  async getSharedCartDataById(cartId: string, zones: string): Promise<any> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.receiveSharedCart,
      params: {
        share: cartId,
        zones,
      },
    });
  }
  async addToCartSharedEntries(payload: {
    id: string;
    zoneIds: string[];
    ageConfirmed: boolean;
  }): Promise<any> {
    return this.m.request({
      method: HTTPMethodTypes.post,
      url: GATEWAY.addToCartSharedEntries,
      body: {
        type: 'shared_items',
        sharedCartIdentifier: payload.id,
        zoneIdentifiers: payload.zoneIds,
        ageConfirmed: payload.ageConfirmed,
      },
    });
  }

  async getPostomatPoints(
    cityId: string,
    postomatIds?: string,
    entryCodes?: string
  ): Promise<{ data: { pickup_points: IKLPoint[] } }> {
    const params: {
      postomatIds?: string;
      cityCode?: string;
      productCode?: string;
    } = {};

    if (cityId) {
      params.cityCode = cityId;
    }

    if (postomatIds) {
      params.postomatIds = postomatIds;
    }

    if (entryCodes) {
      params.productCode = entryCodes;
    }

    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getPostomatPoints,
      params,
    });
  }

  async getPostomatPointsDates(
    params: IPayloadGetPickupDates
  ): Promise<{ data: { pickupPoints: IPickupDateKL[] } }> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getPostomatPointsDates,
      params,
    });
  }

  async getPostomatNeighborOptions(
    params: IPayloadPostomatNeighborOptions
  ): Promise<{
    status: { code: number; message: string };
    data: IResponsePostomatNeighborOptions;
  }> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getPostomatNeighborOptions,
      params,
      config: (xhr: any) => {
        xhr.ignoreHandlingError404 = true;
        xhr.ignoreHandlingError500 = true;
      },
    });
  }

  async getNearbyPostomat(params: INearbyPostomatPayload): Promise<{
    status: { code: number; message: string };
    data: INearbyPostomatResponse;
  }> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getNearbyPostomat,
      params,
    });
  }

  /**
   * Translations
   */
  async getTranslationsByPrefix(prefix: string): Promise<ITranslation[]> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getTranslationsByPrefix,
      params: { prefix },
    });
  }

  /**
   * Certificate
   */

  async createCertificate(body: ICertificateParams) {
    return this.m.request({
      method: HTTPMethodTypes.post,
      url: GATEWAY.createCertificate,
      body,
    });
  }

  async checkCertificateRecipient(params: Record<string, string>) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: BFF.checkCertificateRecipient,
      params,
    });
  }

  /**
   * BFF Refunds
   */

  getRefundsList(params: IRefundsListParams) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: BFF.refundsList,
      params,
    });
  }

  getRefundDetails(id: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: BFF.refundDetails,
      params: {
        id,
      },
    });
  }

  getRefundDetailsByCode(code: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: BFF.refundDetailsByCode,
      params: {
        code,
      },
    });
  }

  getUserProfile(phone: string, config?: any) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: BFF.userProfile,
      params: {
        phone,
      },
      config,
    });
  }
}
