import cookies from 'ks-utilities/lib/cookies';

const userIdHashCookieName: string = 'pd';
export const emptyUserIdHash = '-1';

export function getUserIdHash(): string {
  return cookies.get(userIdHashCookieName) || '';
}

// PCM needs '-1' if empty user id hash
export function getUserIdHashForPCM(): string {
  return getUserIdHash() || emptyUserIdHash;
}
