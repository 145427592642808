export function getMainPromoBannerParams(data: IParamMainPagePromoBanner) {
  return {
    block_name: data.code,
    block_position: data.position,
    block_type: data.type,
    campaign_id: data.list[0].code,
    ads_unique_id: data.eventId,
    listing_position: data.listingPosition,
    listitems_total_count: data.list.length,
  };
}
