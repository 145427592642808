import { getCurrentCityId } from '../../services/locationService';
import { getCategoryHierarchyProps } from './getCategoryHierarchyProps';
import { getPageCategory } from './getPageCategory';

export function combineSearchEventProperties() {
  const path = document.location.pathname;
  const categoryArr =
    window.digitalData.product?.category ||
    window.digitalData.listing?.category;
  const categoryHierarchyProps = getCategoryHierarchyProps(categoryArr);

  const parameters = {
    shop_city: getCurrentCityId(),
    path,
    page_category: getPageCategory(path),
    ...categoryHierarchyProps,
  };

  return parameters;
}
