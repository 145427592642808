import { FilterDeliveryType } from "./FilterDelivery";

export enum OffersFilterType {
  DELIVERY = 'delivery',
  HIGH_RATING = 'highRating',
  SORT_OPTION = 'sortOption',
  SEARCH_TEXT = 'searchText',
}

export enum OffersSortingType {
  PRICE = 'PRICE',
  RATING = 'RATING',
  POPULAR = 'POPULAR',
  // REVIEW = 'REVIEW', // feasible
}

export interface IOffersFilters {
  delivery: FilterDeliveryType;
  highRating: boolean;
  sortOption: IOffersSortOption;
  searchText: string;
}
