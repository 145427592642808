export enum CityCodes {
  ALMATY = '750000000',
  ASTANA = '710000000',
  KARAGANDA = '351010000',
  SHYMKENT = '511010000',
  USTKAMENOGORSK = '631010000',
  AKTOBE = '151010000',
  ATYRAU = '231010000',
  AKTAU = '471010000',
  PAVLODAR = '551010000',
  PETROPAVLOVSK = '591010000',
  KOKSHETAU = '111010000',
  KOSTANAY = '391010000',
  URALSK = '271010000',
  SEMEY = '632810000',
  TARAZ = '311010000',
  KYZYLORDA = '431010000',
  EKIBASTUZ = '552210000',
  RYDNIY = '392410000',
  AKSU = '551610000',
  KHROMTAU = '156020100',
  IRGELI = '195247100',
  ALGA = '153220100',
  KANDYAGASH = '154820100',
  BESAGASH = '196243100',
  PANFILOVO = '196253100',
  TUZDYBASTAY = '196245100',
  RAIYMBEK = '195253100',
  KOKSAI = '195247400',
  BELBULAK = '196235100',
  GULDALA = '196249100',
  KASKELEN = '195220100',
  BESKOL = '595030100',
  MAMLIYTKA = '595220100',
  TALDYKORGAN = '191010000',
  KARABULAK = '196430100',
  TALGAR = '196220100',
  ZHANAOZEN = '471810000',
  AYAGOZ = '633420100',
  SHEMONAIHA = '636820100',
  RIDDER = '156420100',
  ALTAI = '634820100',
  ABAI = '195253200',
  TEMIRTAU = '352410000',
  ZHEZKAZGAN = '351810000',
  SATPAEV = '352310000',
  BALKASH = '351610000',
  KAPSHAGAI = '191610000',
  TURKISTAN = '512610000',
  AKSAI = '273620100',
  SHUCHINSK = '117020100',
  SHU = '316621100',
  STEPNOGORSK = '111810000',
  KORDAI = '314851205',
  SHAKHTINSK = '352810000',
  SARAN = '352210000',
  KOSSHY = '116651100',
  LISAKOVSK = '392010000',
  ESIK = '194020100',
  SARYAGASH = '515420100',
  BAIKONYR = '431910000',
  ZHETISAI = '514420100',
  KULSARY = '233620100',
  KENTAU = '612010000',
  ATBASAR = '113820100',
  OTEGEN_BATYR = '196830100',
  ABAI_KARAGANDA = '353220100',
  TOPAR = '353285100',
}
