const kaspiCDN = window.BACKEND?.config?.kaspiCDN; // from "/shop/front/sa/stable/desktop/public_path.js"

window.BACKEND = {
  analytics: {},
  config: {},
  components: {},
  state: {},
  API: 'api'
};

if (kaspiCDN) {
  window.BACKEND.config.kaspiCDN = kaspiCDN;
}
