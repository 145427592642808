import { PageCategory } from '../const/PageCategory';

export function getPageCategory(path: string): PageCategory {
  if (!path) {
    return null;
  }

  let page = PageCategory.HOME; // default
  const hash = window.location.hash;

  if (/shop.*\/c\//.test(path)) {
    page = window.BACKEND.components.catalog?.filterQuery
      ? PageCategory.CATEGORY_LISTING
      : PageCategory.CATEGORY_NAV;
  } else if (/shop.*\/search/.test(path)) {
    page = PageCategory.SEARCH_LISTING;
  } else if (/shop.*\/p\//.test(path)) {
    page = PageCategory.ITEM;
  } else if (/shop\/favorite-products/.test(path)) {
    page = PageCategory.FAVORITES;
  } else if (/shop\/cart\//.test(path)) {
    page = PageCategory.CART;
  } else if (/shop\/my-orders/.test(path)) {
    if (!hash || hash === '#!/my-orders') {
      page = PageCategory.ORDER_LIST;
    } else {
      page = PageCategory.ORDER;
    }
    if (hash.includes('refunds')) {
      page = PageCategory.REFUNDS;
    }
  } else if (/shop\/checkout/.test(path)) {
    if (
      !hash ||
      hash === '#!/delivery' ||
      hash === '#!/mapDelivery' ||
      hash === '#!/changeCity'
    ) {
      page = PageCategory.CHECKOUT_DELIVERY;
    } else if (hash === '#!/payment') {
      page = PageCategory.CHECKOUT_PAYMENT;
    } else if (hash === '#!/orderConfirmation') {
      page = PageCategory.TYP_CHECKOUT;
    }
  } else if (/shop.*\/merchant/.test(path)) {
    page = PageCategory.MERCHANT;
  } else if (/shop\/l\/supermarket-magnum/.test(path)) {
    page = PageCategory.MAGNUM_LANDING;
  }

  return page;
}
