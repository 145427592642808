import cookies from '../packages/ks-utilities/lib/cookies';
import { EventEmitter } from '../packages/ks-store/common/services/eventEmitterService';
import { EventTypes } from '../packages/ks-store/common/const/events';

export { logSentryEvent, SentryScopes } from './logSentryEvent';

export let Sentry: any = null;

export const initSentry = async () => {
  if (process.env.NODE_ENV === 'development') {
    console.warn('Sentry not work now.');
    return;
  }

  try {
    if (!window.sentryConfig?.dsn) {
      return;
    }

    // tslint:disable-next-line
    console.warn('Sentry is work now.');

    Sentry = await import(/* webpackChunkName: "sentry" */ '@sentry/browser');
    const SentryTracing = await import(
      /* webpackChunkName: "sentry-tracing" */ '@sentry/tracing'
    );
    Sentry.init({
      dsn: window.sentryConfig?.dsn,
      release: window.sentryConfig?.release,
      environment: window.sentryConfig?.environment,
      tracesSampleRate: Number(window.sentryConfig?.tracesSampleRate ?? 0.2),
      sampleRate: Number(window.sentryConfig?.sampleRate ?? 0.2),
      integrations: window.sentryConfig?.performance
        ? [new SentryTracing.BrowserTracing()]
        : [],
      ignoreErrors: [
        /^\[object XMLHttpRequest\]$/,
        /^object XMLHttpRequest$/,
        /^<unknown>$/,
        ...(window.sentryConfig?.ignoreErrors || []),
      ],
      ignoreUrls: [
        /\/api_static\.js/, // ignore dy errors like https://stlog.kaspi.kz/organizations/kaspi/issues/31/?project=2&query=is%3Aunresolved
        /googletagmanager\.com/,
        /hotjar\.com/,
        ...(window.sentryConfig?.ignoreUrls || []),
      ],
      denyUrls: [
        /\/api_static\.js/, // ignore dy errors like https://stlog.kaspi.kz/organizations/kaspi/issues/31/?project=2&query=is%3Aunresolved
        /googletagmanager\.com/,
        /hotjar\.com/,
        ...(window.sentryConfig?.denyUrls || []),
      ],
    });

    const extra = setExtra(Sentry);

    extra('k_stat', 'k_stat');
    extra('jsessionid', 'JSESSIONID');
    extra('city_id', 'kaspi.storefront.cookie.city');
    extra('test_group', 'ks.tg');
    extra('ma_ver', 'ma_ver');

    EventEmitter.subscribe(EventTypes.FETCH_USER_DATA, ({ user }) => {
      Sentry.setExtra('phone', user.phone);
      Sentry.setExtra('global_customer_id', user.userId);
      user.phone &&
        Sentry.setUser({
          id: user.phone.replace(/[\s+\-+()]/g, ''),
          name: user.firstName,
          email: user.email || 'none',
        });
    });

    document.addEventListener(
      EventTypes.CAPTURE_EXCEPTION as any,
      (e: CustomEvent) => {
        Sentry?.captureException(e.detail);
      }
    );

    document.addEventListener(
      EventTypes.CAPTURE_MESSAGE as any,
      (e: CustomEvent) => {
        Sentry?.captureMessage(e.detail);
      }
    );
    EventEmitter.emit(EventTypes.INIT_SENTRY, {});
  } catch (e) {
    // tslint:disable-next-line
    console.error(e);
  }
};

const setExtra = (s: any /* Sentry */) => {
  return (name: string, cookie) => {
    const val = cookies.get(cookie);
    if (val) {
      s.setExtra(name, val);
    }
  };
};
