import { getCategoriesParams } from '../getCategoriesParams';
import { getAvailableDeliveryMethod } from './getAvailableDeliveryMethod';

export const getCartInDeliveryParams = (
  cartItem,
  cart,
  deliveryOption,
  cartItemDeliveryOptions
) => {
  const deliveryPrice = deliveryOption.data.price;
  const cartProductsIds = cartItem.entries.map((entry) => entry.productId);
  const merchantLineItems = cart.lineItems?.filter(
    (item) =>
      item.supplier.name === cartItem.merchant &&
      cartProductsIds.includes(item.product.id)
  );
  const subtotalPrice = merchantLineItems?.reduce(
    (accumulator, item) => accumulator + item.subtotal,
    0
  );
  const categotiesParams = getCategoriesParams(
    merchantLineItems?.map((item) => item.product.category)
  );
  const availableDeliveryMethod = getAvailableDeliveryMethod(
    cartItemDeliveryOptions
  );

  const cartParams = {
    delivery_method: deliveryOption.id,
    cart_id: cart.id,
    delivery_price: deliveryPrice,
    path: document.location.pathname,
    suppliers: cartItem.merchant,
    total_price: subtotalPrice + deliveryPrice || 0,
    ...availableDeliveryMethod,
    ...categotiesParams,
  };

  return cartParams;
};
