/*
 * Страница, с которой перешел пользователь.
 * Значение взять из параметра в ссылке. Может быть в таких форматах:
 * ?ref="значение", ?source="значение", startedFrom="значение", ?started_from="значение"
 */

export function getStartedFrom():
  | {
      started_from: string;
      ref: string;
    }
  | Record<string, never> {
  if (!window.m) {
    return {};
  }

  const formSearch = getStartedFromParam(document.location.search);
  const params = formSearch || getStartedFromParam(document.location.hash.split('?')?.[1]);
  return params || {};
}

function getStartedFromParam(url: string) {
  const params = window.m.parseQueryString(url);
  const startedFrom = params.ref || params.source || params.startedFrom || params.started_from;
  
  return startedFrom ? {
    started_from: startedFrom,
    ref: params.ref,
  } : null;
}
