export const categoriesBannedForChildren = [
    'Car batteries',
    'Snowmobiles',
    'Car adhesives and sealants',
    'Car interior cleaners',
    'Varnishes for car body repair',
    'Cleaners and polishes for tires and rims of cars',
    'Antifreezes',
    'Automotive conditioning oils',
    'Paints for car body repair',
    'Car interior polish',
    'Car waxes',
    'Infrared dryers for car services',
    'Lubricants for cars',
    'Fork oil',
    'Car shampoos and foams',
    'Car lifts',
    'Washer fluid',
    'Car tire repair kits and sealants',
    'Oil dispensers and lubrication blowers',
    'Stands for testing and flushing injectors',
    'Gear oils',
    'Motorcycles',
    'Electric motorcycles',
    'Brake fluids',
    'Technical cleaners for cars',
    'Primers for car body repair',
    'Car trailers',
    'Car exterior polish',
    'Motor oils',
    'Plants for the collection of oils and technical fluids',
    'Hydraulic fluids and oils',
    'Putties for car body repair',
    'Lighter accessories',
    'Antimicrobial drugs',
    'Muscles bones joints',
    'Stomach intestines liver',
    'Syringes needles and injectors',
    'Dentistry',
    'Accessories for physiotherapy',
    'Lubricants',
    'Obstetrics and gynecology',
    'Endocrinology',
    'Healing creams',
    'Pain relievers',
    'Cold and flu',
    'Allergy',
    'Dietary supplements',
    'Skin hair nails',
    'Pain relievers',
    'Inflammation and infections',
    'Neurology',
    'Genitourinary system',
    'Pain relievers',
    'Defibrillators',
    'Pain relievers',
    'Genitourinary system',
    'Condoms',
    'Disinfection germicidal lamps',
    'Ear throat nose',
    'Boost immunity',
    'Dentistry',
    'Vision',
    'Vitamins',
    'Medical endoscopes',
    'Neurostimulators',
    'Genitourinary system',
    'Pain relievers',
    'Surgical instruments',
    'Base coats',
    'Cardiovascular system',
    'Breweries',
    'Band saws for food',
    'Breast milk storage',
    'Diaper bags',
    'Accessories for breast pumps',
    'Baby bibs',
    'Nursing pads',
    'Maternity hospital kits',
    'Breast shells and shields',
    'Poker sets',
    'Wood burning tools',
    'Tailors scissors',
    'Ritual candles',
    'Needles for needlework',
    'Silicone for making and pouring molds',
    'Tools for sawing',
    'Playing cards',
    'Fabric cutting knives',
    'Tarot cards',
    'Glue guns',
    'Ouija boards',
    'Attractions for adults',
    'Tufting guns',
    'Stationery pins',
    'Paper trimmers',
    'Paper clips',
    'Receipt paper',
    'Corner cutter',
    'Thermoforming equipment',
    'Business card cutters',
    'Running machines',
    'Essentials stamp bodies',
    'Caimarez and tongs for stretching',
    'Rubber stamps',
    'Push pins office tacks',
    'Powder glue for DTF printing',
    'Torch accessories',
    'Date stamps',
    'Stationery pen knives',
    'Company seals',
    'Compositions for straightening',
    'Consumables for tattoo',
    'Dryers and climazones',
    'Epilators',
    'Facial cleansing devices',
    'Hair cutting shears',
    'Hair Extension Supplies',
    'Hair extension tongs',
    'Hardware cosmetology',
    'Keratin for hair extensions',
    'Lip liners',
    'Massage tables',
    'Permanent makeup pigments',
    'Piercing Tools',
    'Power sources for tattoo machines',
    'Salon chairs and stools',
    'Skin care tools',
    'Staples for orthonyxia',
    'Sterilizers for manicure tools',
    'Tattoo machines',
    'Distribution line for public catering',
    'Bar tables',
    'Women bras',
    'Men underpants',
    'Women lingerie sets',
    'Women stockings',
    'Women swimsuits',
    'Women swimsuit bottoms',
    'Women tights',
    'Women panties',
    'Maternity bras',
    'Maternity panties',
    'Women shapewear',
    'Women bodysuits',
    'Women underwear sets',
    'Tree ornaments sets',
    'Fashion accessory sets',
    'Souvenir weapon',
    'Fireworks',
    'Color smoke bombs',
    'Absinthe',
    'Brandy',
    'Vermouth',
    'Vine',
    'Whisky',
    'Vodka',
    'Gin',
    'Sparkling wine champagne',
    'Ingredients for making alcohol',
    'Yogurt drinks',
    'Calvados',
    'Ryazhenka',
    'Cognac',
    'Liquor',
    'Tinctures and balms',
    'Pivo',
    'Food grade preservatives antioxidants',
    'Alcohol Gift Sets',
    'Portwine',
    'Rum',
    'Cider',
    'Tequila',
    'Vinegar',
    'Chacha',
    'Energy drinks',
    'Spare parts for paintball equipment',
    'Stocks for hunting',
    'Ice climbing crampons',
    'Gun holsters',
    'Resistance trainers',
    'Riders',
    'Fishing accessories',
    'Yoga hammocks',
    'Camping tools',
    'Joints supplements',
    'Ice auger drill bits',
    'Skate sharpening machines',
    'Fishing line',
    'Ice augers',
    'Fishing kits',
    'Backpacking knives',
    'Sadhu boards',
    'Machetes',
    'Dance poles',
    'Fixed-blade knives',
    'Dartboards',
    'Inversion Tables',
    'Backpacking stoves',
    'Shooting goods',
    'Backpacking stoves accessories',
    'Inflatable water attractions',
    'Climbing rope ladders',
    'Testosterone boosters',
    'Fishing hooks',
    'Multitools',
    'Spearguns',
    'Baseball bats',
    'Postworkout sports nutrition',
    'Skating blades',
    'Machine tools and rests for shooting',
    'Dart flights',
    'Mounts for optical sights',
    'Accessories for hunting and shooting',
    'Gas cylinders for camping burners',
    'Weight gainers',
    'Slingshots',
    'Camping stoves',
    'Blades for ice augers',
    'Airsoft pistols',
    'Sports uniforms',
    'Shooting optics batteries',
    'Detox complexes',
    'Sights for hunting and shooting',
    'Hiking survival kits',
    'Travel heating pads',
    'Hunting traps',
    'Flints',
    'Ice fishing spears',
    'Full fishing equipment',
    'Ice axes for climbing',
    'Fishing tippets',
    'Preworkout sports nutrition',
    'Fencing swords',
    'Fat burners',
    'Energy racks',
    'Gas and petrol blowtorches',
    'Road rollers',
    'Gas soldering irons',
    'Welding chemistry',
    'Wind turbines',
    'Pneumatic drills',
    'Vibroslats',
    'Cable cutting machines',
    'Hand hacksaws',
    'Mineral wool',
    'Check valves for gas cylinders',
    'Septic tanks',
    'Consumables and spare parts for plasma torches',
    'Chip extractors',
    'Pneumatic screwdrivers',
    'Jig saw blades',
    'Welding glasses',
    'Accessories for gas holder',
    'Mobile block containers',
    'Spare parts for nail guns',
    'Circular saws',
    'Accessories for electric shields',
    'Building cables and wires',
    'Welding control units',
    'Tiling tools',
    'Components and accessories for the router',
    'Laboratory power supplies',
    'FOCL welding machines',
    'Gas cutting machines',
    'Duct heaters and coolers',
    'Junction boxes',
    'Hand saws',
    'Welding electrodes',
    'Milling machines',
    'Construction floats',
    'Optical levels',
    'Other low voltage devices',
    'High-pressure hoses',
    'Folding machines',
    'Welding ground terminals',
    'Gas cylinders for welding',
    'Diamond drilling accessories',
    'Gas welding torches',
    'Solar controllers',
    'Locksmith chisels',
    'Straightening machines',
    'Burners for heating boilers',
    'Membrane for expansion tank',
    'Covers for gas cylinders',
    'Chamfering Machine',
    'Shoes for pouring self-leveling floor',
    'Lifting equipment',
    'Soldering flux',
    'Electric shears',
    'High vacuum pumps',
    'Accessories for painting machines',
    'Construction stilts',
    'Consumables and spare parts for TIG welding',
    'Ballast rheostats',
    'Telfer and hoist remotes',
    'DIN rails and accessories',
    'Liquid concrete',
    'Welding helmets',
    'Gas reducers',
    'Locksmith punches',
    'Interfloor stairs',
    'Chucks for drills and crewdrivers',
    'Press tongs',
    'Stones for stoves',
    'Medium for heating systems',
    'Decoders',
    'Saunas for home',
    'Attic ladders',
    'GNSS equipment',
    'Accessories for lifts',
    'Spot welding machines',
    'Ventilation clamps',
    'Accessories for concrete mixers',
    'Pipe cleaners',
    'Construction rivets',
    'Pipes for water floor heating',
    'Pneumatic ratchet',
    'Consumables for spray guns',
    'Roll roofing',
    'Signal indicator lamps',
    'Theodolites',
    'Other measuring instruments',
    'Demolition hammers',
    'Accessories for toilets and urinals',
    'Soldering chemicals and lubricants',
    'Chillers for laser machines',
    'Hopper buckets',
    'Thermoelectric mats',
    'Tool guides and stops',
    'Circuit breakers',
    'Heating cable and accessories',
    'Assembly knives',
    'Self-leveling floor tools',
    'Control systems for boilers',
    'Road barriers',
    'Shower curtains for the bath',
    'Concrete',
    'Cable rewinding machines',
    'Stirring nozzles',
    'Sabre saws',
    'Metal channel',
    'Bearings',
    'Onduline',
    'Tacheometers',
    'Nozzles for welding machines',
    'Sewer hatches',
    'Augers for motor drills',
    'Solar inverters',
    'Power supplies for laser machines',
    'Klupp',
    'Gas convectors',
    'Accessories for plastering stations',
    'Air compressors',
    'Air Compressor Oils',
    'Frequency converters',
    'Lathes',
    'Soft starters',
    'Gasoline wrenches',
    'Cable clamps',
    'Pipe welding machines',
    'Furnaces for homes',
    'Vibrating plates',
    'Welding plugs and sockets',
    'Corner cutting machines',
    'Flanges',
    'Facade brackets',
    'Gas pressure regulators',
    'Electric underfloor heating',
    'Accessories for electric generators',
    'Cable tools',
    'Hydraulic cylinders',
    'Vibratory rammers',
    'Releases and relays',
    'Staple guns',
    'Refractory materials',
    'Button posts',
    'Polyurethane',
    'Gas burners for sauna stoves',
    'Refrigeration repair tool',
    'Roof cutters',
    'Accessories for laser levels',
    'Armature',
    'Sheet benders',
    'Accessories for climatic technology',
    'Security smoke generators',
    'Accessories for gas welding',
    'Welding wire',
    'Spare parts for power tools',
    'Plate heat exchangers',
    'Gas burners',
    'Coal for heating',
    'Industrial electric motors',
    'Accessories for vibrating plates',
    'Forms for concrete samples',
    'Control panels for electric ovens',
    'Steel ropes',
    'Machines for the production of splitter blocks',
    'Metalwork beards',
    'Pressure pumps',
    'Drilling machines',
    'Security detectors',
    'Fuse holders',
    'Plastic welding machines',
    'Welding torches',
    'Laser markers',
    'Exhaust fans',
    'Bench grinders',
    'Vibrating sanders',
    'Crowbar and nail clippers',
    'Stretch ceiling accessories',
    'Accessories for contactors and relays',
    'Plastering stations',
    'Cement',
    'Power tool sets',
    'Gas cutters',
    'Filters for pnumatic tool',
    'Adjustable log and tile supports',
    'Residential trailers',
    'Other fire equipment',
    'Diesel generator sets',
    'Hydro drilling kits',
    'Thermo blanket for gas cylinders',
    'Angle grinders',
    'Tap holders',
    'Instrument transformers',
    'Mounting manholes claws and accessories',
    'Wood routers',
    'Rebar bending machines',
    'Sheet metal',
    'Diamond drilling machines',
    'Shut-off flaps',
    'Profile hangers and drywall fasteners',
    'Rebar tying guns',
    'Sandblasting machines and guns',
    'Water fan heaters',
    'Plasma shrouds',
    'Pneumatic tool sets',
    'Alarm control panels',
    'Concrete heating transformers',
    'Chimneys for boilers',
    'Electric soldering irons',
    'Steam generators for baths',
    'Industrial compressed air dryers',
    'Metal unwinders',
    'Flushing pumps',
    'Welding machines',
    'Pipe cutters',
    'Built-in convectors',
    'Current Clamp',
    'Optical splitters',
    'Gas control cabinets',
    'Pneumatic impact wrenches',
    'Axes',
    'Accessories for lightning protection',
    'Accessories for chimneys',
    'Compressor hoses',
    'Electric hand planers',
    'Balancing and control valves',
    'Fire fittings',
    'Reinforced concrete products',
    'Polyurethane spraying equipment',
    'Spray guns',
    'Nail guns',
    'Electric actuators for control valves',
    'Fire hydrants',
    'Accessories for water pumps',
    'Plasma cutters',
    'Laser machines',
    'Motor driven augers',
    'Dosing pumps',
    'Heat pumps',
    'Pneumatic jackhammers',
    'Bolt cutters',
    'DIN rail power supplies',
    'Hammer drills',
    'Accessories for pipe benders',
    'Electrode holders',
    'Band saws',
    'Thicknessers',
    'Trowel machines',
    'Solar collectors',
    'Crushers and shredders for plastic',
    'Relay',
    'Multifunctional woodworking machines',
    'Guillotines',
    'Mounting cartridges',
    'Connecting sleeves',
    'Cold forging machines',
    'Plasma torches',
    'Induction heaters',
    'Welding rods',
    'Current transformers',
    'Bits for hammer drills',
    'Oscillating multitools',
    'Grinding machines',
    'Welding Gas Preheaters',
    'Electrical distribution boards',
    'Residual current circuit breakers',
    'Kleimers',
    'Expansion tanks for heating',
    'Slate',
    'Pneumatic engravers',
    'Nozzles for press punchers',
    'Components floor heating',
    'Battery adapters',
    'Pneumatic scissors',
    'Tile saws',
    'Jig saws',
    'Planer machines',
    'Seam cutter',
    'Concrete mixers',
    'Painting machines',
    'Chainsaw spark plugs',
    'Ceiling electric heater',
    'Fittings for boiler rooms and heating points',
    'Gasoline and electric cutters',
    'Stud drivers',
    'Acoustic islands and baffles',
    'Grinding rollers for brush grinders',
    'Blades for hydraulic tools',
    'Sauna stoves',
    'Damper tapes',
    'Gas heaters',
    'Pneumatic jigsaws and saws',
    'Cutting discs',
    'Saw blades',
    'Construction scissors',
    'Compressor receivers',
    'Seaming machines',
    'Staples for construction stapler',
    'Hydraulic equipment',
    'Miter boxes',
    'Tubes for laser machines',
    'Biofuel for fireplaces',
    'Knives for planers',
    'Chainsaw chain sharpening tool',
    'Edge banding machines',
    'Refractometers',
    'Blades for saws',
    'Plastering trowels',
    'Rolling machines',
    'Tubing benders',
    'Cable glands',
    'Belt grinders',
    'Accessories for barriers',
    'Construction vibrators',
    'Miter saws',
    'Refrigerants',
    'Vacuum forming machines',
    'Contact conductive paste',
    'Machine tools accessories',
    'Fittings for SIP',
    'Riveters',
    'Grids for the furnace',
    'Protective welding screens',
    'Chainsaws',
    'Optical cables',
    'Electrical fuses',
    'Drilling tools',
    'Maser - Nut Cutter',
    'Construction studs and pins',
    'Pneumatic grinders',
    'Chains and bars for saws',
    'Control stations for submersible pumps',
    'Wall chasers',
    'Chisel',
    'Drywall cutters',
    'Collets for welding',
    'Acetylene generators',
    'Ovens for drying electrodes',
    'Chemicals for heating',
    'Grinding cup wheels',
    'Gas bottles',
    'Metal polymers',
    'Rebar cutting machines',
    'Polishing pastes',
    'Mounting platforms',
    'Accessories for construction deep vibrators',
    'Wire liners',
    'Hooks for knitting fittings',
    'Consumables and spare parts for semi automatic MIG MAG',
    'Chimneys',
    'Cooling units for welding',
    'Autorun blocks for generators',
    'Heated tobacco systems',
    'Alcohol preparation accessories',
    'Wineglasses',
    'Ashtrays',
    'Lighters',
    'Spare parts and accessories for lighters',
    'Kitchen mortars',
    'Aquarium Spare Parts',
    'Shearing machines spare parts',
    'Colostrum defrosters',
    'Milking machines',
    'Veterinary syringes',
    'Poultry plucking machines',
    'Harvesting silage',
    'Milking machine spare parts',
    'Hoof treatment tools',
    'Decrystallizers',
    'Grain crushers',
    'Milk separators',
    'Veterinary equipment',
    'Bird brooders',
    'Jewelry cleaners',
    'Imitation piercing jewelry',
    'Rosary',
    'Piercing jewelry',
    'Jewelry rosary',
    'Knives and cutting boards',
];
