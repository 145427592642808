import { PaymentTypes } from '../../pages/checkout/const/PaymentTypes';
import { PaymentTypeStatus } from '../../pages/checkout/const/PaymentTypeStatus';

export enum TransactionFinalScreenType {
  creditApproved = 'Credit Approved',
  cardPaymentApproved = 'Card Payment Approved',
  creditOrderCreated = 'Credit Order Created',
  cardOrderCreated = 'Card Order Created',
  creditPreApproved = 'Credit - Sent to Bank Branch',
  creditAlternativeByTerm = 'Credit Alternative By Credit Term',
  creditAlternativeBySum = 'Credit Alternative By Sum',
  preApprovedNeedECP = 'Pre Approved - Need ECP',
  preApprovedNeedConfirmId = 'Pre Approved - Need to Confirm ID',
  creditRejected = 'Credit Rejected',
}

export function getTransactionFinalScreen(payment: IPaymentStatus) {
  let type: string = '';

  switch (payment.status) {
    case PaymentTypeStatus.approved:
      type =
        payment.type === PaymentTypes.CREDIT
          ? TransactionFinalScreenType.creditApproved
          : TransactionFinalScreenType.cardPaymentApproved;
      break;
    case PaymentTypeStatus.inProgress:
      type =
        payment.type === PaymentTypes.CREDIT
          ? TransactionFinalScreenType.creditOrderCreated
          : TransactionFinalScreenType.cardOrderCreated;
      break;
    case PaymentTypeStatus.preApproved:
      type = TransactionFinalScreenType.creditPreApproved;
      break;
    default:
      type = TransactionFinalScreenType.creditOrderCreated;
      break;
  }

  return type;
}
