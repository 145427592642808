import { parseQueryString } from '../../helpers/parseQueryString';

import { getProductInfo } from './getProductInfo';
import { getAdInfo } from './getAdInfo';
import { getStartedFrom } from './getStartedFrom';
import { getSupplier } from './getSupplier';
import { getFromUrl } from './getParamFromSearchQuery';
import { getCategoryHierarchyProps } from './getCategoryHierarchyProps';
import { getPageCategory } from './getPageCategory';
import { isPrevPageEquals } from './windowHistoryHelper';

export function combineProductEventProperties(data: IParamViewedProductPage | IParamViewedProductOffersPage) {
  const startedFrom = getStartedFrom();
  const categoryHierarchyProps = getCategoryHierarchyProps(data.product?.category);
  const productInfo = getProductInfo(data.product);
  const adInfo = getAdInfo();
  const supplier = getSupplier(data.product);
  let searchTerm: string;

  if (isPrevPageEquals('search')) {
    const refQueryStr = document.referrer.substring(document.referrer.indexOf('?'));
    searchTerm = parseQueryString(refQueryStr)?.text;
  }

  let path = document.location.pathname;
  const ut = getFromUrl.ut();
  if (ut) {
    path += `?ut=${ut}`;
  }

  const parameters = {
    path,
    referrer: getFromUrl.referrer(),
    ...startedFrom,
    ...categoryHierarchyProps,
    ...productInfo,
    ...adInfo,
    ...supplier,
    listing_position: getFromUrl.listingPosition(),
    search_term: searchTerm,
    query_id: getFromUrl.queryId(),
    available_filters: data.filters?.map((filter: IFiltersDate) => filter.title),
    inner_source: getPageCategory(document.referrer),
    offers_quantity: data.total || data.product.offersQuantity,
    utm_campaign: getFromUrl.utmCampaign(),
    product_type: data.product.product_type,
    category_id: data.product.categoryId,
  };

  return parameters;
}
