export function sortPromoBadges({
  config,
  badges,
}: {
  config: BadgeType[];
  badges: IBadge[];
}) {
  console.assert(config, 'sortPromoBadges: config is required');
  const result = [];
  if (badges?.length) {
    if (badges.length === 1) {
      return badges;
    }

    config.forEach((type) => {
      const badge = badges.find((item) => item.type === type);

      if (badge) {
        result.push(badge);
      }
    });

    return result;
  } else {
    return null;
  }
}
