import { CheckoutMethod } from '../../pages/checkout/const/CheckoutMethod';
import gtm, * as gtmTypes from '../../services/gtmService';

import { getCategoryHierarchyProps } from '../helpers/getCategoryHierarchyProps';
import { getPageCategory } from '../helpers/getPageCategory';
import { getTransactionCartParams } from '../helpers/getTransactionCartParams';
import { isProductSelectedInCart } from '../helpers/isProductSelectedInCart';

export class GTMMapper {
  viewedItemCard(data: IParamProductTeaser) {
    const { product, listingPosition } = data;

    const ecommerce: gtmTypes.IViewItemList = {
      item_list_name: getPageCategory(document.location.pathname),
      items: [
        {
          ...gtmItemMapper(product),
          index: listingPosition,
        },
      ],
    };

    gtm.push({
      event: 'view_item_list',
      ecommerce,
    });
  }

  viewedProductPage(data: IParamViewedProductPage) {
    const { product } = data;

    const ecommerce: gtmTypes.IViewItem = {
      currency: 'KZT',
      value:
        product.unitPrice || product.unitSalePrice || product.price || null,
      items: [gtmItemMapper(product)],
    };

    gtm.push({
      event: 'view_item',
      ecommerce,
    });
  }

  addedProductInCart(data: IParamChangeProductInCart) {
    const { product } = data;

    const ecommerce: gtmTypes.IAddToCart = {
      currency: 'KZT',
      value:
        product.unitPrice || product.unitSalePrice || product.price || null,
      items: [
        {
          ...gtmItemMapper(product),
          quantity: data?.quantity,
        },
      ],
    };

    gtm.push({
      event: 'add_to_cart',
      ecommerce,
    });
  }

  viewedCheckoutOrderConfirmPage(data: IParamViewedCheckoutOrderConfirmPage) {
    data = JSON.parse(JSON.stringify(data));

    if (!data.transaction.lineItems) {
      return;
    }

    const isCartMethod =
      data.transaction.checkoutMethod === CheckoutMethod.cart;

    if (isCartMethod) {
      data.transaction.lineItems = data.transaction.lineItems.filter(
        (item: ITransactionLineItem) =>
          isProductSelectedInCart(item.product.id, item.supplier.id)
      );
    }

    const transactionParams = getTransactionCartParams(data.transaction);

    const items = data.transaction.lineItems.map(
      (item: ITransactionLineItem) => ({
        ...gtmItemMapper(item.product),
        affiliation: item.supplier?.id,
      })
    );

    const ecommerce: gtmTypes.IPurchase = {
      transaction_id: transactionParams.order_id,
      value: transactionParams.total_price,
      currency: 'KZT',
      items,
    };

    gtm.push({
      event: 'purchase',
      ecommerce,
    });
  }
}

const gtmItemMapper = (item) => {
  const mappedItem: gtmTypes.IBaseItem = {
    item_id: item.productCode || item.id,
    item_name: item.name || item.title,
    discount: item.discount || 0,
    item_brand: item.brand,
    price: item.unitPrice || item.unitSalePrice || item.price || null,
    ...gtmCategoriesMapper(item.category),
  };

  if (!isNaN(item.quantity)) {
    mappedItem.quantity = item.quantity;
  }

  Object.keys(mappedItem).forEach((key) => {
    // checks for null and undefined
    if (mappedItem[key] == null) {
      delete mappedItem[key];
    }
  });

  return mappedItem;
};

const gtmCategoriesMapper = (categoriesArray: string[]) => {
  const categories = getCategoryHierarchyProps(categoriesArray);

  return {
    item_category: categories.category_level_1,
    item_category2: categories.category_level_2 || null,
    item_category3: categories.category_level_3 || null,
    item_category4: categories.category_level_4 || null,
    item_category5: categories.category_level_5 || null,
  };
};
