import { Sentry } from '../../../../sentry';
import { isGeoEnabled } from '../const/sessionValues';
import { PaymentStepTypes } from '../pages/checkout/const/PaymentStepTypes';
import { checkoutData } from '../services/ddService';
import { EventEmitter } from '../services/eventEmitterService';
import { SessionStorageModel } from '../StorageModel/SessionStorageModel';
import { EventType } from './const/EventType';
import { getCategoryHierarchyArr } from './helpers/getCategoryHierarchyArr';
import { getSessionId } from './helpers/getSessionId';
import { getTransactionFinalScreen } from './helpers/getTransactionFinalScreen';
import { getTransactionFromSessionCart } from './helpers/getTransactionFromSessionCart';
import { setCartWithDelivery, setCartWithPayment } from './helpers/setCart';

/* eslint-disable */
export class EventServiceClass {
  eventMappers = [];

  emit(type: EventType, data: any) {
    EventEmitter.emit(type, data);
  }

  registrationMapper(mapper) {
    this.eventMappers.push(mapper);
  }

  clickedBuyNowButton(data: any) {
    data.ssohash = getSessionId();
  }

  addedProductToFavorites(data: IParamProductTeaser) {
    data.ssohash = getSessionId();
  }

  @emit
  addedProductInCart(data: IParamChangeProductInCart) {
    data.ssohash = getSessionId();
  }

  clickedOtherMerchantSelect(data: any) {
    data.cart = checkoutData.getSessionCart();
  }

  clickedCartProductQuantityIncrease(data: IParamChangeProductInCart) {
    data.cart = checkoutData.getSessionCart();
  }

  clickedCartProductQuantityDecrease(data: IParamChangeProductInCart) {
    data.cart = checkoutData.getSessionCart();
  }

  viewedRemoveProductPopup(data: IParamChangeProductInCart) {
    data.cart = checkoutData.getSessionCart();
  }

  clickedCartProductCheckbox(data: IParamChangeProductInCart) {
    data.cart = checkoutData.getSessionCart();
  }

  clickedCartOutOfStockMoreInfo(data: Partial<IParamChangeProductInCart>) {
    data.cart = checkoutData.getSessionCart();
  }

  clickedCartPriceChangedMoreInfo(data: Partial<IParamChangeProductInCart>) {
    data.cart = checkoutData.getSessionCart();
  }

  removedProductFromCart(data: IParamChangeProductInCart) {
    data.cart = checkoutData.getSessionCart();
  }

  removedNotAvailableProductFromCart(data: IParamChangeProductInCart) {
    data.cart = checkoutData.getSessionCart();
  }

  viewedSelectProductsToContinuePopup(data: IParamCartAlertView) {
    data.cart = checkoutData.getSessionCart();
  }

  selectedOptionInAlertAboutRemoving(data: IParamChangeProductInCart) {
    data.cart = checkoutData.getSessionCart();
  }

  clickedContinueButtonInCart(data: any) {
    data.cart = checkoutData.getSessionCart();
  }

  clickedRemoveSelectedProductsFromCart(
    data: Partial<IParamRemoveSelectedFromCart>
  ) {
    data.cart = checkoutData.getSessionCart();
  }

  clickedMerchantInCart(data: Partial<IParamCartMerchantClick>) {
    data.cart = checkoutData.getSessionCart();
  }

  viewedFastBuyNow(data: IParamViewedFastBuyNow) {
    data.cart = checkoutData.getSessionCart();
  }

  updateCartWithDelivery(data: any) {
    return {
      cart: setCartWithDelivery(data),
      isSpa: data.isSpa ?? true,
    };
  }

  updateCartWithPayment(data: any) {
    return setCartWithPayment(data);
  }

  clickedSpendBonus(data: any) {
    return { label: data.checked ? 'On' : 'Off' };
  }

  viewedCheckoutPaymentPage(data: any) {
    data.cart = checkoutData.getSessionCart();
  }

  viewedCheckoutLoanPage(data: any) {
    const type = data.loanForm.hiddenData?.applicationTypeRFO;
    let prefilled = 'empty';
    data.loanForm.form?.forEach((section) => {
      for (const field of section.fields) {
        // TODO: something
        if (field.input && field.id !== 'field-mobile-phone' && field.value) {
          prefilled = 'prefilled';
          return;
        }
      }
    });
    return { applicationType: `${type} / ${prefilled}` };
  }

  viewedHybrisCheckoutLoanPage(data: any) {
    const type = data.loanForm.hiddenData?.applicationTypeRFO;
    let prefilled = 'empty';
    data.loanForm.form?.sections.forEach((section) => {
      for (const row of section.rows) {
        // TODO: something
        if (row.input && row.id !== 'field-mobile-phone' && row.value) {
          prefilled = 'prefilled';
          return;
        }
      }
    });
    return { applicationType: `${type} / ${prefilled}` };
  }

  viewedCheckoutTrustedPersonPage(data: any) {
    data.cart = checkoutData.getSessionCart();
  }

  viewedCheckoutDecisionProgressPage(data: any = {}) {
    const sessionCart = checkoutData.getSessionCart();
    // if paymentMethod === PAY_WITH_CREDIT we have creditTerm
    data.paymentType = sessionCart.creditTerm ? PaymentStepTypes.credit : '';
    return data;
  }

  viewedCheckoutDecisionRejectedPage(data: IParamViewedCheckoutDecisionPage) {
    const sessionCart = checkoutData.getSessionCart();
    // if paymentMethod === PAY_WITH_CREDIT we have creditTerm
    if (sessionCart.creditTerm) {
      data.paymentType = PaymentStepTypes.credit;
    }
    return data;
  }

  viewedCheckoutOrderConfirmPage(data: IParamViewedCheckoutOrderConfirmPage) {
    data.transaction = getTransactionFromSessionCart(
      data.orders,
      data.orderGroupCode
    );
    data.finalScreen = getTransactionFinalScreen(data.payment);

    return data;
  }

  viewedCheckoutPostomatList(data: IParamCheckoutPostomatData) {
    data.cart = checkoutData.getSessionCart();
    return data;
  }

  clickedCheckoutPostomat(data: IParamCheckoutPostomatData) {
    data.cart = checkoutData.getSessionCart();
    return data;
  }

  clickedCheckoutPostomatAddress(data: IParamCheckoutPostomatData) {
    data.cart = checkoutData.getSessionCart();
    return data;
  }

  clickedCheckoutPostomatPickup(data: IParamCheckoutPostomatData) {
    data.cart = checkoutData.getSessionCart();
    return data;
  }

  viewedCheckoutPostomatBackdrop(data: IParamCheckoutPostomatData) {
    data.cart = checkoutData.getSessionCart();
    return data;
  }

  clickedCheckoutPostomatBackdrop(data: IParamCheckoutPostomatData) {
    data.cart = checkoutData.getSessionCart();
    return data;
  }

  selectedDeliveryMethod(data: any) {
    data.cart = checkoutData.getSessionCart();
    return data;
  }

  @emit
  viewedCheckoutError500Page() {}

  viewedCheckoutAlertError() {
    return {
      cart: checkoutData.getSessionCart(),
      deliveryOptions: checkoutData.getSessionDeliveryOptions(),
    };
  }

  viewedNearbyPostomatToDeliveryPointBackdrop() {
    return {
      cart: checkoutData.getSessionCart(),
      deliveryOptions: checkoutData.getSessionDeliveryOptions(),
    };
  }

  clickedAcceptNearbyPostomat() {
    return {
      cart: checkoutData.getSessionCart(),
      deliveryOptions: checkoutData.getSessionDeliveryOptions(),
    };
  }

  clickedRejectNearbyPostomat() {
    return {
      cart: checkoutData.getSessionCart(),
      deliveryOptions: checkoutData.getSessionDeliveryOptions(),
    };
  }

  clickedMapNearbyPostomat() {
    return {
      cart: checkoutData.getSessionCart(),
      deliveryOptions: checkoutData.getSessionDeliveryOptions(),
    };
  }

  /**
   * LISTING
   */

  viewedCategoryListing(data: IParamViewedPage) {
    data.ssohash = getSessionId();
  }

  viewedSearchResults(data: IParamViewedPage) {
    data.ssohash = getSessionId();
  }

  clickedApplyFilter(data: IParamFilterApply) {
    if (!data.getValueFromTreeCategory) {
      return data;
    }

    const categoryHierarchyArr = getCategoryHierarchyArr(data.treeCategory);
    data.value = categoryHierarchyArr.join('/');

    return data;
  }

  changedListingLayout(data: IParamChangedListingLayout) {
    data.value = data.value.replace('_', '');
    return data;
  }

  /**
   * end LISTING
   */

  updateUserIsGeoEnabled(data: any) {
    new SessionStorageModel({ prefix: 'checkout' }).saveValue({
      name: isGeoEnabled,
      value: data.isGeoEnabled.toString(),
    });
  }

  clickedOrderDetailsCancelButton(data: IParamOrderPage) {
    data.ssohash = getSessionId();
  }

  viewedProductPage(data: IParamViewedProductPage) {
    data.ssohash = getSessionId();
  }

  viewedProductMerchantSelect(data: IParamViewedProductMerchantSelect) {
    data.ssohash = getSessionId();
  }

  viewedMyOrders(data: IMyOrderAnalytic) {
    data.ssohash = getSessionId();
  }

  viewedOrderPage(data: IParamOrderPage) {
    data.ssohash = getSessionId();
  }

  viewedSearchForm(data: IParamSearchEventsParams) {
    data.ssohash = getSessionId();
  }

  viewedSearchHints(data: IParamSearchHintsEvents) {
    data.ssohash = getSessionId();
  }

  shopSearchQueryStart(data: IParamSearchEventsParams) {
    data.ssohash = getSessionId();
  }

  clickOnSuggestion(data: IParamClickOnSuggestion) {
    data.ssohash = getSessionId();
  }

  @emit
  viewedItemCard(data: IParamProductTeaser) {
    data.ssohash = getSessionId();
  }

  clickedItemCard(data: IParamProductTeaser) {
    data.ssohash = getSessionId();
  }

  viewedListingProductMerchants(data: IParamViewedListingProductMerchants) {
    data.ssohash = getSessionId();
  }

  viewedCrossSellBackdrop(data: IParamCrossSellBackdropData) {
    data.ssohash = getSessionId();
  }

  clickedCrossSellItemCard(data: IParamCrossSellBackdropData) {
    data.ssohash = getSessionId();
  }

  viewedCartPage(data: any) {
    data.cart = checkoutData.getSessionCart();
  }

  clickedAnalogGoodsSelect(data: any) {
    data.cart = checkoutData.getSessionCart();
  }

  viewedAnalogGoodsBackdrop(data: any) {
    data.cart = checkoutData.getSessionCart();
  }

  viewedCartOutOfStockButton(data: any) {
    data.cart = checkoutData.getSessionCart();
  }

  viewedCheckoutPage(data: any) {
    data.cart = checkoutData.getSessionCart();
  }

  clickedCheckoutPaymentButton(data: any) {
    data.cart = checkoutData.getSessionCart();
  }

  viewedPaymentBalanceAlert(data: any) {
    data.cart = checkoutData.getSessionCart();
  }

  viewedCreditApprovalInProgress(data: any) {
    data.cart = checkoutData.getSessionCart();
  }

  viewedShopCardPayment(data: any) {
    data.cart = checkoutData.getSessionCart();
  }

  viewedCreditApplicationForm(data: any) {
    data.cart = checkoutData.getSessionCart();
  }

  @emit
  viewedExperiment(data: IParamViewedExperiment) {
    data.ssohash = getSessionId();
  }

  call(propertyName: EventType, data?: any) {
    let result;
    try {
      result = this[propertyName]?.(data);
    } catch (error) {
      console.error(`EventService ${propertyName} emit error:`, error);
      Sentry?.captureException(error);
    }
    if (!result) {
      result = data;
    }
    this.emit(EventType[propertyName], result);
    this.eventMappers.forEach((mapper) => {
      if (mapper[propertyName]) {
        try {
          mapper[propertyName](result); // send data attribute
        } catch (error) {
          console.error(`EventService ${propertyName} mapper error:`, error);
          Sentry?.captureException(error);
        }
      }
    });
    return result;
  }
}
/* eslint-enable */

// decorate function for EventServiceClass
// старая реализация - лучше использовать функцию - call
function emit(
  target: object,
  propertyName: string,
  propertyDescriptor: PropertyDescriptor
): PropertyDescriptor {
  const method = propertyDescriptor.value;
  propertyDescriptor.value = function (...args: any[]) {
    let result;
    try {
      result = method.apply(this, args);
    } catch (error) {
      console.error(`EventService ${propertyName} emit error:`, error);
      Sentry?.captureException(error);
    }
    if (!result) {
      result = args[0];
    }

    this.emit(EventType[propertyName], result);
    this.eventMappers.forEach((mapper) => {
      if (mapper[propertyName]) {
        try {
          mapper[propertyName](result); // send data attribute
        } catch (error) {
          console.error(`EventService ${propertyName} mapper error:`, error);
          Sentry?.captureException(error);
        }
      }
    });
    return result;
  };
  return propertyDescriptor;
}
