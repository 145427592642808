export function getPcmBlockData(block: IPcmAnalyticBlockData): object {
  if (!block) {
    return {};
  }

  return {
    block_id: block.blockId,
    block_type: block.blockType,
    block_style: block.blockStyle,
    block_name: block.blockName,
    block_position: block.blockPosition,
    block_source: 'pcm',
  };
}
