import { CityCodes } from './CityCodes';

export const CitiesWithMap = [
  CityCodes.ALMATY,
  CityCodes.ASTANA,
  CityCodes.SHYMKENT,
  CityCodes.KARAGANDA,
  CityCodes.ATYRAU,
  CityCodes.AKTOBE,
  CityCodes.USTKAMENOGORSK,
  CityCodes.PAVLODAR,
  CityCodes.PETROPAVLOVSK,
  CityCodes.KOKSHETAU,
  CityCodes.AKTAU,
  CityCodes.KOSTANAY,
  CityCodes.URALSK,
  CityCodes.SEMEY,
  CityCodes.TARAZ,
  CityCodes.KYZYLORDA,
  CityCodes.EKIBASTUZ,
  CityCodes.RYDNIY,
  CityCodes.AKSU,
  CityCodes.KHROMTAU,
  CityCodes.IRGELI,
  CityCodes.ALGA,
  CityCodes.KANDYAGASH,
  CityCodes.BESAGASH,
  CityCodes.PANFILOVO,
  CityCodes.TUZDYBASTAY,
  CityCodes.RAIYMBEK,
  CityCodes.KOKSAI,
  CityCodes.BELBULAK,
  CityCodes.GULDALA,
  CityCodes.KASKELEN,
  CityCodes.BESKOL,
  CityCodes.MAMLIYTKA,
  CityCodes.TALDYKORGAN,
  CityCodes.KARABULAK,
  CityCodes.TALGAR,
  CityCodes.ZHANAOZEN,
  CityCodes.AYAGOZ,
  CityCodes.SHEMONAIHA,
  CityCodes.RIDDER,
  CityCodes.ALTAI,
  CityCodes.ABAI,
  CityCodes.TEMIRTAU,
  CityCodes.ZHEZKAZGAN,
  CityCodes.SATPAEV,
  CityCodes.BALKASH,
  CityCodes.KAPSHAGAI,
  CityCodes.TURKISTAN,
  CityCodes.AKSAI,
  CityCodes.SHUCHINSK,
  CityCodes.SHU,
  CityCodes.STEPNOGORSK,
  CityCodes.KORDAI,
  CityCodes.SHAKHTINSK,
  CityCodes.SARAN,
  CityCodes.KOSSHY,
  CityCodes.LISAKOVSK,
  CityCodes.ESIK,
  CityCodes.SARYAGASH,
  CityCodes.BAIKONYR,
  CityCodes.ZHETISAI,
  CityCodes.KULSARY,
  CityCodes.KENTAU,
  CityCodes.ATBASAR,
  CityCodes.OTEGEN_BATYR,
  CityCodes.ABAI_KARAGANDA,
  CityCodes.TOPAR,
];
