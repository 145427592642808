import { DeliveryStepTypes } from '../../../pages/checkout/const/DeliveryStepTypes';

interface IDeliveryOptionsParams {
  delivery_price: number;
  delivery_suborders_count: number;
  postomat_suborders_count: number;
  pickup_suborders_count: number;
}

export const getDeliveryOptionsParams = (
  deliveryOptions
): IDeliveryOptionsParams => {
  if (!deliveryOptions) {
    return {} as IDeliveryOptionsParams;
  }

  let delivery_suborders_count = 0;
  let postomat_suborders_count = 0;
  let pickup_suborders_count = 0;

  deliveryOptions.forEach((o) => {
    o.typeCase === DeliveryStepTypes.delivery && delivery_suborders_count++;
    o.typeCase === DeliveryStepTypes.postomat && postomat_suborders_count++;
    o.typeCase === DeliveryStepTypes.inStore && pickup_suborders_count++;
  });
  const deliveryPrices = deliveryOptions
    .filter((o) => o.typeCase === DeliveryStepTypes.delivery)
    .map((o) => o.data.price);

  const params = {
    delivery_price: deliveryPrices.length > 0 ? deliveryPrices : 0,
    delivery_suborders_count,
    postomat_suborders_count,
    pickup_suborders_count,
  };

  return params;
};
