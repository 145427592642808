export function combineProductReviewsEventProperties(
  data: IParamViewedProductReview
) {
  return {
    has_photo: !!data.review.galleryImages,
    position: data.position,
    product_id: window.digitalData.product.id,
    rating: data.review.rating,
    unique_id: data.review.id,
  };
}
