import { ApproveSignType } from '../../pages/checkout/const/ApproveSignType';

import { FiltersRangeType } from '../../const/filters';
import { PaymentStepTypes } from '../../pages/checkout/const/PaymentStepTypes';
import {
  digitalDataLayer,
  getTransformItem,
  viewedPageEvent,
  getPageInfo,
  updateDDCartFromSessionCart,
  updateDDUserGeoFromSession,
  getProductLabel,
  formatSupplierInfo,
  SupplierInfoType,
  updateCartData,
} from '../../services/ddService';
import { getTransactionFromSessionCart } from '../helpers/getTransactionFromSessionCart';

export class DigitalDataMapper {
  clickedAddToCartButton() {
    digitalDataLayer.push({
      category: 'Ecommerce',
      action: 'Clicked Add to Cart Button',
      name: 'Clicked Add to Cart Button',
    });
  }
  clickedAddToCartButtonProductList(data: IParamChangeProductInCart) {
    digitalDataLayer.push({
      category: 'Behaviour',
      label: data.product.category,
      name: 'Clicked Add to Cart Button',
      product: data.product.id,
    });
  }
  clickedBuyNowButton() {
    digitalDataLayer.push({
      category: 'Ecommerce',
      action: 'Clicked Buy Now Button',
      name: 'Clicked Buy Now Button',
    });
  }
  viewedItemConfiguratorBackdrop() {
    digitalDataLayer.push({
      category: 'Ecommerce',
      action: 'Viewed Item Configurator',
      name: 'Viewed Item Configurator',
    });
  }
  viewedProductDescription() {
    digitalDataLayer.push({
      category: 'ItemPageAnalytics',
      action: 'ProductDescriptionView',
      name: 'Viewed Product Description',
    });
  }
  viewedProductSpecifications() {
    digitalDataLayer.push({
      category: 'ItemPageAnalytics',
      action: 'ProductSpecsView',
      name: 'Viewed Product Specifications',
    });
  }
  addingToCartErrorOccurred(label: string) {
    digitalDataLayer.push({
      category: 'Ecommerce',
      action: 'Adding to Cart Error Occurred',
      name: 'Adding to Cart Error Occurred',
      label,
    });
  }
  addedProductInCart(data: IParamChangeProductInCart) {
    let supplier = null;

    if (data.supplier) {
      supplier = {
        id: data.supplier.id,
        name: data.supplier.name,
        position: data.supplier.position,
        rating: data.supplier.rating,
        reviewsCount: data.supplier.reviewsCount,
        deliveryOptions: data.supplier.deliveryOptions,
        deliveryCost: formatSupplierInfo(
          SupplierInfoType.DELIVERY_COST,
          data.offer
        ),
        deliveryDate: formatSupplierInfo(
          SupplierInfoType.DELIVERY_DATE,
          data.offer
        ),
        pickupDate: formatSupplierInfo(
          SupplierInfoType.PICKUP_DATE,
          data.offer
        ),
        totalOffersCount: data.product.supplierOffers
          ? data.product?.supplierOffers.length
          : null,
        unitSalePrice: data.product.unitSalePrice,
      };
    }

    digitalDataLayer.push({
      category: 'Ecommerce',
      name: 'Added Product',
      label: data.product.category,
      product: getTransformItem(data.product),
      quantity: data?.quantity,
      supplier,
    });
  }
  removedProductFromCart(data) {
    digitalDataLayer.push({
      category: 'Ecommerce',
      name: 'Removed Product',
      product: getTransformItem(data.product),
      quantity: data.quantity,
    });
  }
  addedProductToFavorites(data: IParamProductTeaser) {
    digitalDataLayer.push({
      category: 'Ecommerce',
      action: 'addToFavourite',
      name: 'Added item to Favourite',
      label: getProductLabel(data.product),
    });
  }
  removedProductFromFavorites(data: IParamProductTeaser) {
    digitalDataLayer.push({
      category: 'Ecommerce',
      action: 'removeFromFavourite',
      name: 'Removed item to Favourite',
      label: getProductLabel(data.product),
    });
  }
  clickedRemoveProduct(data) {
    digitalDataLayer.push({
      name: 'Clicked Remove Product',
      category: 'Ecommerce',
      product: data.product ? getTransformItem(data.product) : {},
      startedBy: data.startedBy, // 'Remove Button' or 'Counter'
    });
  }
  viewedAlertAboutRemovingFromCart() {
    digitalDataLayer.push({
      name: 'Viewed Alert about Removing from Cart',
      category: 'Ecommerce',
      action: 'Viewed Alert about Removing from Cart',
    });
  }
  selectedOptionInAlertAboutRemoving(data) {
    digitalDataLayer.push({
      name: 'Selected Option in Alert about Removing',
      category: 'Ecommerce',
      action: 'Selected Option in Alert about Removing',
      label: data.label,
    });
  }
  updateCartWithDelivery(data) {
    if (data.isSpa) {
      digitalDataLayer.pushChanges({
        cart: data.cart,
      });
    } else {
      updateCartData(data.cart);
    }
  }
  viewedCheckoutDeliveryPage() {
    digitalDataLayer.pushChanges({
      page: {
        ...getPageInfo('checkout'),
        category: 'Select Delivery Method',
      },
    });
    viewedPageEvent();
  }
  viewedCheckoutDeliveryMapPage() {
    digitalDataLayer.pushChanges({
      page: {
        ...getPageInfo('checkout'),
        category: 'Delivery Map',
      },
    });
    viewedPageEvent();
  }
  updateCartWithPayment(data) {
    if (data.isSpa) {
      digitalDataLayer.pushChanges({
        cart: data.cart,
      });
    } else {
      updateCartData(data.cart);
    }
  }
  clickedSpendBonus(data: any) {
    digitalDataLayer.push({
      name: 'Clicked Spend Bonus',
      category: 'Behaviour',
      action: 'Clicked Spend Bonus',
      label: data.label,
    });
  }
  viewedCheckoutPaymentPage() {
    digitalDataLayer.pushChanges({
      page: {
        ...getPageInfo('checkout'),
        category: 'Select Payment Method',
      },
    });
    viewedPageEvent();
  }
  viewedCheckoutPaymentConfirmationPage() {
    updateDDCartFromSessionCart();
    updateDDUserGeoFromSession();
    digitalDataLayer.pushChanges({
      page: {
        ...getPageInfo('checkout'),
        category: 'Card Payment Confirmation',
      },
    });
    viewedPageEvent();
  }
  viewedCheckoutLoanPage(data) {
    updateDDCartFromSessionCart();
    updateDDUserGeoFromSession();
    digitalDataLayer.pushChanges({
      page: {
        ...getPageInfo('checkout'),
        category: 'Credit Application',
        applicationType: data.applicationType,
      },
    });
    viewedPageEvent();
  }

  viewedHybrisCheckoutLoanPage(data) {
    updateDDCartFromSessionCart();
    updateDDUserGeoFromSession();
    digitalDataLayer.pushChanges({
      page: {
        ...getPageInfo('checkout'),
        category: 'Credit Application',
        applicationType: data.applicationType,
      },
    });
    viewedPageEvent();
  }

  viewedCheckoutTrustedPersonPage(data) {
    updateDDCartFromSessionCart();
    updateDDUserGeoFromSession();
    digitalDataLayer.pushChanges({
      page: {
        ...getPageInfo('checkout'),
        category: 'Credit Application Trusted Persons',
      },
    });
    viewedPageEvent();
  }

  viewedCheckoutDecisionProgressPage(data) {
    updateDDCartFromSessionCart();
    updateDDUserGeoFromSession();
    let category = 'Card Payment In Progress';
    if (data.paymentType === PaymentStepTypes.credit) {
      category = 'Credit Approval In Progress';
    }
    digitalDataLayer.pushChanges({
      page: {
        ...getPageInfo('checkout'),
        category,
      },
    });
    viewedPageEvent();
  }

  viewedCheckoutDecisionRejectedPage(data: IParamViewedCheckoutDecisionPage) {
    const transaction: any = {};
    let category = 'Card Payment Error';
    if (data.paymentType === PaymentStepTypes.credit) {
      category = 'Credit Rejected';
      transaction.cart = undefined;
      transaction.transaction = getTransactionFromSessionCart(data.orders);
    }
    digitalDataLayer.pushChanges({
      page: {
        ...getPageInfo('checkout'),
        category,
      },
      ...transaction,
    });
    viewedPageEvent();
  }

  viewedCheckoutDecisionNewSumPage(data: IParamViewedCheckoutDecisionPage) {
    const transaction: any = {};
    transaction.cart = undefined;
    transaction.transaction = getTransactionFromSessionCart(data.orders);

    digitalDataLayer.pushChanges({
      page: {
        ...getPageInfo('checkout'),
        category: 'Credit Alternative By Sum',
      },
      ...transaction,
    });
    viewedPageEvent();
  }

  viewedCheckoutDecisionNewConditionsPage(
    data: IParamViewedCheckoutDecisionPage
  ) {
    const transaction: any = {};
    transaction.cart = undefined;
    transaction.transaction = getTransactionFromSessionCart(data.orders);

    digitalDataLayer.pushChanges({
      page: {
        ...getPageInfo('checkout'),
        category: 'Credit Alternative By Credit Term',
      },
      ...transaction,
    });
    viewedPageEvent();
  }

  agreedToTheCreditAlternative(data) {
    digitalDataLayer.push({
      name: 'Agreed To The Credit Alternative',
      category: 'Ecommerce',
      action: 'Agreed To The Credit Alternative',
      label: data.label,
    });
  }
  rejectedTheCreditAlternative(data) {
    digitalDataLayer.push({
      name: 'Rejected The Credit Alternative',
      category: 'Ecommerce',
      action: 'Rejected The Credit Alternative',
      label: data.label,
    });
  }

  viewedCheckoutDecisionApprove(data: IParamViewedCheckoutDecisionPage) {
    const categoryPage = `Pre Approved - ${
      data.type === ApproveSignType.SIGN ? 'Need ECP' : 'Need to Confirm ID'
    } `;

    const transaction: any = {};
    transaction.cart = undefined;
    transaction.transaction = getTransactionFromSessionCart(data.orders);

    digitalDataLayer.pushChanges({
      page: {
        ...getPageInfo('checkout'),
        category: categoryPage,
      },
      ...transaction,
    });
    viewedPageEvent();
  }

  clickedOnApproveButton(type) {
    const name = 'Clicked Pre Approve Button';
    // тип кнопки: ECP - “Подписать документы онлайн”, FV - “Пройти фотографирование”
    digitalDataLayer.push({
      category: 'Ecommerce',
      name,
      action: 'Clicked Pre Approve Button',
      label: type === ApproveSignType.SIGN ? 'ECP' : 'FV',
    });
  }
  viewedCheckoutOrderConfirmPage(data: IParamViewedCheckoutOrderConfirmPage) {
    digitalDataLayer.pushChanges({
      page: {
        ...getPageInfo('checkout'),
        category: data.finalScreen,
      },
      cart: undefined,
      transaction: data.transaction,
    });
    viewedPageEvent();
  }

  viewedCheckoutError500Page() {
    digitalDataLayer.pushChanges({
      page: {
        ...getPageInfo('checkout'),
        category: 'Checkout Error',
      },
    });
    viewedPageEvent();
  }

  clickedSubmitReviewForm(data: any) {
    digitalDataLayer.push({
      category: 'ReviewSubmit',
      action: data.action, // Product or Merchant
      label: data.label, // Comment or Rating
    });
  }
  clickedDeliveryAddressSuggestion() {
    digitalDataLayer.push({
      category: 'Behaviour',
      action: 'Clicked Delivery Address Suggestion',
      name: 'Clicked Delivery Address Suggestion',
    });
  }
  startedDeliveryAddressEntering() {
    digitalDataLayer.push({
      category: 'Behaviour',
      name: 'Started Delivery Address Entering',
      action: 'Started Delivery Address Entering',
    });
  }
  viewedDeliveryAddressSuggestions() {
    digitalDataLayer.push({
      category: 'Behaviour',
      action: 'Viewed Delivery Address Suggestions',
      name: 'Viewed Delivery Address Suggestions',
    });
  }
  viewedNoDeliveryAddressSuggestions(data: any) {
    digitalDataLayer.push({
      category: 'Behaviour',
      action: 'Viewed No Delivery Address Suggestions',
      name: 'Viewed No Delivery Address Suggestions',
      label: data.label,
    });
  }
  updateUserIsGeoEnabled(data: any) {
    digitalDataLayer.pushChanges([
      'user.isGeoEnabled',
      data.isGeoEnabled,
      'Source Code',
    ]);
  }
  clickedDeliveryAddressOnGeoSuggestion() {
    digitalDataLayer.push({
      category: 'Behaviour',
      action: 'Clicked Delivery Address on Geo Suggestion',
      name: 'Clicked Delivery Address on Geo Suggestion',
    });
  }
  clickedMapOnGeoSuggestion() {
    digitalDataLayer.push({
      category: 'Ecommerce',
      action: 'Clicked Map on Geo Suggestion',
      name: 'Clicked Map on Geo Suggestion',
    });
  }
  clickedMapLink() {
    digitalDataLayer.push({
      category: 'Ecommerce',
      action: 'Clicked Map Link',
      name: 'Clicked Map Link',
    });
  }
  pickedAddressOnMap() {
    digitalDataLayer.push({
      category: 'Behaviour',
      action: 'Picked Address on Map',
      name: 'Picked Address on Map',
    });
  }
  clickedProductReviewLink() {
    digitalDataLayer.push({
      category: 'Order Management',
      action: 'Clicked on Product Review Link',
      name: 'Clicked on Product Review Link',
    });
  }
  clickedButtonTimeline(label: string) {
    digitalDataLayer.push({
      category: 'Order Management',
      action: 'Clicked on Button in Timeline',
      label,
      name: 'Clicked on Button in Timeline',
    });
  }
  clickedOrderDetailsCancelButton(data: IParamOrderPage) {
    digitalDataLayer.push({
      category: 'Order Management',
      action: 'Clicked on Order Cancel Button',
      name: 'Clicked on Order Cancel Button',
    });
  }
  clickedMerchantPhoneLink() {
    digitalDataLayer.push({
      category: 'Order Management',
      action: 'Clicked on Merchant Phone Link',
      name: 'Clicked on Merchant Phone Link',
    });
  }
  clickedOrderDetailsButton() {
    digitalDataLayer.push({
      category: 'Order Management',
      action: 'Clicked on Order Details Button',
      name: 'Clicked on Order Details Button',
    });
  }
  clickedOrderCancellationButton(data: IParamOrderCancellationReason) {
    digitalDataLayer.push({
      category: 'Order Management',
      action: 'Canceled Order',
      name: 'Canceled Order',
      label: data.reason,
    });
  }
  selectedCancellationReason(data: IParamOrderCancellationReason) {
    digitalDataLayer.push({
      category: 'Order Management',
      action: 'Selected Cancellation Reason',
      name: 'Selected Cancellation Reason',
      label: data.reason,
    });
  }

  viewedOrderPage(data: IParamOrderPage) {
    const { currentStatus, isTracking, nextStatus } = data;

    const order: any = {
      currentStatus,
      isTracking,
    };

    if (nextStatus) {
      order.nextStatus = nextStatus;
    }

    digitalDataLayer.pushChanges({
      page: {
        ...getPageInfo('content'),
        category: 'Order Details',
      },
      order,
    });
    viewedPageEvent();
  }

  clickedTrackOrderButton() {
    digitalDataLayer.push({
      category: 'Ecommerce',
      action: 'Clicked Track Order Button',
      name: 'Clicked Track Order Button',
    });
  }

  uploadedPictureReview(data: { count: number }) {
    digitalDataLayer.push({
      name: 'KS Events',
      action: 'Uploaded Picture Review',
      count: data.count /* Number of images uploaded */,
      category: 'Ecommerce',
    });
  }

  clickedRemovedPictureReview() {
    digitalDataLayer.push({
      name: 'KS Events',
      action: 'Removed Picture Review',
      category: 'Ecommerce',
    });
  }

  clickedPhotoReview() {
    digitalDataLayer.push({
      name: 'KS Events',
      action: 'Clicked Photo Review',
      category: 'Ecommerce',
    });
  }

  viewedCompletedReviewForm(mediaQuantity: number) {
    digitalDataLayer.pushChanges({
      page: {
        category: 'Review TYP',
        photoReviewCount: mediaQuantity,
      },
    });
    viewedPageEvent();
  }

  viewedProductReviews(data: any) {
    digitalDataLayer.push({
      category: 'ItemPageAnalytics',
      action: 'ProductReviewView',
      name: 'Viewed Product Reviews',
    });
  }

  clickDeliverySlot(data: any) {
    digitalDataLayer.push({
      category: 'Ecommerce',
      name: 'shop_delivery_timeslot_click',
      day: data.day,
      timeslot: data.timeslot,
    });
  }
}
