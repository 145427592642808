interface IStorageValue {
  name: string;
  value: string;
}

export abstract class StorageModel {
  private _storage: Storage;
  private _prefix: string;
  private _rootPrefix: string = 'ks';
  private expiresName: string = 'expiresTime';

  constructor({ prefix, storage }) {
    this._storage = storage;
    this._prefix = prefix;
  }

  get prefix() {
    return this._rootPrefix + '.' + (this._prefix ? this._prefix + '.' : '');
  }

  get fullExpireName() {
    const expireName = this.prefix + this.expiresName;
    return expireName;
  }

  get newExpiresTime() {
    const numberOfMinutes = 30;
    const minuteInMilliseconds = 60_000;
    return new Date(
      new Date().getTime() + numberOfMinutes * minuteInMilliseconds
    );
  }

  protected static supported(storage: Storage) {
    try {
      storage.setItem('test', 'test');
      storage.getItem('test');
      storage.removeItem('test');
      return true;
    } catch (e) {
      return false;
    }
  }

  saveValue({ name, value }: IStorageValue, ignorePrefix = false) {
    if (!(name && value)) {
      return;
    }

    if (this.supportedStorage()) {
      let itemName = name;
      if (ignorePrefix) {
        itemName = `${this._rootPrefix}.${itemName}`; 
      } else {
        itemName = this.prefix + name;
      }
      this._storage.setItem(itemName, value);
    }
  }

  getValue(name: string, ignorePrefix = false) {
    if (this.supportedStorage()) {
      let itemName = name;
      if (ignorePrefix) {
        itemName = `${this._rootPrefix}.${itemName}`;
      } else {
        itemName = this.prefix + name;
      }
      return this._storage.getItem(itemName);
    }
  }

  getValues(values: any[]) {
    if (this.supportedStorage()) {
      Object.keys(values).forEach((key) => {
        const el = values[key];

        if (el.name) {
          const val = this.getValue(el.name);

          if (val && !el.value) {
            el.value = val; // warning: conflict with input value
          }
        }
      });

      return values;
    }
    return {};
  }

  removeValue(name: string) {
    if (this.supportedStorage()) {
      this._storage.removeItem(this.prefix + name);
    }
  }

  checkExpire() {
    try {
      const currentTime = new Date();
      const expirationTime = this._storage.getItem(this.fullExpireName)
        ? new Date(this._storage.getItem(this.fullExpireName))
        : new Date();

      //
      if (currentTime.getTime() >= expirationTime.getTime()) {
        this.clear();
        this.setNewExpiresTime();
      }
    } catch (e) {
      console.warn('sessionStorage not support');
    }
  }

  setNewExpiresTime(expireName = this.fullExpireName) {
    this._storage.setItem(expireName, this.newExpiresTime.toString());
  }

  clear() {
    if (this.supportedStorage()) {
      for (const key in sessionStorage) {
        if (key.substring(0, this.prefix.length) === this.prefix) {
          sessionStorage.removeItem(key);
        }
      }
    }
  }

  abstract supportedStorage(): boolean;
}
