'use strict';

import { CountryCodes } from '../common/const/CountryCodes';

/**
 * Validator helps make checks for string values
 */
interface ICheck {
  notEmpty: any;
  isEmpty: any;
  word: any;
  wordWithSpace: any;
  lengthMoreThen: any;
  lengthLessThen: any;
  dateString: any;
  cyrillic: any;
  notLatin: any;
  date: any;
  whiteSpaces: any;
  numbers: any;
  numbersLimit: any;
  email: any;
  mobilePhone: any;
  website: any;
  ageMoreThen: any;
  ageLessThen: any;
  iin: any;
  mobileOperator: any;
  symbolsOutOfRange: any;
  addressSymbolsOutOfRange: any;
}

export enum ClientType {
  iin = 1,
  bin = 2,
}

interface ICheckByType {
  s: string;
  required: boolean;
  type: string;
  eventType: string;
}

const check: ICheck = {
  notEmpty(s) {
    return s.trim().length !== 0;
  },

  isEmpty(s) {
    return s.trim().length === 0;
  },

  word(s) {
    // last 4 symbols has different charCode on desktop and mobile devices
    const notNumbersRegExp =
      /^([a-zA-ZаÁǴŃÓÚÝáǵıńóúýа-яА-ЯёЁӘҒҚҢӨҰҮҺІяәғқңөұүһіəƏëË\s-]+)$/;
    return notNumbersRegExp.test(s);
  },

  wordWithSpace(s) {
    const notNumbersRegExpWithSpace =
      /^([a-zA-ZаÁǴŃÓÚÝáǵıńóúýа-яА-ЯёЁӘҒҚҢӨҰҮҺІяәғқңөұүһіəƏëË\s-]+)$/;
    return notNumbersRegExpWithSpace.test(s);
  },

  lengthMoreThen(s, limit) {
    if (s.length > limit) {
      return false;
    }
  },

  lengthLessThen(s, limit) {
    if (s.length < limit) {
      return false;
    }
  },

  dateString(s: string, separator) {
    const bits = s.split(separator || '.');
    const y = parseInt(bits[2], 10),
      m = parseInt(bits[1], 10),
      d = parseInt(bits[0], 10);
    return check.date(y, m, d);
  },

  cyrillic(s: string) {
    const reg = /^([а-яА-ЯёЁӘҒҚҢӨҰҮҺІяәғқңөұүһіəƏëË\d\s-]+)$/;

    return reg.test(s);
  },

  notLatin(s: string) {
    const reg = /[a-zA-ZÁǴŃÓÚÝáǵıńóúý]/;

    return !reg.test(s);
  },

  date(y: number, m: number, d: number) {
    // Assume not leap year by default (note zero index for Jan)
    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (y % 4 === 0) {
      daysInMonth[1] = 29;
    }
    return d <= daysInMonth[--m] && d !== 0;
  },

  whiteSpaces(s) {
    const spacesRegExp = /\s/;
    return spacesRegExp.test(s);
  },

  numbers(s) {
    const numbersRegExp = /^[\s\d]+$/;
    return numbersRegExp.test(s);
  },

  numbersLimit(s) {
    const numbersRegExp = /^[\s\d]+$/;
    if (s.length > 8) {
      return false;
    }
    return numbersRegExp.test(s);
  },

  email(s) {
    // this is W3C HTML5 regexp for emails
    const emailRegExp =
      /^[a-zA-Z0-9._%"“”+-]+@(([a-zA-Z]+\-?[a-zA-Z]+(\.[a-zA-Z]{2,6})+)|(\[?([0-9]{3}\.?){4}\]?))$/;
    return emailRegExp.test(s);
  },

  mobilePhone(s) {
    const phoneRegExp = /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/;
    return phoneRegExp.test(s);
  },

  website(s) {
    const websiteRegExp =
      /^([0-9a-zA-ZаÁǴŃÓÚÝáǵıńóúýа-яА-ЯёЁӘҒҚҢӨҰҮҺІяәғқңөұүһіəƏëË.-_\s-]+)$/;
    return websiteRegExp.test(s);
  },

  ageMoreThen(s, age, separator) {
    const bits = s.split(separator || '.');
    const inputDate = new Date(bits[2], bits[1] - 1, bits[0]);
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - (age + 1));
    return inputDate < maxDate;
  },

  ageLessThen(s, age, separator) {
    const bits = s.split(separator || '.');
    const inputDate = new Date(bits[2], bits[1] - 1, bits[0]);
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - age);
    return inputDate > minDate;
  },

  // took from here http://yvision.kz/post/114938
  iin(
    iin: string,
    clientType: ClientType,
    birthDate: Date,
    sex: boolean,
    isResident: boolean
  ) {
    // additional check for zeroes
    // Number.parseInt not yet supported in IE, Safari
    if (parseInt(iin) === 0) {
      return false;
    }
    // clientType: 1 - Физ. лицо (ИИН), 2 - Юр. лицо (БИН)
    // birthDate: дата рождения (в формате Javascript Date)
    // sex: true - м, false - ж
    // isResident: true - резидент, false: нерезидент (true: по умолчанию)
    isResident = isResident || true;
    if (!iin) {
      return false;
    }

    if (iin.length !== 12) {
      return false;
    }

    if (!/[0-9]{12}/.test(iin)) {
      return false;
    }
    switch (clientType) {
      case 1:
        // Физ. лицо
        // Проверяем первый фасет на совпадение с датой рождения ГГММДД;
        if (
          iin.substring(0, 6) !==
          '' +
            birthDate.getFullYear() +
            (birthDate.getMonth() + 1 < 10 ? '0' : '') +
            (birthDate.getMonth() + 1) +
            (birthDate.getDate() < 10 ? '0' : '') +
            birthDate.getDate()
        ) {
          return false;
        }
        // Проверяем пол и век рождения
        const s = parseInt(iin.substring(6, 7));
        if ((s % 2 === 1) !== sex) {
          return false;
        }
        if (
          birthDate.getFullYear() < 1800 + parseInt(s / 2 + '') * 100 ||
          birthDate.getFullYear() > 1900 + parseInt(s / 2 + '') * 100
        ) {
          return false;
        }
        break;
      case 2:
        // Юр. лицо
        // Проверяем корректность даты (насколько это возможно)
        const m = parseInt(iin.substring(2, 4));
        if (m > 12) {
          return false;
        }
        // Проверяем признак резидентства
        const r = parseInt(iin.substring(4, 5));
        if (
          r < 4 ||
          r > 6 ||
          (r === 4 && !isResident) ||
          (r === 5 && isResident)
        ) {
          return false;
        }
        break;
      default:
        // Физ. лицо
        // Проверяем корректность даты (насколько это возможно)
        if (
          !check.date(
            parseInt(iin.substring(0, 2), 10),
            parseInt(iin.substring(2, 4), 10),
            parseInt(iin.substring(4, 6), 10)
          )
        ) {
          return false;
        }
        break;
    }
    // Проверяем контрольный разряд
    const b1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const b2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2];
    const a: number[] = [];
    let controll = 0;
    for (let i = 0; i < 12; i++) {
      a[i] = parseInt(iin.substring(i, i + 1));
      if (i < 11) {
        controll += a[i] * b1[i];
      }
    }
    controll = controll % 11;
    if (controll === 10) {
      controll = 0;
      for (let i = 0; i < 11; i++) {
        controll += a[i] * b2[i];
      }
      controll = controll % 11;
    }
    return controll === a[11];
  },

  mobileOperator(s: string, country: string = CountryCodes.KZ) {
    const matched = s.match(/\((\d{3})\)/);
    // all digits should be filled

    if (!matched || !matched[1]) {
      return true;
    }

    const operators = {
      [CountryCodes.KZ]: window.BACKEND.components.form.kzPhoneOperators,
      [CountryCodes.RU]: window.BACKEND.components.form.ruPhoneOperators,
    };

    // checking if it contains operator
    return operators[country].some((operator) => {
      const operatorRegExp = new RegExp('.*(' + operator + ')');
      return operatorRegExp.test(matched[1]);
    });
  },

  /**
   *
   *
   * @param {String} s
   * @param {RegExp} whiteList
   * @returns {?Array}
   */
  symbolsOutOfRange(s, whiteList) {
    return s.match(whiteList);
  },

  addressSymbolsOutOfRange(s) {
    return check.symbolsOutOfRange(
      s,
      /[^ 0-9a-zA-ZаÁIǴŃÓÚÝáǵıńóúýа-яА-ЯёЁӘҒҚҢӨҰҮҺІяәғқңөұүһіəƏëË#-.',/()"«»:;№\\]/gu
    ); // jshint ignore:line
  },
};

/**
 * @function correctDate
 * @return {String} correction value.
 * @param {String} value to be with length = 8
 */
const correctDate = (value: string): string => {
  const currentYear = new Date().getFullYear() % 100;
  const currentCentury = parseInt(new Date().getFullYear() / 100 + '');
  const year = value.slice(6);

  const newYear =
    String(currentCentury - (Number(year) > currentYear ? 1 : 0)) + year;

  return value.slice(0, 6) + newYear;
};

/**
 * @returns {object} {checks: {[checkName: bool]}, failedChecks: [[checkName,]]} it also inherits from ops param
 */
const checkByType = (ops: ICheckByType): object => {
  ops.eventType = ops.eventType || 'submit';
  const vObj = Object.create(ops);
  vObj.checks = {};
  vObj.failedChecks = [];
  ops.s = ops.s.trim();

  switch (ops.type) {
    case 'mobile-phone':
    case 'merchant-employee-mobile-phone':
      break;

    case 'home-phone':
    case 'merchant-company-name':
    case 'merchant-daily-orders-quantity':
    case 'merchant-average-check':
    case 'merchant-employee-position':
    case 'delivery-street':
    case 'delivery-street-num':
    case 'delivery-num-app':
      break;

    case 'kz-mobile-phone':
      vObj.checks.mobileOperator = check.mobileOperator(ops.s);
      break;

    case 'email':
    case 'merchant-employee-email':
      switch (ops.eventType) {
        case 'input':
          break;
        case 'submit':
          vObj.checks.email = check.email(ops.s);
          break;
      }
      break;

    case 'name':
    case 'surname':
    case 'middle-name':
    case 'merchant-employee-name':
    case 'merchant-employee-surname':
      vObj.checks.word = check.word(ops.s);
      vObj.checks.lengthMoreThen = check.lengthMoreThen(ops.s, 40);
      break;

    case 'name-full':
      vObj.checks.wordWithSpace = check.wordWithSpace(ops.s);
      break;

    case 'birth-date':
      switch (ops.eventType) {
        case 'input':
          if (ops.s.length >= 10 || ops.s.length === 8) {
            vObj.checks.dateString =
              check.dateString(ops.s) && check.ageLessThen(ops.s, 100);
            vObj.checks.ageMoreThen = check.ageMoreThen(ops.s, 15);
          }
          break;
        case 'submit':
          vObj.checks.dateString =
            check.dateString(ops.s) && check.ageLessThen(ops.s, 100);
          vObj.checks.ageMoreThen = check.ageMoreThen(ops.s, 15);
          break;
      }
      break;

    case 'document-number':
      switch (ops.eventType) {
        case 'submit':
          vObj.checks.lengthLessThen = check.lengthLessThen(ops.s, 9);
          break;
      }
      break;

    case 'salary':
    case 'spouse-salary':
    case 'additional-income':
    case 'pensions':
    case 'utility-expension':
    case 'current-payments':
    case 'merchant-shops-quantity':
      const limit = window.BACKEND.state.disableMask ? 8 : 10;
      vObj.checks.numbers = check.numbers(ops.s);
      vObj.checks.lengthMoreThen = check.lengthMoreThen(ops.s, limit);
      break;

    case 'iin':
      switch (ops.eventType) {
        case 'input':
          if (ops.s.length > 11) {
            vObj.checks.iin = check.iin(ops.s);
          }
          break;
        case 'submit':
          vObj.checks.iin = check.iin(ops.s);
          break;
      }
      vObj.checks.numbers = check.numbers(ops.s);
      break;

    case 'sms-code':
      switch (ops.eventType) {
        case 'submit':
          vObj.checks.numbers = check.numbers(ops.s);
          vObj.checks.lengthLessThen = check.lengthLessThen(ops.s, 4);
          break;
      }
      break;

    default:
      console.error(
        'validator.checkByType doesn\'t know "' + ops.type + '" input type'
      ); // eslint-disable-line
  }

  // If input is required we should check it for emptiness on submit, too;
  if (ops.required && ops.eventType === 'submit') {
    vObj.checks.notEmpty = check.notEmpty(ops.s);
  }

  // We do not need other error messages for empty field except this one
  if (vObj.checks.notEmpty === false) {
    vObj.failedChecks.push('notEmpty');
  } else {
    // filter failed checks
    for (const failedCheck in vObj.checks) {
      if (
        vObj.checks.hasOwnProperty(failedCheck) &&
        vObj.checks[failedCheck] === false
      ) {
        vObj.failedChecks.push(failedCheck);
      }
    }
  }
  return vObj;
};

/**
 * validates string against validator function(-s)
 * @param {(Function|Function[])} validators
 * @return {(Boolean|?Array)}
 */
function validate(validators: any, str: string) {
  if (Array.isArray(validators)) {
    return validators.every((validator) => validator(str));
  } else {
    return validators(str);
  }
}

export default {
  check,
  checkByType,
  validate,
  correctDate,
};
