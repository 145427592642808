'use strict';

import { DeliveryOptions } from '../../common/const/DeliveryOptions';
import { DiscountOffersType } from '../analytic/const/DiscountOffersType';
import { productUnitType } from '../analytic/const/productUnitType';
import { transformCreatedTime } from '../analytic/helpers/transformCreatedTime';
import { isGeoEnabled } from '../const/sessionValues';
import { CheckoutMethod } from '../pages/checkout/const/CheckoutMethod';
import { SessionStorageModel } from '../StorageModel/SessionStorageModel';
import { addService } from './ksServices';

const serviceName = 'ddService';

/**
 * @desc DDM Push Event => Viewed Page
 */
export const viewedPageEvent = (data?: any) => {
  digitalDataLayer.push({ name: 'Viewed Page', ...data });
};

/**
 * @desc google-analytics wrapper implementing safe window.digitalData.push action
 */
export const digitalDataLayer = {
  push(data: any) {
    if (window.digitalData) {
      window.digitalData.events.push(data);
    }
  },
  pushChanges(data: any) {
    if (window.digitalData) {
      window.digitalData.changes.push(data);
    }
  },
};

// TODO: remove with desktop event
export function updateListingWith({
  currentPage,
  sortBy,
  items,
  listingLayout = null,
  pushEvent = true,
  queryID = '',
}) {
  if (!items) {
    /*  eslint-disable-next-line */
    console.error('updateListingWith failed - listing data is missing');
    return;
  }

  window.digitalData.listing = window.digitalData.listing || {};

  items = items.map(transformItem());
  listingLayout = listingLayout?.replace('_', '') || '';

  Object.assign(window.digitalData.listing, {
    currentPage,
    sortBy,
    items,
    queryID,
    listingLayout,
  });

  if (pushEvent) {
    setTimeout(() => viewedPageEvent({ source: 'code' }), 0); // call after redraw
  }
}

export function updateTeasersData({
  teasers,
  isDirtyData = false,
}: {
  teasers: ITeaser[];
  isDirtyData?: boolean;
}) {
  let analitycData = [] as any;

  if (isDirtyData) {
    analitycData = teasers;
  } else {
    // transport teasers in dirty data
    analitycData = teasers.map((teaserData) => ({
      listId: teaserData.listId,
      listName: teaserData.title,
      items: teaserData.cards.map(transformItem()),
    }));
  }

  if (!window.digitalData.recommendation) {
    window.digitalData.recommendation = [];
  }

  analitycData.forEach((item) => {
    window.digitalData.recommendation.push(item);
  });
}

/**
 * Adding ddService->updateTeasersData to window.ksService
 */
// addService(serviceName, { updateTeasersData });

export function updateProductRecommendationData(data: any) {
  const analitycData = {
    listId: data.listId,
    listName: data.listName,
    resultCount: data.resultCount,
    items: data.items.map(transformItem()),
  };

  if (!window.digitalData.recommendation) {
    window.digitalData.recommendation = [];
  }

  const checkListId = window.digitalData.recommendation.find(
    (item) => item.listId === analitycData.listId
  );

  if (checkListId) {
    analitycData.items.forEach((item) => {
      checkListId.items.push(item);
    });
  } else {
    window.digitalData.recommendation.push(analitycData);
  }
}

function getCardFromRecommendation(cardId: string) {
  const recommendations = window.digitalData?.recommendation || [];
  let result = {};

  recommendations.forEach((recommendation) => {
    const foundItem = recommendation.items.find((item) => item.id === cardId);
    if (foundItem) {
      result = foundItem;
    }
  });

  return result;
}

export function updateCartData(cart) {
  checkoutData.set({ cart });
  window.digitalData.cart = { ...window.digitalData.cart, ...cart };
}

export function updateDDCartFromSessionCart() {
  const sessionCart = checkoutData.getSessionCart();
  updateCartData(sessionCart);
}

export function updateDDUserGeoFromSession() {
  const isEnabled =
    new SessionStorageModel({ prefix: 'checkout' }).getValue(isGeoEnabled) ??
    null;
  if (isEnabled === null) {
    return;
  }

  digitalDataLayer.pushChanges([
    'user.isGeoEnabled',
    isEnabled === true.toString() ? true : false,
    'Source Code',
  ]);
}

export function getPromo(promo: IBadge[] | string): string {
  if (!promo || !promo.length) {
    return undefined;
  }

  // string on item page
  if (typeof promo === 'string') {
    return promo;
  }

  const promoCodesArr = promo.map((item) =>
    typeof item === 'string' ? item : `${item.type}_${item.priority}`
  );
  return promoCodesArr.join('/');
}

export function getTransformItem(i) {
  const result = {
    brand: i.brand,
    category: i.category,
    categoryId: i.categoryId,
    creditMonthlyPrice: i.creditMonthlyPrice,
    currency: i.currency,
    id: i.productCode || i.id,
    imageUrl: i.imageUrl || i.img?.src,
    name: i.name || i.title,
    promo: getPromo(i.promo),
    rating: i.rating,
    reviewCount: i.reviewsQuantity || i.reviewCount || 0,
    stock: i.stock,
    thumbnailUrl: i.thumbnailUrl,
    unitPrice: i.unitPrice || i.unitSalePrice || i.price,
    unitSalePrice: i.unitSalePrice || i.unitPrice || i.price,
    url: i.url || i.shopLink,
    quantity: i.quantity || 1,
    createdTime: transformCreatedTime(i?.createdTime),
    product_type: (i.unit && productUnitType[i.unit.type]) || '',
    isDiscount: !!(i.discount ?? i.percentageDiscount), // Keys named different, need to support both vars
    testDiscountAmount: i.discount || i.percentageDiscount || 0,
    testDiscountType: i.discountType || DiscountOffersType.NONE,
  };

  /** Removing undefined fields from an object */
  Object.keys(result).forEach((key) => {
    if (result[key] === undefined) {
      delete result[key];
    }
  });

  return result;
}

function transformItem() {
  return (i) => getTransformItem(i);
}

export function getProductLabel(item: any, onlyId: boolean = false) {
  if (!item) {
    console.assert(item, 'ddService: product is required for getProductLabel');
    return '';
  }

  if (onlyId) {
    return `productId-${item.id}`;
  }

  // for item page and category level 3 page
  if (!item.category && window.digitalData) {
    item.category =
      window.digitalData.product?.category ||
      window.digitalData.listing?.categoryString ||
      '';
  }

  // for main page (DY)
  if (!item.category) {
    const itemFromRecommendations: any = getCardFromRecommendation(item.id);
    item.category = itemFromRecommendations.category || '';
  }

  const product = getTransformItem(item);
  return `productName-${product.name}/productId-${product.id}/category-${product.category}`;
}

/*
 * @param product: IProductInCart from cartStore
 */
export function getLineItem(product) {
  return {
    product: getTransformItem(product),
    supplier: {
      id: product.merchantId,
      name: product.merchant,
      shippingDate: formatSupplierInfo('DELIVERY_DATE', product),
      reviewCount: product.reviewCount,
    },
    quantity: product.quantity,
    subtotal: product.price,
  };
}

/**
 * @desc get form name for push enevt
 * @example 'name': getFormName() + ' Form Validation Error'
 */
export function getFormName() {
  if (window.BACKEND?.config?.formName) {
    return window.BACKEND.config.formName;
  } else {
    return 'Form name is undefined';
  }
}

const localStorageOfferPosition = 'ks.checkout.offerPosition';
const localStorageBestOfferPrice = 'ks.checkout.bestOfferPrice';
const localStorageCart = 'ks.checkout.cart';
const localStorageDeliveryOptions = 'ks.checkout.deliveryOptions';

export interface ICheckoutData {
  offerPosition?: any;
  bestOfferPrice?: any;
  product?: any;
  cart?: any;
  resolver?: boolean;
  deliveryOptions?: any;
}

function setCheckoutData({
  offerPosition,
  bestOfferPrice,
  product,
  cart,
  deliveryOptions,
}: ICheckoutData) {
  if (offerPosition) {
    sessionStorage.setItem(localStorageOfferPosition, offerPosition);
  }
  if (bestOfferPrice) {
    sessionStorage.setItem(localStorageBestOfferPrice, bestOfferPrice);
  }
  if (cart) {
    sessionStorage.setItem(localStorageCart, JSON.stringify(cart));
  }
  if (deliveryOptions) {
    sessionStorage.setItem(
      localStorageDeliveryOptions,
      JSON.stringify(deliveryOptions)
    );
  }
}

function updateCheckoutData({ variable = 'cart' }) {
  const offerPosition = sessionStorage.getItem(localStorageOfferPosition);
  const bestOfferPrice = sessionStorage.getItem(localStorageBestOfferPrice);
  const DDL = window.digitalData;

  if (DDL && DDL[variable]?.supplier && offerPosition) {
    DDL[variable].supplier.offerPosition = parseInt(offerPosition, 10);
  }

  if (DDL && DDL[variable] && bestOfferPrice) {
    DDL[variable].bestOfferPrice = bestOfferPrice;
  }
}

export function setCartFromUrl(cart) {
  const search = document.location.search;
  if (!search) {
    return;
  }

  const lineItem = {
    product: { id: '' },
    supplier: { id: '' },
  };
  const searchArr = search.substring(1).split('&');
  searchArr.forEach((item) => {
    const [name, value] = item.split('=');
    switch (name) {
      case 'merchantId':
        lineItem.supplier.id = value;
        break;
      case 'product':
        lineItem.product.id = value;
        break;
      default:
        break;
    }
  });
  cart.checkoutMethod = CheckoutMethod.buyNow;
  cart.lineItems = [lineItem];
}

function getCheckoutData() {
  const cart = JSON.parse(sessionStorage.getItem(localStorageCart));
  return cart ? cart : {};
}

function getDeliveryOptions() {
  const deliveryOptions = JSON.parse(
    sessionStorage.getItem(localStorageDeliveryOptions)
  );
  return deliveryOptions ? deliveryOptions : [];
}

export const checkoutData = {
  set: setCheckoutData,
  update: updateCheckoutData,
  getSessionCart: getCheckoutData,
  getSessionDeliveryOptions: getDeliveryOptions,
};

export function getPageInfo(type?: string): object {
  const result = {} as any;
  const getPath = () => {
    const hash = window.location.hash?.replace('#!/', '');
    return window.location.pathname + hash;
  };

  result.path = getPath();

  if (type) {
    result.type = type;
  }

  return result;
}

export enum SupplierInfoType {
  DELIVERY_COST = 'delivery-cost',
  DELIVERY_DATE = 'delivery-date',
  PICKUP_DATE = 'pickup-date',
}

export function formatSupplierInfo(type, schema) {
  switch (type) {
    case SupplierInfoType.DELIVERY_COST:
      const deliveryCost = schema?.deliveryOptions?.find(
        (option) => option.type === DeliveryOptions.delivery
      )?.price;
      return deliveryCost || 'платно';

    case SupplierInfoType.DELIVERY_DATE:
      const deliveryDate = schema?.deliveryOptions?.find(
        (option) => option.type === DeliveryOptions.delivery
      )?.date;
      return deliveryDate ? deliveryDate : null;

    case SupplierInfoType.PICKUP_DATE:
      const pickupDate = schema?.deliveryOptions?.find(
        (option) => option.type === DeliveryOptions.pickup
      )?.date;
      return pickupDate ? pickupDate : null;

    default:
      return null;
  }
}

export function updateProduct(data: any) {
  if (!window.digitalData) {
    //  eslint-disable-next-line
    console.error('digitalData is', window.digitalData);
    return;
  }

  window.digitalData.product = {
    ...window.digitalData.product,
    ...data,
  };
}

/**
 * sellersOfferComponent: when addToCart
 * checkoutData.set({ offerPosition, bestOfferPrice })
 *
 * pickup/loan/checkout/paymentConfirm/
 *  // checkoutData.update({variable: 'cart'})
 *
 * orderConfirm
 *  // checkoutData.update({variable: 'transaction'})
 *
 */
