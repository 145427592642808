import { compareDateTimes } from 'ks-utilities/lib/dateTimeUtils';

import { LocalStorageModel } from '../../StorageModel/LocalStorageModel';

type Item = { rotation?: boolean } & (
  | Record<string, string | boolean>
  | string
);

interface InitAttrs<T extends Item> {
  prefix: string;
  items: T[];
  isRandom?: boolean;
}

export enum RotatableBlockNames {
  MAIN_BANNERS = 'MAIN_BANNERS',
  MAGNUM_BANNERS = 'MAGNUM_BANNERS',
  CATALOG_BANNERS = 'CATALOG_BANNERS',
  GOODS_LIST = 'GOODS_LIST',
}

export class CarouselRotationService<T extends Item = any> {
  private readonly storageModel: LocalStorageModel;
  private readonly isRandom: boolean;

  constructor({ prefix, items, isRandom = false }: InitAttrs<T>) {
    this.storageModel = new LocalStorageModel({
      prefix,
    });
    this.isRandom = isRandom;
    this.checkAndSetItems(items);
    this.checkAndSetExpireTime();
  }

  private get expireTime(): Date {
    return new Date(this.storageModel.getValue('expireTime'));
  }

  private set expireTime(value: Date) {
    this.storageModel.saveValue({
      name: 'expireTime',
      value: value.toString(),
    });
  }

  private get items(): T[] {
    return JSON.parse(this.storageModel.getValue('items')) ?? [];
  }

  private set items(value) {
    this.storageModel.saveValue({
      name: 'items',
      value: JSON.stringify(value),
    });
  }

  private get shiftIndex(): number {
    return Number(this.storageModel.getValue('shiftIndex'));
  }

  private set shiftIndex(value: number) {
    this.storageModel.saveValue({
      name: 'shiftIndex',
      value: value.toString(),
    });
  }

  private getShiftedRotatableItems() {
    const rotatableItems = this.items.filter((item) => item.rotation !== false);

    if (!rotatableItems.length) {
      return [];
    }

    // reset shiftIndex if it is greater than the length of rotatable items
    if (this.shiftIndex === rotatableItems.length) {
      this.shiftIndex = 0;
    }

    // rotate array: [1, 2, 3] => [2, 3, 1];
    for (let i = 0; i < this.shiftIndex; i++) {
      const firstItem = rotatableItems.shift();
      rotatableItems.push(firstItem);
    }

    this.shiftIndex++;

    return rotatableItems;
  }

  private fillWithNonRotatableItems(arr: T[]) {
    this.items.forEach((item, index) => {
      if (item.rotation !== false) {
        return;
      }

      arr[index] = item;
    });
    return arr;
  }

  private fillWithRotatableItems(arr: T[]) {
    const rotatableItems = this.getShiftedRotatableItems();
    let rotatableItemsIndex = 0;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i]) {
        continue;
      }

      arr[i] = rotatableItems[rotatableItemsIndex];

      rotatableItemsIndex++;
    }

    return arr;
  }

  private checkAndSetExpireTime() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const isExpireTimeExceeded =
      compareDateTimes(this.expireTime.toUTCString(), today.toUTCString()) <= 0;

    if (isExpireTimeExceeded) {
      this.expireTime = new Date(today.getTime() + 1000 * 60 * 60 * 24);
      this.shiftIndex = 0;
    }
  }

  private checkAndSetItems(newItems: T[]) {
    const itemsInStorageStr = JSON.stringify(this.items);
    const newItemsStr = JSON.stringify(newItems);

    if (newItemsStr !== itemsInStorageStr) {
      this.storageModel.clear();
      this.shiftIndex = 0;
      this.items = newItems;
    }
  }

  rotateItems() {
    const rotatedItems: T[] = Array.from(new Array(this.items.length));

    this.fillWithNonRotatableItems(rotatedItems);
    this.fillWithRotatableItems(rotatedItems);

    return rotatedItems;
  }

  /**
   * Fisher-Yates Shuffle Algorithim
   * https://www.tutorialspoint.com/what-is-fisher-yates-shuffle-in-javascript
   */
  rotateItemsRandomly() {
    const itemsCopy = this.items.map((item) => item);
    const n = itemsCopy.length;
    for (let i = n - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));

      const temp = itemsCopy[i];
      itemsCopy[i] = itemsCopy[j];
      itemsCopy[j] = temp;
    }

    return itemsCopy;
  }

  getRotatedItems() {
    if (this.isRandom) {
      return this.rotateItemsRandomly();
    }

    return this.rotateItems();
  }
}
