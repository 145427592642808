export enum DeliveryOptions {
  pickup = 'PICKUP',
  delivery = 'DELIVERY',
  postomat = 'POSTOMAT',
  express = 'EXPRESS',
}

export enum OfferDeliveryOptionType {
  TO_DOOR = 'TO_DOOR',
  EXPRESS = 'EXPRESS',
  POSTOMAT = 'POSTOMAT',
  PICKUP = 'PICKUP',
}