import { DeliveryStepTypes } from '../../../pages/checkout/const/DeliveryStepTypes';
import { getAvailableDeliveryMethod } from './getAvailableDeliveryMethod';
import { getCartParams } from '../getCartParams';

export const getPostomatDeliveryPrice = (
  deliveryOptions: IDeliveryOption[],
  cartId: string
) => {
  const postomatDeliveryOption = deliveryOptions
    .filter((o) => o.typeCase === DeliveryStepTypes.postomat)
    .find((o) => o.cartId === cartId);

  return { delivery_price: postomatDeliveryOption.data.price };
};

export const getCheckoutPostomatParams = (data: any) => {
  const cartParams = getCartParams(data.cart);
  const availableDeliveryMethod = getAvailableDeliveryMethod(
    data.deliveryOptions
  );
  const deliveryPrice = getPostomatDeliveryPrice(
    data.deliveryOptions,
    data.cartId
  );

  const params = {
    ...cartParams,
    ...availableDeliveryMethod,
    ...deliveryPrice,
    path: document.location.pathname,
    nearby_button_clicked: data.isNearby,
    suborder_count: data.suborderCount,
    saved_address_count: data.savedPointsCount,
  };

  return params;
};
