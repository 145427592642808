import { Api, OfferOptions } from '../../../api';
import { OffersSortingType } from '../../const/OffersFilters';
import { getInstallIdForPCM } from '../../helpers/getInstallId';
import { getCurrentCityId } from '../../services/locationService';
import { AvailableZonesApiProvider } from '../AvailableZonesApiProvider';

export class OffersApiProvider {
  readonly installId = getInstallIdForPCM();
  readonly userId = window.digitalData?.user?.userId;
  api: Api;
  m: any;
  cityId: string = getCurrentCityId() || '750000000';
  availableZoneProvider: AvailableZonesApiProvider;

  constructor(m: any) {
    this.m = m;
    this.api = new Api(m);

    this.availableZoneProvider = new AvailableZonesApiProvider(m);
  }

  // Api requests
  async getOffersByMerchant(
    products: Array<{
      productCode: string;
      merchantId: string;
      outOfStock?: boolean;
    }>
  ) {
    const entries = products.map((product) => ({
      sku: product.productCode,
      merchantId: product.merchantId,
    }));

    const zoneIds = await this.availableZoneProvider.getAvailableZones();

    const payload: IGetOffersByMerchantPayload = {
      options: [OfferOptions.PRICE],
      cityId: this.cityId,
      entries,
    };

    if (zoneIds?.length) {
      payload.zoneId = zoneIds;
    }

    if (this.installId) {
      payload.installationId = this.installId;
    }

    if (this.userId) {
      payload.userId = this.userId;
    }

    const response: IOfferGateway[] = await this.api.getOffersByMerchant(
      payload
    );

    products.forEach((product) => {
      const result: IOfferGateway = response?.find(
        (offer) =>
          offer.masterSku === product.productCode &&
          offer.merchantId === product.merchantId
      );
      product.outOfStock = !result;
    });

    return { products, offers: response };
  }

  async getOffersByMaster({
    id,
    merchantUID,
    limit = 1,
    page = 0,
    product,
    deliveryFilter = null,
    offset,
    sortOption = OffersSortingType.PRICE,
    highRating = false,
    searchText,
  }: IGetOffersByMaster): Promise<IResponseOfferGateway> {
    try {
      const zoneIds = await this.availableZoneProvider.getAvailableZones();
      const params = { id };
      const body: IGetOffersByMasterPayload = {
        cityId: this.cityId,
        id,
        merchantUID,
        limit,
        page,
        product,
        sortOption,
        highRating,
        searchText,
      };

      if (zoneIds?.length) {
        body.zoneId = zoneIds;
      }

      if (offset) {
        body.offset = offset;
      }
      if (deliveryFilter) {
        body.deliveryFilter = deliveryFilter;
      }

      if (this.installId) {
        body.installationId = this.installId;
      }

      if (this.userId) {
        body.userId = this.userId;
      }

      return await this.api.getOffersByMaster(params, body);
    } catch (error) {
      // tslint:disable-next-line
      console.error(error);
      return null;
    }
  }

  async getItemCard(productCode: string) {
    try {
      const zoneIds = await this.availableZoneProvider.getAvailableZones();

      const payload: IGoodsParams = {
        productsSkus: [productCode],
      };

      if (zoneIds?.length) {
        payload.zid = zoneIds.join(',');
      }

      return await this.api.getOrdersProduct(payload);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
