import { getStartedFrom } from './getStartedFrom';

export function getMagnumPcmBannerData({
  banner,
  block,
}): Record<string, TSimpleTypes> {
  return {
    block_id: block.code,
    block_type: block.type,
    block_position: 1,
    listitems_total_count: block.list.length,
    listitems_position: banner.position,
    listitems_product_id: banner.id,
    ...getStartedFrom(),
  };
}
