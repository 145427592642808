export function getProductPromo(promo: IBadge[]) {
  if (!promo?.length) {
    return {};
  }

  const promoCodesArr = promo.map((item: IBadge) => `${item.type}_${item.priority}`);

  return {
    promo: [promoCodesArr.join('/')],
  };
}
