interface IAnalyticTransactionProductInfo {
  product_id?: string;
  product_name?: string;
  brand?: string;
  quantity?: number;
  product_unit_price?: number;
  product_subtotal?: number;
  product_type?: string;
  review_count?: number;
}

export function getTransactionProductInfo(
  product: ITransactionProduct
): IAnalyticTransactionProductInfo {
  if (!product) {
    return {};
  }

  const price = product.unitPrice || product.unitSalePrice;

  const data = {
    product_id: product.id,
    product_name: product.name,
    brand: product.brand,
    quantity: product.quantity,
    product_subtotal: price * product.quantity,
    promo: product.promo,
    supplier_price: price,
    product_type: product.product_type,
    review_count: product.reviewCount,
    is_discount: product.isDiscount,
    test_discount_amount: product.testDiscountAmount,
    test_discount_type: product.testDiscountType,
    category_id: product.categoryId,
    // rating // ! no info
    // has_configurator // ! no info
    // created_time // ! no info
  };

  return data;
}
