/**
 * @description toVw() helps convert from px to vw
 */

export const defaultViewport = 375;

function calcToVw(px: number, vp?: number): number {
  const viewport: number = vp || defaultViewport;
  let numb: number = 0;

  if (px) {
    numb = Number((px / viewport * 100).toFixed(3));
  }

  return numb;
}

export function convertToDefaultViewport(height: number): number {
  const clientWidth = document.documentElement.clientWidth;
  return Math.round(height * defaultViewport / clientWidth);
}

export function toVw(px: number, vp?: number): string {
  const numb = calcToVw(px, vp);
  return `${numb}vw`;
}

export function toVwNumber(px: number): number {
  const clientWidth = document.documentElement.clientWidth;
  const numb = Number(((px * clientWidth) / defaultViewport).toFixed(3));
  return numb;
}
