/**
 * osService
 * @module osService
 */

export const ios = 'device-ios';
export const android = 'device-android';
export const windowsMobile = 'device-windows-phone';

export function isMobile() {
  return navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i);
}

/**
 * @description Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return windowsMobile;
  }

  if (/android/i.test(userAgent)) {
    return android;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return ios;
  }

  return 'unknown';
}

export function isIOs() {
  return getMobileOperatingSystem() === ios;
}

export function isAndroid() {
  return getMobileOperatingSystem() === android;
}
