import cookies from 'ks-utilities/lib/cookies';

import { SentryScopes } from '../../../../sentry';
import { categoriesBannedForChildren } from './categoriesBannedForChildren';
import { CitiesWithMap } from './CitiesWithMap';
import { CityCodes } from './CityCodes';
import { defaultDiscountsCategories } from './DefaultDiscountsCategories';

interface IInitiateConstants {
  minPriceForFreeDelivery?: number;
  minPriceToShowInstallments?: number;
  maxQuantityAddToCart?: number;
  excludeCategoriesForShowInstallments: string[];
  deliveryAddressPickOnMap?: boolean;
  deliveryAddressAddCoords?: boolean;
  deliveryAddressPickOnMapCityIds?: string[];
  cartLink: string;
  isPcmCrossSellTurnedOn: boolean;
  creditPercent: number;
  commissionPercent: number;
  pcmBackdropAllowed: boolean;
  staticMainPageBanner: object;
  pcmTeasersObserverMargin: string;
  showStaticPcmBlocks: boolean;
  menuNavigationEndpoint?: string;
  desktopMainCategoryCode?: string;
  desktopMenuCode?: string;
  desktopTopbarCode?: string;
  mobileMainCategoryCode?: string;
  mobileCatalogCode?: string;
  desktopFooterCode?: string;
  mobileFooterCode?: string;
  minPriceForAllLoans: number;
  extendedProductPage: boolean;
  loanPeriod: any[];
  isFastBuyNow?: boolean;
  autoloadBottomValue: number;
  includeBdConfigCategories: string[];
  layoutStyleTypeExceptions: string[];
  mutateTitleCardCategories: string[];
  chatRedirect: any;
  chatOrderTemplatePrefix: string;
  showTryout: boolean;
  showSellersOffers: boolean;
  plFiltersThrottleDelay: number;
  redesignFashionCategories: boolean;
  searchCompletionsTestName: boolean;
  searchAutocompleteThrottleDelay: number;
  citiesWithAvailableZones: string[];
  customZoneAvailable: boolean;
  isKzLocale: boolean;
  showDeliveryDurationDateInTeaser: boolean;
  sentryScopes: SentryScopes[];
  sizeBackDropAcceptedCategories: string[];
  configuratorBackdropRestrictedCategories: string[];
  listingAdsConfig: IAdsServiceConfig;
  enablePostomatNeighborOptions: boolean;
  postomatSuggestionUpdateTime: string;
  yandexGeoSuggestPublicConfigs: {
    url: string;
    debounceDelay: number;
  };
  yandexGeocodeUrl: string;
  minShowMagnumReceiptTime: number;
  searchableCategories: string[];
  queueUsers: {
    percentage: number;
    queueMinutes: number;
    allowMinutes: number;
  };
  queueUsersCredit: {
    percentage: number;
    queueMinutes: number;
    allowMinutes: number;
  };
  kaspiQrLink: string;
  desktopZhumaBanner: {
    isOn: boolean;
    isZhuma: boolean;
  };
  customPreApprovedTitle: {
    isOn: boolean;
    text: string;
  };
  deliveryOptionOrder: string;
  translationsFromApiConfig: {
    enabled: boolean;
    interval: number;
  };
  kaspiPayTimeout: number; // minutes
  merchantAcceptanceTimeout: number; // hours
  onlineSignatureLink: string;
  showMagnumForgotItemsInCart: boolean;
  useBffOrders: boolean;
  nightSlots: {
    slotFrom: string;
    slotTo: string;
  };
  magnumShouldNoticeSlots: Array<{ from: string; to: string }>;
  paymentReceipts: {
    minVersion: string;
    minOrderCreationTime: string | null;
    endpoint: string;
  };
  pharmSplitCartConfig: {
    category: string;
  };
  debugProductList: boolean;
  shouldDivideIntoDeliveryThresholdGroups: boolean;
  adultCategories: string[];
  newCheckoutEnabled: boolean;
  readonlyFieldRecipient: boolean;
  fastExpressHours: number;
  useBffRefunds: boolean;
  divideCartByMerchantThreshold: boolean;
  filterSubcategoryLineData: {
    subcategory: string[];
    exceptionSubcategory: string[];
    idSubcategoryName: string[];
  };
  showAllDiscounts: boolean;
  showPromoDiscounts: boolean;
  disableKzLocale: boolean;
  discountsCategories: string[];
  hideMagnumProducts: {
    enabled: boolean;
    firstZoneCode: string;
    fakeZoneCode: string;
  };
  includeConvenientBackdropCategories: {
    category: string[];
    exceptionSubcategory: string[];
  };
  adultsAdvancedCategories: string[];
  standardCancellationReason: string[];
  receiptHiddenMerchants: string[];
  informativeReviewsSort: boolean;
  showAllCategoriesAsGrid: boolean;
  enableCityCodeInUrl: boolean;
  repeatOrderV2: boolean;
  showRichContent: boolean;
  shoesSizeHint: {
    enabled: boolean;
    brands: string[];
    characteristicAttributes: string[];
  };
  useOpayPayment: {
    enabled: boolean;
    url: string;
  };
  loanFormTimeout: number;
  isShareCartEnabled: boolean;
  addCategoryPcm: boolean;
  certificatesPreviewUrl: string;
  certificatesBackgroundBaseUrl: string;
  useNativeWebStorage: boolean;
  initProductCover: {
    enabled: boolean;
    coverUrl: string;
    existUrl: string;
  };
}

class Constants {
  isMobileApp: boolean;
  initKZLocale: boolean;
  private initiateConstants: IInitiateConstants = null;
  private readonly localeCookieName = 'locale';

  // initiateConstants is needed to change constants through ab test
  constructor(initiateConstants?: IInitiateConstants) {
    if (initiateConstants) {
      this.initiateConstants = initiateConstants;
    }

    this.initKZLocale = cookies.get(this.localeCookieName) === 'kk-KZ';
    if (this.initiateConstants?.disableKzLocale) {
      this.initKZLocale = false;
    }
    this.isMobileApp = !!cookies.get('is_mobile_app');
  }
  get maxQuantityAddToCart() {
    const maxQuantity = 10;
    const configureSet = this.initiateConstants?.maxQuantityAddToCart;
    return configureSet ?? maxQuantity;
  }
  get minPriceToShowInstallments() {
    const preset = 8_000;
    const configureSet = this.initiateConstants?.minPriceToShowInstallments;
    return configureSet ?? preset;
  }
  get minPriceForFreeDelivery() {
    const preset = 5_000;
    const configureSet = this.initiateConstants?.minPriceForFreeDelivery;
    return configureSet ?? preset;
  }
  get excludeCategoriesForShowInstallments() {
    const preset = ['Продукты питания'];
    const configureSet =
      this.initiateConstants?.excludeCategoriesForShowInstallments;
    return configureSet ?? preset;
  }
  get deliveryAddressPickOnMap() {
    const preset = true;
    const configureSet = this.initiateConstants?.deliveryAddressPickOnMap;
    return configureSet ?? preset;
  }
  get deliveryAddressAddCoords() {
    const preset = true;
    const configureSet = this.initiateConstants?.deliveryAddressAddCoords;
    return configureSet ?? preset;
  }
  get deliveryAddressPickOnMapCityIds() {
    const preset = CitiesWithMap;
    const configureSet =
      this.initiateConstants?.deliveryAddressPickOnMapCityIds;
    return configureSet ?? preset;
  }
  get cartLink() {
    const preset = `${window.BACKEND.config.contextPath}/cart/`;
    const configureSet = this.initiateConstants?.cartLink;
    return configureSet ?? preset;
  }
  get isPcmCrossSellTurnedOn() {
    const preset = true;
    const configureSet = this.initiateConstants?.isPcmCrossSellTurnedOn;
    return configureSet ?? preset;
  }
  get pcmBackdropAllowed() {
    const preset = false;
    const configureSet = this.initiateConstants?.pcmBackdropAllowed;
    return configureSet ?? preset;
  }
  get staticMainPageBanner() {
    const preset = null;
    const configureSet = this.initiateConstants?.staticMainPageBanner;
    return configureSet ?? preset;
  }
  get pcmTeasersObserverMargin() {
    const preset = '50% 0% 15% 0%';
    const configureSet = this.initiateConstants?.pcmTeasersObserverMargin;
    return configureSet || preset;
  }
  get showStaticPcmBlocks() {
    const preset = false;
    const configureSet = this.initiateConstants?.showStaticPcmBlocks;
    return configureSet ?? preset;
  }
  get creditPercent(): number {
    const preset = 24.99;
    const configureSet = this.initiateConstants?.creditPercent;
    return configureSet ?? preset;
  }
  get commissionPercent(): number {
    const preset = 0.99;
    const configureSet = this.initiateConstants?.commissionPercent;
    return configureSet ?? preset;
  }
  get minPriceForAllLoans() {
    const preset = 1000;
    const configureSet = this.initiateConstants?.minPriceForAllLoans;
    return configureSet ?? preset;
  }
  get loanPeriod(): Array<{
    month: 3 | 6 | 12 | 24;
    fromSum: number;
  }> {
    const preset = [
      {
        month: 3,
        fromSum: 0,
      },
      {
        month: 6,
        fromSum: 15000,
      },
      {
        month: 12,
        fromSum: 25000,
      },
      {
        month: 24,
        fromSum: 50000,
      },
    ];
    const configureSet = this.initiateConstants?.loanPeriod;
    return configureSet ?? preset;
  }
  get standardCancellationReasons(): string[] {
    return [
      'CHANGED_MIND',
      'LONG_DELIVERY_DATES',
      'BAD_QUALITY',
      'OUT_OF_STOCK',
      'MERCHANT_ASKED_TO_CANCEL',
      'MERCHANT_OVERESTIMATED_THE_PRICE',
      'OTHER',
    ];
  }

  get isFastBuyNow() {
    const preset = false;
    const configureSet = this.initiateConstants?.isFastBuyNow;
    return configureSet ?? preset;
  }

  get isMobApp() {
    return this.isMobileApp;
  }

  get autoloadBottomValue() {
    const preset = null;
    const configureSet = this.initiateConstants?.autoloadBottomValue;
    return configureSet ?? preset;
  }

  get mobileCatalogCode() {
    const preset = 'catalog-categories';
    const configureSet = this.initiateConstants?.mobileCatalogCode;
    return configureSet ?? preset;
  }

  get desktopMainCategoryCode() {
    const preset = 'desktop-categories';
    const configureSet = this.initiateConstants?.desktopMainCategoryCode;
    return configureSet ?? preset;
  }

  get desktopMenuCode() {
    const preset = 'desktop-menu';
    const configureSet = this.initiateConstants?.desktopMenuCode;
    return configureSet ?? preset;
  }

  get mobileMainCategoryCode() {
    const preset = 'catalog-categories';
    const configureSet = this.initiateConstants?.mobileMainCategoryCode;
    return configureSet ?? preset;
  }

  get desktopTopbarCode() {
    const preset = 'desktop-topbar';
    const configureSet = this.initiateConstants?.desktopTopbarCode;
    return configureSet ?? preset;
  }

  get desktopFooterCode() {
    const preset = 'desktop-footer';
    const configureSet = this.initiateConstants?.desktopFooterCode;
    return configureSet ?? preset;
  }

  get mobileFooterCode() {
    const preset = 'mobile-footer';
    const configureSet = this.initiateConstants?.mobileFooterCode;
    return configureSet ?? preset;
  }

  get layoutStyleTypeExceptions() {
    const preset = [':category:Shoes', ':category:Fashion'];
    const configureSet = this.initiateConstants?.layoutStyleTypeExceptions;
    return configureSet ?? preset;
  }

  // categories that includes to show backdrop configurator
  get includeBdConfigCategories() {
    const preset = ['Shoes', 'Fashion'];
    const configureSet = this.initiateConstants?.includeBdConfigCategories;
    return configureSet ?? preset;
  }

  get mutateTitleCardCategories() {
    const preset = [];
    const configureSet = this.initiateConstants?.mutateTitleCardCategories;
    return configureSet ?? preset;
  }

  get showTryout() {
    const preset = false;
    const configureSet = this.initiateConstants?.showTryout;
    return configureSet ?? preset;
  }

  get showSellersOffers() {
    const preset = false;
    const configureSet = this.initiateConstants?.showSellersOffers;
    return configureSet ?? preset;
  }

  get chatRedirect() {
    const preset = {
      redirectUrl: 'https://kaspi.kz/chat?threadId=chatGuide',
      showUpdateAppBackdrop: true,
      supportedVersion: '5.4.1',
    };
    const configureSet = this.initiateConstants?.chatRedirect;
    return configureSet ?? preset;
  }

  get chatOrderTemplatePrefix() {
    const preset = '';
    const configureSet = this.initiateConstants?.chatOrderTemplatePrefix;
    return configureSet ?? preset;
  }

  get plFiltersThrottleDelay() {
    const preset = 500;
    const configureSet = this.initiateConstants?.plFiltersThrottleDelay;
    return configureSet ?? preset;
  }

  get redesignFashionCategories() {
    const preset = false;
    const configureSet = this.initiateConstants?.redesignFashionCategories;
    return configureSet ?? preset;
  }

  get searchCompletionsTestName() {
    const preset = null; // 'gray' | 'blue'
    const configureSet = this.initiateConstants?.searchCompletionsTestName;
    return configureSet ?? preset;
  }

  get searchAutocompleteThrottleDelay() {
    const preset = 300;
    const configureSet =
      this.initiateConstants?.searchAutocompleteThrottleDelay;
    return configureSet ?? preset;
  }

  get citiesWithAvailableZones() {
    const preset = [
      CityCodes.ALMATY,
      CityCodes.ASTANA,
      CityCodes.KASKELEN,
      CityCodes.SHYMKENT,
      CityCodes.BESAGASH,
    ];
    const configureSet = this.initiateConstants?.citiesWithAvailableZones;
    return configureSet || preset;
  }

  get customZoneAvailable() {
    const preset = true;
    const configureSet = this.initiateConstants?.customZoneAvailable;
    return configureSet || preset;
  }

  get isKzLocale() {
    const preset = this.initKZLocale;
    const configureSet = this.initiateConstants?.isKzLocale;
    return configureSet || preset;
  }

  get showDeliveryDurationDateInTeaser() {
    const preset = false;
    const configureSet =
      this.initiateConstants?.showDeliveryDurationDateInTeaser;
    return configureSet || preset;
  }

  get sentryScopes() {
    const preset: SentryScopes[] = [];
    const configureSet = this.initiateConstants?.sentryScopes;
    return configureSet || preset;
  }

  get sizeBackDropAcceptedCategories() {
    const preset = ['Shoes', 'Fashion'];
    const configureSet = this.initiateConstants?.sizeBackDropAcceptedCategories;
    return configureSet || preset;
  }

  get configuratorBackdropRestrictedCategories() {
    const preset = ['Shoes', 'Fashion'];
    const configureSet =
      this.initiateConstants?.configuratorBackdropRestrictedCategories;
    return configureSet || preset;
  }

  get listingAdsConfig() {
    const preset: IAdsServiceConfig = {
      startMountAd: 0,
      rangeMountAd: 4,
      countPrint: 2,
    };
    const configureSet = this.initiateConstants?.listingAdsConfig;
    return configureSet || preset;
  }

  get enablePostomatNeighborOptions() {
    const preset = true;
    const configureSet = this.initiateConstants?.enablePostomatNeighborOptions;
    return configureSet ?? preset;
  }

  get postomatSuggestionUpdateTime() {
    const preset = 'default';
    const configureSet = this.initiateConstants?.postomatSuggestionUpdateTime;
    return configureSet ?? preset;
  }

  get yandexGeoSuggestPublicConfigs() {
    const preset = {
      url: 'https://kaspi.kz/shop-ext/v1/suggest',
      debounceDelay: 300,
    };

    const configureSet = this.initiateConstants?.yandexGeoSuggestPublicConfigs;
    return configureSet || preset;
  }

  get yandexGeocodeUrl() {
    const preset = 'https://kaspi.kz/shop-ext/v1/geocode/1.x/';
    const configureSet = this.initiateConstants?.yandexGeocodeUrl;
    return configureSet || preset;
  }

  get minShowMagnumReceiptTime() {
    const preset = 1689012000000; // 11 July 2023, 00:00
    const configureSet = this.initiateConstants?.minShowMagnumReceiptTime;
    return configureSet ?? preset;
  }

  get searchableCategories() {
    const preset = ['Medications'];
    const configureSet = this.initiateConstants?.searchableCategories;
    return configureSet || preset;
  }

  get queueUsers() {
    const preset = {
      percentage: 0,
      queueMinutes: 5,
      allowMinutes: 10,
    };
    const configureSet = this.initiateConstants?.queueUsers || {};
    return { ...preset, ...configureSet };
  }

  get queueUsersCredit() {
    const preset = {
      percentage: 0,
      queueMinutes: 5,
      allowMinutes: 10,
    };
    const configureSet = this.initiateConstants?.queueUsersCredit || {};
    return { ...preset, ...configureSet };
  }

  get kaspiQrLink() {
    const preset = 'https://kaspi.kz/qr';
    const configureSet = this.initiateConstants?.kaspiQrLink;
    return configureSet ?? preset;
  }

  get desktopZhumaBanner() {
    const preset: IInitiateConstants['desktopZhumaBanner'] = {
      isOn: false,
      isZhuma: false,
    };
    const configureSet = this.initiateConstants?.desktopZhumaBanner;
    return configureSet ?? preset;
  }

  get customPreApprovedTitle() {
    const preset: IInitiateConstants['customPreApprovedTitle'] = {
      isOn: false,
      text: 'В течение <b>30 минут</b> вам придет сообщение с решением. В случае одобрения, заказ появится в разделе «Мои заказы»',
    };
    const configureSet = this.initiateConstants?.customPreApprovedTitle || {};
    return { ...preset, ...configureSet };
  }

  get deliveryOptionOrder() {
    const preset = null;
    const configureSet = this.initiateConstants?.deliveryOptionOrder;
    return configureSet || preset;
  }

  get translationsFromApiConfig() {
    const preset = {
      enabled: false,
      interval: 5 * 60 * 1000,
    };
    const configureSet = this.initiateConstants?.translationsFromApiConfig;
    return configureSet || preset;
  }

  get kaspiPayTimeout() {
    const preset = 10;
    const configureSet = this.initiateConstants?.kaspiPayTimeout;
    return configureSet ?? preset;
  }

  get merchantAcceptanceTimeout() {
    const preset = 2;
    const configureSet = this.initiateConstants?.merchantAcceptanceTimeout;
    return configureSet ?? preset;
  }

  get onlineSignatureLink() {
    const preset = 'https://kaspi.kz/bank/esignature/process';
    const configureSet = this.initiateConstants?.onlineSignatureLink;
    return configureSet ?? preset;
  }

  get showMagnumForgotItemsInCart() {
    const preset = false;
    const configureSet = this.initiateConstants?.showMagnumForgotItemsInCart;
    return configureSet || preset;
  }

  get useBffOrders() {
    const preset = false;
    const configureSet = { ...(this.initiateConstants || {}) }.useBffOrders;
    return configureSet || preset;
  }

  get paymentReceipts() {
    const preset = {
      minOrderCreationTime: '2023-12-31T18:00:00.000Z',
      endpoint: 'https://receipt.kaspi.kz/web',
    };
    const configureSet = this.initiateConstants?.paymentReceipts;
    return configureSet || preset;
  }

  get pharmSplitCartConfig() {
    const preset = {
      category: ':category:Medications',
    };
    const configureSet = this.initiateConstants?.pharmSplitCartConfig;
    return configureSet ?? preset;
  }

  get debugProductList() {
    const preset = false;
    const configureSet = this.initiateConstants?.debugProductList;
    return configureSet ?? preset;
  }

  get nightSlots() {
    const preset = {
      slotFrom: '21:00',
      slotTo: '06:00',
    };

    const configureSet = this.initiateConstants?.nightSlots;
    return configureSet ?? preset;
  }

  get magnumShouldNoticeSlots() {
    const preset = [
      {
        from: '07:00',
        to: '09:00',
      },
      {
        from: '21:00',
        to: '23:45',
      },
    ];
    const configureSet = this.initiateConstants?.magnumShouldNoticeSlots;
    return configureSet ?? preset;
  }

  get adultCategories() {
    const preset = ['tobacco', 'cigarettes', 'alcohol', 'women lingerie sets'];
    const configureSet = this.initiateConstants?.adultCategories;
    return configureSet || preset;
  }

  get newCheckoutEnabled() {
    const preset = false;
    const configureSet = this.initiateConstants?.newCheckoutEnabled;
    return configureSet || preset;
  }

  get readonlyFieldRecipient() {
    const preset = true;
    const configureSet = this.initiateConstants?.readonlyFieldRecipient;
    return configureSet ?? preset;
  }

  get fastExpressHours() {
    const preset = 3;
    const configureSet = this.initiateConstants?.fastExpressHours;
    return configureSet ?? preset;
  }

  get divideCartByMerchantThreshold() {
    const preset = false;
    const configureSet = this.initiateConstants?.divideCartByMerchantThreshold;
    return configureSet ?? preset;
  }

  get filterSubcategoryLineData() {
    const preset = {
      subcategory: [],
      exceptionSubcategory: [],
      idSubcategoryName: [],
    };
    const configureSet = this.initiateConstants.filterSubcategoryLineData;

    return configureSet ?? preset;
  }

  get showAllDiscounts() {
    const preset = false;
    const configureSet = this.initiateConstants?.showAllDiscounts;

    return configureSet ?? preset;
  }

  get showPromoDiscounts() {
    const preset = false;
    const configureSet = this.initiateConstants?.showPromoDiscounts;

    return configureSet ?? preset;
  }

  get discountsCategories() {
    const preset = defaultDiscountsCategories;
    const configureSet = this.initiateConstants?.discountsCategories;

    return configureSet ?? preset;
  }

  get hideMagnumProducts() {
    const preset = {
      enabled: false,
      firstZoneCode: 'Magnum_ZONE1',
      fakeZoneCode: 'magnum_f_zone',
    };
    const configureSet = this.initiateConstants?.hideMagnumProducts;

    return configureSet ?? preset;
  }

  get includeConvenientBackdropCategories() {
    const preset = {
      category: [],
      exceptionSubcategory: [],
    };
    const configureSet =
      this.initiateConstants?.includeConvenientBackdropCategories;

    return configureSet ?? preset;
  }

  get adultsAdvancedCategories() {
    const preset = categoriesBannedForChildren;
    const configureSet = this.initiateConstants?.adultCategories;
    return configureSet || preset;
  }

  get receiptHiddenMerchants() {
    const preset = ['11761009'];
    const configureSet = this.initiateConstants?.receiptHiddenMerchants;
    return configureSet || preset;
  }

  get informativeReviewsSort() {
    const preset = false;
    const configureSet = this.initiateConstants?.informativeReviewsSort;
    return configureSet ?? preset;
  }

  get showAllCategoriesAsGrid() {
    const preset = true;
    const configureSet = this.initiateConstants?.showAllCategoriesAsGrid;
    return configureSet ?? preset;
  }

  get enableCityCodeInUrl() {
    const preset = false;
    const configureSet = this.initiateConstants?.enableCityCodeInUrl;
    return configureSet ?? preset;
  }

  get repeatOrderV2() {
    const preset = false;
    const configureSet = this.initiateConstants?.repeatOrderV2;
    return configureSet ?? preset;
  }
  get showRichContent() {
    const preset = false;
    const configureSet = this.initiateConstants?.showRichContent;
    return configureSet ?? preset;
  }

  get shoesSizeHint() {
    const preset = { enabled: false, brands: [] };
    const configureSet = this.initiateConstants?.shoesSizeHint;
    return configureSet || preset;
  }

  get useOpayPayment() {
    const preset = {
      enabled: false,
      url: 'https://opay-test.kaspi.kz/cpp/api/v1/start',
    };
    const configureSet = this.initiateConstants?.useOpayPayment;
    return configureSet || preset;
  }

  get loanFormTimeoutSeconds() {
    const preset = 30; // timeout in seconds
    const configureSet = this.initiateConstants?.loanFormTimeout;
    return configureSet ?? preset;
  }

  get isShareCartEnabled() {
    const preset = true;
    const configureSet = this.initiateConstants?.isShareCartEnabled;
    return configureSet ?? preset;
  }

  get addCategoryPcm() {
    const preset = false;
    const configureSet = this.initiateConstants?.addCategoryPcm;
    return configureSet ?? preset;
  }

  get certificatesPreviewUrl() {
    const preset = 'https://mybank.kaspi.kz/bank/giftcards/certificate';
    const configureSet = this.initiateConstants?.certificatesPreviewUrl;
    return configureSet || preset;
  }

  get certificatesBackgroundBaseUrl() {
    const preset = 'https://resources.cdn-kaspi.kz';
    const configureSet = this.initiateConstants?.certificatesBackgroundBaseUrl;
    return configureSet || preset;
  }

  get useNativeWebStorage() {
    const preset = false;
    const configureSet = this.initiateConstants?.useNativeWebStorage;
    return configureSet ?? preset;
  }

  get initProductCover() {
    const preset = {
      enabled: false,
      coverUrl: 'https://resources.cdn-kaspi.kz/img/cnt/mp/v',
      existUrl: 'https://resources.cdn-kaspi.kz/api/exists/cnt/mp/v'
    };
    const configureSet = this.initiateConstants?.initProductCover;
    return configureSet || preset;
  }
}

let initiateConst = null;
if (window.BACKEND.config?.constants) {
  initiateConst = window.BACKEND.config.constants;
}

export const CONSTANTS = new Constants(initiateConst);
