export function getTransactionCartParams(
  transaction: ITransactionEventData
): Record<string, any> {
  if (!transaction) {
    return {};
  }

  const suppliers = transaction.lineItems?.reduce((acc, item) => {
    if (acc.some((i) => i === item.supplier?.name)) {
      return acc;
    }
    acc.push(item.supplier?.name);
    return acc;
  }, []);

  const data = {
    order_id: transaction.orderId,
    checkout_method: transaction.checkoutMethod,
    total_price: transaction.total,
    subtotal_price: transaction.subtotal,
    payment_method: transaction.paymentMethod,
    credit_term: transaction.creditTerm,
    credit_monthly_price: transaction.creditMonthlyPrice,
    bonus_amount: transaction.bonusAmount,
    bonus_spent: transaction.bonusTotal || 0,
    cart_quantity: transaction.cartQuantity,
    cart_size: transaction.cartSize,
    delivery_price: transaction.shippingCost || 0,
    suppliers_count: transaction.cartSuppliersCount,
    suborders_count: transaction.cartSubordersCount,
    suppliers,
    promo_sku_count: transaction.lineItems?.reduce(
      (acc, item) => (item.product?.promo ? acc + 1 : acc),
      0
    ),
  };

  return data;
}
