export const EventEmitter = {
  subscribes: [],
  subscribe(eventName: string | number, fn) {
    this.subscribes.push({
      eventName,
      fn,
    });

    // unsubscribe;
    return () => {
      this.subscribes = this.subscribes.filter((subscriber) => subscriber.fn !== fn);
    };
  },
  emit(eventName: string | number, data: any) {
    this.subscribes.forEach((subscriber) => {
      if (subscriber.eventName === eventName) {
        subscriber.fn(data);
      }
    });
    fireNativeEvent(eventName, data);
  },
};

export const fireNativeEvent = (eventName, data) => {
  const event = new CustomEvent(eventName, {
    bubbles: true,
    detail: data,
  });
  document.dispatchEvent(event);
};
