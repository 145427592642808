export function creditCalc(amount: number, apr: number, month: number, commission?: number): number {
  /* Convert interest from a percentage to a decimal,
    and convert from an annual rate to a monthly rate. */
  const principal = amount;
  const interest = apr / 100 / 12;
  const payments = month;
    
  // compute the monthly payment figure
  const x = Math.pow(1 + interest, payments); // Math.pow computes powers
  let monthly = (principal * x * interest) / (x - 1);

  if (commission) {
    monthly += amount * commission / 100;
  }

  const result = isFinite(monthly) ? Math.ceil(monthly) : null;

  return result;
}

export function installmentCalc(amount: number, month: number): number {
  return  Math.ceil(amount / month);
}
