export function setTimeZone(date: Date | string): string {
  try {
    const timezoneOffset = -300; // UTC+5:00
    const defaultTime = new Date(date);
    const time = new Date(
      defaultTime.setMinutes(defaultTime.getMinutes() - timezoneOffset)
    );
    const dateISO = time.toISOString().substring(0, 16);

    return dateISO;
  } catch (error) {
    console.error(`${date}:`, error);
    return '';
  }
}
