import { BadgeSize, widthMobile, widthDesktop, sortConfigBadges } from '../../const/Badge';
import { isMobile } from '../../services/osService';
import { sortPromoBadges } from './sortPromoBadges';

export function mutatePromoBadges(badges: IResponseBadge[] = []): IBadge[] {
  if (!badges) {
    return null;
  }

  const result = badges.map((badge) => {
    if (!badge) {
      return null;
    }

    const img = {} as IBadgeModel;

    Object.entries(BadgeSize).forEach(([key, value]) => {
      const defaultWidth = isMobile()
        ? widthMobile[value]
        : widthDesktop[value];

      img[value] = {
        src: badge.imageUrl,
        width: badge.width || defaultWidth,
      };
    });

    return {
      priority: badge.priority,
      duration: badge.duration,
      type: badge.type,
      img,
      code: badge.code,
    };
  });

  return sortPromoBadges({ config: sortConfigBadges, badges: result });
}
