import cookies from 'ks-utilities/lib/cookies';
import { getInstallId } from '../../helpers/getInstallId';

export function getSessionId() {
  const sessionId = sessionStorage.getItem('ks.sessionId') ?? setSessionId();

  return sessionId;
}

function setSessionId() {
  const kstatCookie = cookies.get('k_stat');
  const installId = getInstallId();

  let uniqueId: string;
  if (kstatCookie) {
    uniqueId = kstatCookie;
  } else if (installId) {
    uniqueId = installId;
  } else {
    return 'NotAKstat';
  }

  const sessionId = uniqueId + Math.floor(Date.now() / 1000);
  sessionStorage.setItem('ks.sessionId', sessionId);
  return sessionId;
}
