import { Sentry } from '../../../../../sentry';

import { getProductPromo } from './getProductPromo';
import { checkoutData } from './checkoutData';

export function getTransactionFromSessionCart(
  orders = [],
  orderGroupCode?: string
) {
  try {
    const sessionCart = checkoutData.getSessionCart();
    let id = orderGroupCode ?? sessionCart.id;
    delete sessionCart.id;

    let shippingCost = 0;
    const products = [];
    const merchants = [];
    const shippingMethod = [];

    const ordersProducts = [];
    orders.forEach((order) => {
      order.entries.forEach((entry) => {
        ordersProducts.push({
          orderId: order.id,
          merchant: order.merchant,
          productCode: entry.productCode,
          promo: entry.promo,
        });
      });
    });

    if (!id && ordersProducts.length) {
      id = ordersProducts.map((item) => item.orderId).join('-');
    }

    const lineItems = sessionCart.lineItems?.map((item) => {
      let subOrderId = '';
      if (!products.includes(item.product.id)) {
        products.push(item.product.id);
      }
      if (!merchants.includes(item.supplier.id)) {
        merchants.push(item.supplier.id);
        shippingCost += item.shippingCost;
      }
      if (!shippingMethod.includes(item.shippingMethod)) {
        shippingMethod.push(item.shippingMethod);
      }
      if (ordersProducts.length) {
        const product = ordersProducts.find(
          (ordersProduct) =>
            ordersProduct.productCode === item.product.id &&
            ordersProduct.merchant === item.supplier.id
        );
        if (product?.promo) {
          item.product.promo = getProductPromo(product.promo) || '';
        }
        subOrderId = product?.orderId;
      }

      return {
        subOrderId,
        ...item,
      };
    });

    const cartQuantity =
      lineItems?.reduce(
        (sum: number, item: ITransactionLineItem) => sum + item.quantity,
        0
      ) || null;

    const transaction = {
      ...sessionCart,
      orderId: id,
      shippingCost,
      shippingMethod: shippingMethod.join(', '),
      cartQuantity,
      cartSize: products.length || null,
      cartSuppliersCount: merchants.length || null,
      cartSubordersCount: orders.length || null,
      lineItems,
    };

    return transaction;
  } catch (error) {
    console.error('DDService: getTransactionFromSessionCart', error); //  eslint-disable-line
    Sentry?.captureMessage('DDService: getTransactionFromSessionCart', error);
    return {};
  }
}
