import { getCategoryHierarchyProps } from "./getCategoryHierarchyProps";

export function combineOffersFiltersEventProperties(data: IOffersFiltersAnalyticParams) {

  const appliedFilters = [
    data.offersStoreData.highRating ? 'rating' : null,
    data.activeDeliveryFilter ? 'delivery' : null,
  ].filter(Boolean);

  return {
    ...getCategoryHierarchyProps(data.product.category),
    product_id: data.product.id,
    sort_by: data.offersStoreData.sortOption,
    sort_applied: data.sortApplied,
    applied_filter: appliedFilters.length ? appliedFilters : null,
    delivery_filter_value: data.activeDeliveryFilter,
    offers_quantity: data.offersStoreData.offersCount,
    result_count: data.offersStoreData.total,
    product_page: data.isSellersView ? 'shop_product_offers' : 'shop_product',
    search_term: data.searchTerm
  }
}