import { getStartedFrom } from './getStartedFrom';

export function getRefundInOrderParams(data: IParamOrderPage) {
  const path = document.location.href;
  const startedFrom = getStartedFrom();
  const parameters = {
    suborder_id: data.id || data.orderId,
    order_size: data.orderSize,
    current_status: data.status?.name || data.currentStatus,
    order_quantity: data.orderQuantity,
    path,
    ...startedFrom,
  };

  return parameters;
}

export function getProductForRefundParams(data: IProductToRefund) {
  const startedFrom = getStartedFrom();
  const reason = data.withReason
    ? {
        return_reason: data.reason,
        comment_show: data.commentShow,
        photo_show: data.photoShow,
        has_comment: data.hasComment,
        comment_optional: data.commentOptional,
        has_photo: data.hasPhoto,
      }
    : {};

  const parameters = {
    suborder_id: data.orderId,
    order_quantity: data.orderQuantity,
    order_size: data.orderSize,
    product_id: data.product.productSku,
    product_name: data.product.name,
    product_unit_price: data.product.price,
    quantity: data.product.quantity,
    supplier: data.product.merchant,
    supplier_id: data.product.merchantId,
    price: data.product.totalPrice,
    alert_text: data.alertText,
    return_request_day: data.resultDate,
    path: [document.location.pathname + document.location.hash],
    ...reason,
    ...startedFrom,
  };

  return parameters;
}
