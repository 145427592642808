export enum StartedByTypes {
  TopThree = 'Top-3',
  Backdrop = 'Backdrop',
  OffersPage = 'Offers Page',
  productOffers = 'product_offers',
  BackdropWhenRemoved = 'backdrop_when_removed',
  BackdropSimilarProducts = 'backdrop_similar_products',
  SwipedLeft = 'swiped_left',
  PreselectedFilterListing = 'preselected_filter_listing',
  CrossSellBackdrop = 'cross_sell_backdrop',
  SimilarGoods = 'similar_goods',
  AnotherSupplier = 'another_supplier',
}
