'use strict';

/**
 * Cookies helper
 *
 */

// returns cookie with name, returns udnefined if there is no such cookie
function getCookie(name) {
  const matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

// sets cookie with name and value
// options - is object containing expires, path, domain, secure properites
function setCookie(name, value, options) {
  options = options || {};

  let expires = options.expires;

  if (typeof expires === 'number' && expires) {
    const d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }

  value = encodeURIComponent(value);

  let updatedCookie = name + '=' + value;

  for (const propName in options) {
    if (options.hasOwnProperty(propName)) {
      updatedCookie += '; ' + propName;
      const propValue = options[propName];
      if (propValue !== true) {
        updatedCookie += '=' + propValue;
      }
    }
  }

  document.cookie = updatedCookie;
}

// deletes cookie with provided name
function deleteCookie(name: string, options = {}) {
  setCookie(name, '', {
    'max-age': -1,
    ...options,
  });
}

export const Cookies = {
  get: getCookie,
  set: setCookie,
  remove: deleteCookie
};

// ! deprecated
export default {
  get: getCookie,
  set: setCookie,
  remove: deleteCookie
};
