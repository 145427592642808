import { DeliveryOptions } from '../../../const/DeliveryOptions';

enum SupplierInfoType {
  DELIVERY_COST = 'delivery-cost',
  DELIVERY_DATE = 'delivery-date',
  PICKUP_DATE = 'pickup-date',
  DELIVERY_OPTIONS = 'delivery_options',
}

function formatDeliveryInfo(type, schema) {
  switch (type) {
    case SupplierInfoType.DELIVERY_COST:
      const deliveryCost = schema?.find(
        (option) => option.type === DeliveryOptions.delivery
      )?.price;

      return deliveryCost ?? null;

    case SupplierInfoType.DELIVERY_DATE:
      const deliveryDate = schema?.find(
        (option) => option.type === DeliveryOptions.delivery
      )?.date;
      return deliveryDate ?? null;

    case SupplierInfoType.PICKUP_DATE:
      const pickupDate = schema?.find(
        (option) => option.type === DeliveryOptions.pickup
      )?.date;
      return pickupDate ?? null;

    case SupplierInfoType.DELIVERY_OPTIONS:
      const options = schema?.map((option) => option.type);
      return (
        options?.filter((option) => option !== DeliveryOptions.pickup) ?? null
      );
    default:
      return null;
  }
}

export function getBaseDeliveryOptions(deliveryOptions: any[]) {
  if (!deliveryOptions) {
    return;
  }

  const data = {
    delivery_options: formatDeliveryInfo(
      SupplierInfoType.DELIVERY_OPTIONS,
      deliveryOptions
    ),
    delivery_cost: formatDeliveryInfo(
      SupplierInfoType.DELIVERY_COST,
      deliveryOptions
    ),
    delivery_date: formatDeliveryInfo(
      SupplierInfoType.DELIVERY_DATE,
      deliveryOptions
    ),
    pickup_date: formatDeliveryInfo(
      SupplierInfoType.PICKUP_DATE,
      deliveryOptions
    ),
  };

  return data;
}
