import { getStartedFrom } from '../helpers/getStartedFrom';
import { getPageCategory } from '../helpers/getPageCategory';

export function getBaseParams(): any {
  const startedFrom = getStartedFrom();
  const pageCategory = getPageCategory(window.location.pathname);

  const parameters = {
    path: document.location.pathname,
    ...startedFrom,
    page_category: pageCategory,
  };

  return parameters;
}
