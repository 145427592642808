export function getChangedCategoryLevels(products) {
  const categoryLevels = {
    category_level_1_changed: new Set(),
    category_level_2_changed: new Set(),
    category_level_3_changed: new Set(),
  };

  products.forEach((product) => {
    if (!product.category) {
      return;
    }

    product.category.forEach((category, index) => {
      if (category) {
        categoryLevels[
          `category_level_${index + 1}_changed`
        ]?.add(category);
      }
    });
  });

  return Object.keys(categoryLevels).reduce((obj, key) => {
    obj[key] = Array.from(categoryLevels[key]);
    return obj;
  }, {});
}
