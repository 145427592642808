// TODO: remove
export default function mutateCardsTitle(
  cards: IItemCard[] = [],
  mutateTitleCategories: string[]
) {
  cards.forEach((card) => {
    if (!card.categoryCodes) {
      return;
    }

    const useMutate = mutateTitleCategories?.some((category) =>
      card.categoryCodes.includes(category)
    );

    if (useMutate) {
      card.title = card.shortNameText || card.brand;
      card.customCategoryName = card.model;
    }
  });
}

export function addAgeLimitToCard(card: IItemCard) {
  if (
    !window.ksService.AgeLimitService ||
    window.ksService.AgeLimitService.isAllowed
  ) {
    return;
  }

  if (window.ksService.AgeLimitService.isAdultContent(card.categoryCodes)) {
    card.ageLimit = 21;
  }
}
