import { getStartedFrom } from './getStartedFrom';
import { getFromUrl } from './getParamFromSearchQuery';
import { getCategoryHierarchyProps } from './getCategoryHierarchyProps';

export function getRefundInListParams(data: { product: IRefundItemCard }) {
  const startedFrom = getStartedFrom();
  const { product } = data;
  const parameters = {
    request_number: product.applicationNumber,
    current_status: product.currentStep.title,
    ...startedFrom,
    ...getProduct(product),
    source: getFromUrl.source(),
    supplier: product.merchantId,
    supplier_id: product.merchantName,
  };

  return parameters;
}

export function getRefundDetailsParams(data: IRefundDetails) {
  const startedFrom = getStartedFrom();
  const { product, refundData = {}, merchant, slot } = data;
  const stage = refundData.stateSteps?.find((step) => step.stage === 'CURRENT');
  const next = refundData.stateSteps?.find((step) => step.stage === 'ONGOING');
  const parameters = {
    request_number: refundData.code,
    suborder_id: refundData.orderCode,
    current_status: stage?.title,
    next_status: next?.title,
    ...startedFrom,
    product_subtotal: refundData.totalPrice,
    quantity: refundData.quantity,
    has_photo: Boolean(refundData.imageUrls?.length),
    comment_show: Boolean(refundData.reason?.length),
    return_reason: refundData.reason,
    ...getProduct(product),
    supplier: merchant.uid,
    supplier_id: merchant.name,
    date_option: slot,
    button: data.button,
  };

  return parameters;
}

export function getRefundInOrder(data: {
  entry: IOrderEntry;
  orderId: string;
}) {
  const startedFrom = getStartedFrom();
  const { entry, orderId } = data;
  return {
    request_number: entry.refund.activeRequest,
    suborder_id: orderId,
    product_id: entry.productCode,
    product_name: entry.name,
    product_category: entry.category,
    ...getCategoryHierarchyProps(entry.category),
    product_unit_price: entry.price,
    quantity: entry.quantity,
    supplier: entry.merchant,
    supplier_id: entry.merchantId,
    ...startedFrom,
  };
}

function getProduct(product) {
  return {
    product_id: product.id,
    product_name: product.title,
    product_category: product.category,
    ...getCategoryHierarchyProps(product.category),
    product_unit_price: product.unitPrice,
    brand: product.brand,
  };
}
