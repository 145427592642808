export const ALMATY_UTC_OFFSET = 5; // used as default offset since servers are located in Almaty

function getDiffInSeconds(startDate: Date, endDate: Date): number {
  return Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
}

// TODO: enhance functions to accept dateTime with any value of 'Z' (UTC offset)
// currently they only accept YYYY-MM-DDTHH:mm:ss format without offset (as received from server)

/**
 * @description converts dateTime with given UTC offset to local dateTime. *Doesn't work with non-integer offsets
 *
 * https://tc39.es/ecma262/#sec-date-time-string-format
 * @param {string} dateTime dateSting (ISO 8601 format) in date-time form without UTC offset (YYYY-MM-DDTHH:mm:ss or other) (without 'Z', +/-HH:mm)
 * @param {number} [UTCOffset=5] integer offset (in hours) of the input date from UTC (GMT)
 *
 * @returns {Date} Date object representing given dateTime in local timezone
 */
export function getLocalizedDatetime(
  dateTime: string,
  UTCOffset: number = ALMATY_UTC_OFFSET
): Date | null {
  if (!dateTime) {
    return null;
  }

  const absOffset = Math.abs(UTCOffset);
  const sign = UTCOffset < 0 ? '-' : '+';
  const HH = absOffset < 10 ? `0${absOffset}` : `${absOffset}`;
  const Z = `${sign}${HH}:00`;

  return new Date(`${dateTime}${Z}`);
}

/**
 * @param {string} finishDateTime simplified version of ISO 8601 without UTC offset part 'Z', +/-HH:mm
 * @param timestamp number of milliseconds since the epoch or Date object -
 *                  represents a point in time to calculate remaining time from. Defaults to current dateTime.
 * @returns {number} seconds left until given dateTime. Always positive. If finishDateTime is in past, then 0 is returned.
 */
export function getRemainingTimeInSeconds(
  finishDateTime: string,
  timestamp: Date | number | null = null
): number {
  const endDate = getLocalizedDatetime(finishDateTime);

  if (!endDate) {
    return 0;
  }

  const startDate = timestamp ? new Date(timestamp) : new Date();
  const diff = getDiffInSeconds(startDate, endDate);

  return diff > 0 ? diff : 0;
}

/**
 * @description compares two dateTimes down to milliseconds. * Compatible with Array.sort compareFn
 *
 * @param {string} date1 simplified version of ISO 8601 without UTC offset part 'Z', +/-HH:mm
 * @param {string} date2 simplified version of ISO 8601 without UTC offset part 'Z', +/-HH:mm
 * @returns {number} -1 if date1 is earlier than date2, 1 if date1 is later, 0 if they are equal
 */
export function compareDateTimes(date1: string, date2: string): number {
  const date1Time = getLocalizedDatetime(date1)?.getTime?.();
  const date2Time = getLocalizedDatetime(date2)?.getTime?.();

  if (!date1Time || !date2Time) {
    return 0;
  }

  if (date1Time < date2Time) {
    return -1;
  }
  if (date1Time > date2Time) {
    return 1;
  }
  return 0;
}

// TODO: make formatDate configurable to different formats ('DD/MM/YYYY'...)
/**
 *
 * @param date Date object
 * @returns dateTime in 'YYYY-MM-DD' format
 */
export function formatDate(date: Date): string {
  if (!date) {
    return;
  }

  const [year, month, day]: string[] = [
    date.getFullYear().toString(),
    pad((date.getMonth() + 1)),
    pad(date.getDate()),
  ];

  return `${year}-${month}-${day}`;
}

const pad = (num: number) =>
    num.toString().length === 1 ? '0' + num : num.toString();
