import { isObject } from 'ks-utilities/lib/isObject';
import { InterceptorsModel } from './InterceptorsModel';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IResponse<T> {}

export interface IResponseWithStatus<T> {
  xhr: number;
  response: IResponse<T>;
}

type MithrilRequest = any;

type RequestFunction = (_?: IConfig) =>
Promise<IResponse<any> | IResponseWithStatus<any>>;

export class RequestManager {
  interceptors: InterceptorsModel<TInterceptorRequest, TInterceptorResponse>;
  private defaultParams: IConfig = {} as any;
  private _request: RequestFunction;

  constructor(request) {
    this._request = this.wrapOriginalRequest(request);
    this.interceptors = new InterceptorsModel<TInterceptorRequest, TInterceptorResponse>();
  }

  setDefaultParams(params: IConfig) {
    this.defaultParams = params;
  }

  get request(): (params: IConfig) => any {
    return this._request;
  }

  private wrapOriginalRequest(request: MithrilRequest): RequestFunction {
    const wrappedRequest = (params: IConfig) => {
      if (!isObject(params)) {
        throw new Error('RequestManager: request parameter should be object');
      }
      // apply request interceptors
      params = this.interceptors.request._apply(
        params, // params is data for request interceptor
        params,
        null);

      const requestParams = params;
      let xhr = null;

      const useXHRStrategy = wrapXhr(params.withXHR);

      // WARNING RequestManager: config override!
      const originalConfig = requestParams.config;
      requestParams.config = (x) => {
        xhr = x;
        originalConfig && originalConfig(x);
      };
      
      return request({
        ...this.defaultParams,
        ...params})
        .then((response) => {
          // apply response interceptors
          const result = this.interceptors.response._apply(
            response,
            params,
            xhr);
          return useXHRStrategy(result, xhr);
        }).catch((error) => {
          // this part should probably be written differently
          // this.interceptors.response._apply(error, params);
          const result = this.interceptors.response._apply(
            error,
            params,
            xhr
          );

          throw useXHRStrategy(result, xhr);

        });
    };
    return wrappedRequest;
  }
}

const wrapXhr = (withXHR: boolean) => {
  return (response, xhr) =>
    withXHR
      ? {
        response,
        xhr,
      }
      : response;
};

type TInterceptorRequest = (config: IConfig) => IConfig | Promise<Error>;
type TInterceptorResponse = (response: any, _?: any, __?: any) => any | Promise<Error>;

export enum HTTPMethodTypes {
  get     = 'GET', // by default
  post    = 'POST',
  put     = 'PUT',
  patch   = 'PATCH',
  delete  = 'DELETE',
  head    = 'HEAD',
  options = 'OPTIONS',
}

export interface IConfig {
  method?: HTTPMethodTypes;
  url?: string;
  // The data to be interpolated into the URL and/or serialized into the query string.
  params?: object;
  // The data to be serialized into the body (for other types of requests).
  body?: object;
  // Whether the request should be asynchronous. Defaults to true.
  async?: boolean;
  // A username for HTTP authorization. Defaults to undefined.
  user?: string;
  // A password for HTTP authorization. Defaults to undefined. This option is provided for XMLHttpRequest compatibility, but you should avoid using it because it sends the password in plain text over the network.
  password?: string;
  // Whether to send cookies to 3rd party domains. Defaults to false
  withCredentials?: boolean;
  // The amount of milliseconds a request can take before automatically being terminated. Defaults to undefined.
  timeout?: number;
  // The expected type of the response. Defaults to "" if extract is defined, "json" if missing. If responseType: "json", it internally performs JSON.parse(responseText).
  responseType?:	string;
  // Exposes the underlying XMLHttpRequest object for low-level configuration and optional replacement (by returning a new XHR).
  config?:	(xhr) => any;
  // Headers to append to the request before sending it (applied right before options.config).
  headers?: object;
  // A constructor to be applied to each object in the response. Defaults to the identity function.
  type?: () => any;
  // A serialization method to be applied to body. Defaults to JSON.stringify, or if options.body is an instance of FormData, defaults to the identity function (i.e. function(value) {return value}).
  serialize?: (s: string) => any;
  // A deserialization method to be applied to the xhr.response or normalized xhr.responseText. Defaults to the identity function. If extract is defined, deserialize will be skipped.
  deserialize?: () => any;
  // A hook to specify how the XMLHttpRequest response should be read. Useful for processing response data, reading headers and cookies. By default this is a function that returns options.deserialize(parsedResponse), throwing an exception when the server response status code indicates an error or when the response is syntactically invalid. If a custom extract callback is provided, the xhr parameter is the XMLHttpRequest instance used for the request, and options is the object that was passed to the m.request call. Additionally, deserialize will be skipped and the value returned from the extract callback will be left as-is when the promise resolves.
  extract?:	(xhr, options) => any;
  // If false, redraws mounted components upon completion of the request. If true, it does not. Defaults to false.
  background?:	boolean;
  // Custom request
  withXHR?: boolean;
}
