export function getCategoryTags(treeCategory: any) {
  if (!treeCategory.items) {
    return null;
  }

  let categoryTagsStr = '';

  function composeData(items) {
    items?.forEach((item) => {
      if (!item.items?.length) {
        categoryTagsStr += item.title + ':' + item.count + ';';
        return;
      }
      composeData(item.items);
    });
  }

  composeData(treeCategory.items);

  return categoryTagsStr;
}