import { IConfig } from './Request';
import { InterceptorPointModel } from './InterceptorPointModel';

export class InterceptorsModel<T, K> {
  request: InterceptorPointModel<T, IConfig>;
  response: InterceptorPointModel<K, any>;
  constructor() {
    this.request = new InterceptorPointModel<T, IConfig>();
    this.response = new InterceptorPointModel<K, any>();
  }
}
