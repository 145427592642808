import { transformCreatedTime } from './transformCreatedTime';

interface IAnalyticProductInfo {
  product_id?: string;
  product_name?: string;
  brand?: string;
  rating?: string;
  review_count?: string;
  image_count?: string;
  credit_monthly_price?: string;
  number_of_orders?: string;
  price?: string;
  stock?: string;
  created_time?: string;
  discount?: string;
  mabaya_adds_count?: string;
  has_description?: string;
  product_category?: string;
  has_big_picture?: string;
  has_configurator?: string;
}

export function getProductInfo(product: any): IAnalyticProductInfo {
  if (!product) {
    return {};
  }

  const propsOnItemPage: any = {};
  const item = window.BACKEND.components.item;
  if (item) {
    const hasVideo = !!item.videoId;
    const imagesCount = item.galleryImages?.length;

    propsOnItemPage.has_video_review = hasVideo;
    propsOnItemPage.image_count = imagesCount
      ? imagesCount - (hasVideo ? 1 : 0)
      : null;
  }

  const data = {
    product_id: product.productCode || product.id,
    product_name: product.name || product.title,
    brand: product.brand,
    rating: product.rating,
    number_of_orders: product.numberOfOrders,
    stock: product.stock,
    created_time: transformCreatedTime(product.createdTime),
    has_big_picture: product.hasBigPicture,
    product_category: product.category,
    mabaya_adds_count: product.ma_adds_count,
    review_count: product.reviewsQuantity || product.reviewCount,
    image_count: product.previewImages?.length,
    credit_monthly_price: product.creditMonthlyPrice,
    price: product.unitPrice || product.unitSalePrice || product.price,
    has_description: product.hasDescription ?? null,
    has_configurator: product.hasVariants,
    // could use product.hasVideoReview, but it's broken for now
    ...propsOnItemPage,
  };

  return data;
}
