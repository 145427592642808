type PageNames = 'search' | 'catalog';

export function isPrevPageEquals(pageName: PageNames) {
  const pageNameUrlMap: Record<PageNames, string> = {
    catalog: '/c/',
    search: '/search/',
  };

  return document.referrer.includes(pageNameUrlMap[pageName]);
}
