export const extract = (xhr, options) => {
  let message;

  try {
    message = JSON.parse(xhr.responseText);
  } catch (e) {
    message = xhr.responseText;
    throw new Error(JSON.stringify(message));
  }
  const success = (xhr.status >= 200 && xhr.status < 300) || xhr.status === 304;
  if (!success) {
    throw message;
  }

  return message;
};

export const extendExtract = (xhr, options) => {
  try {
    return extract(xhr, options);
  } catch (error) {
    // for empty response with success result
    const success =
      (xhr.status >= 200 && xhr.status < 300) || xhr.status === 304;
    if (!xhr.responseText && success) {
      // return true for process the response from the server in the form : await request()
      return true;
    }
    if (typeof error === 'string') {
      throw error;
    }
    // eslint-disable-next-line no-throw-literal
    throw {
      ...error,
      status: xhr.status,
    };
  }
};
