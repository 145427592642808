declare let ASSET_PATH;
declare let __webpack_public_path__;

// utilites
import 'ks-utilities/polyfills/element.matches.polyfill';
import 'ks-utilities/polyfills/element.closest.polyfill';
import 'ks-utilities/polyfills/object.assign';
import 'ks-utilities/polyfills/array.form.polyfill';
import 'ks-utilities/polyfills/array.find.polyfill';
import 'ks-utilities/polyfills/array.findIndex.polyfill';
import 'ks-utilities/polyfills/touch-polyfill';
import '@babel/polyfill';

import './globalVars';

__webpack_public_path__ = (window as any).BACKEND.config.kaspiCDN || ASSET_PATH;

import 'ks-store/common/analytic';
import {
  experimentUtilities,
  nameUtilities,
  debugUtilities,
} from 'ks-store/common/experimentUtilities';

experimentUtilities.snippetDDM();
experimentUtilities.loadInit(debugUtilities);
window[nameUtilities] = experimentUtilities;
