export enum EventTypes {
  FETCH_SPA_DATA = 'FETCH_SPA_DATA',
  FETCH_USER_DATA = 'FETCH_USER_DATA',
  FETCH_CART_DATA = 'FETCH_CART_DATA',
  INIT_SENTRY = 'INIT_SENTRY',
  INIT_APP = 'INIT_APP',
  CAPTURE_EXCEPTION = 'CAPTURE_EXCEPTION',
  CAPTURE_MESSAGE = 'CAPTURE_MESSAGE',
  GET_CURRENT_GEOLOCATION = 'GET_CURRENT_GEOLOCATION',
  INIT_TABBAR = 'INIT_TABBAR',
  ADDED_FAVORITES = 'ADDED_FAVORITES',
  REMOVED_FAVORITES = 'REMOVED_FAVORITES',
  ADDED_CART = 'ADDED_CART',
  CHANGE_CART = 'CHANGE_CART',
  FAST_CHANGE_CART = 'FAST_CHANGE_CART',
  TAB_BAR_REPAINT = 'TAB_BAR_REPAINT',
  SHOW_DIALOG_QR_PRODUCT = 'SHOW_DIALOG_QR_PRODUCT',
  SHOW_DIALOG = 'SHOW_DIALOG',
  HIDE_DIALOG = 'HIDE_DIALOG',
  PAGE_LOAD_PERSISTED = 'PAGE_LOAD_PERSISTED',
  VIEWED_EXPERIMENT = 'VIEWED_EXPERIMENT',
  DIVIDE_CART_BY_MERCHANT_THRESHOLD = 'DIVIDE_CART_BY_MERCHANT_THRESHOLD',
  HIDE_MAGNUM_PRODUCTS = 'HIDE_MAGNUM_PRODUCTS',
  REPEAT_ORDER_BTN_CLICKED = 'REPEAT_ORDER_BTN_CLICKED',
  KEYBOARD_TOGGLE = 'KEYBOARD_TOGGLE',
}
