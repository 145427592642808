import {
  checkoutData,
  setCartFromUrl,
  getLineItem,
} from '../../services/ddService';
import { DeliveryStepTypes } from '../../pages/checkout/const/DeliveryStepTypes';
import { DeliveryOptionIdTypes } from '../../pages/checkout/const/DeliveryOptionIdTypes';

export function setCartWithPayment(data) {
  const isSpa = data.isSpa ?? true;
  delete data.isSpa;

  const sessionCart = checkoutData.getSessionCart();
  Object.keys(data).forEach((key) => {
    if (data[key] === undefined || data[key] === null) {
      delete data[key];
      delete sessionCart[key];
    }
  });
  const cart = {
    ...sessionCart,
    ...data,
  };
  checkoutData.set({ cart });

  return {
    cart,
    isSpa,
  };
}

export function setCartWithDelivery(data) {
  const sessionCart = checkoutData.getSessionCart();

  // for Kaspi Button
  const isSessionCartEmpty = Object.keys(sessionCart).length === 0;
  if (isSessionCartEmpty) {
    setCartFromUrl(sessionCart);
  }

  let total = sessionCart.total || 0;

  data.carts.forEach((cartItem) => {
    const deliveryOption =
      data.deliveryOptions?.find(
        (option) =>
          option.cartId === cartItem.id &&
          option.typeCase === cartItem.selectedDeliveryType
      ) ?? data.deliveryOptions[0];

    const selectedSlot = deliveryOption?.slots?.deliverySlots.find(
      (s) => s.active
    );

    total += deliveryOption?.data.price || 0;

    cartItem.entries.forEach((entry) => {
      const sessionLineItem = sessionCart.lineItems?.find(
        (lineItem) =>
          lineItem.product.id === entry.productId &&
          lineItem.supplier.id === entry.merchantId
      );

      if (sessionLineItem) {
        if (isSessionCartEmpty) {
          sessionCart.subtotal = entry.price || 0;
          total += entry.price || 0;

          const product = getLineItem(entry);
          Object.keys(product).forEach((key) => {
            sessionLineItem[key] = product[key];
          });
        }

        // separates pickup and postomat delivery options
        if (deliveryOption) {
          sessionLineItem.shippingMethod =
            deliveryOption.typeCase === DeliveryStepTypes.postomat
              ? DeliveryOptionIdTypes.DELIVERY_POSTOMAT
              : deliveryOption.id;

          sessionLineItem.shippingCost = deliveryOption.data.price;

          if (deliveryOption.data.shippingProvider) {
            sessionLineItem.shippingProvider =
              deliveryOption.data.shippingProvider;
          }

          if (deliveryOption.data?.superExpress) {
            sessionLineItem.shippingMethod =
              DeliveryOptionIdTypes.DELIVERY_EXPRESS;
          }
        }

        if (selectedSlot) {
          sessionLineItem.supplier.delivery_timeslot =
            selectedSlot.formattedTime;
          sessionLineItem.supplier.delivery_day = selectedSlot.formattedDate;
        }
      }
    });
  });

  const cart = {
    ...sessionCart,
    total,
  };

  if (data.savedAddressesCount || data.savedAddressesCount === 0) {
    cart.savedAddressesCount = data.savedAddressesCount;
  }

  checkoutData.set({ cart });
  return cart;
}
