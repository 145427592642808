export function getCategoryHierarchyArr(tree: any, categoryArr = []): string[] {
  if (!tree) {
    return categoryArr;
  }

  if (tree.title) {
    categoryArr.push(tree.title);
  }

  const result = tree.items?.find((item: ICategoryTreeItem) => item.active);

  if (result) {
    return getCategoryHierarchyArr(result, categoryArr);
  } else {
    window.digitalData.listing = window.digitalData.listing || {};
    window.digitalData.listing.category = categoryArr;

    return categoryArr;
  }
}
