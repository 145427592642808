import { LocalStorageModel } from '../../StorageModel/LocalStorageModel';

export function isProductSelectedInCart(
  productCode: string,
  productMerchantId: string
): boolean {
  const localStorageModel = new LocalStorageModel({ prefix: 'cart' });
  const storageKey = 'selectedProducts';
  const jsonObject = localStorageModel.getValue(storageKey);
  const productsSelects = JSON.parse(jsonObject) || null;
  return productsSelects?.[`${productCode}_${productMerchantId}`]?.value;
}
