interface ICategoryLevel {
  category_level: string[];
  category_level_1: string;
  category_level_2: string;
  category_level_3: string;
  category_level_4: string;
  category_level_5: string;
  category_string: string[];
}

export function getCategoryHierarchyProps(
  categoryHierarchyArr: string[] = [],
  isShort: boolean = false
): ICategoryLevel {
  if (!categoryHierarchyArr?.length) {
    return {} as ICategoryLevel;
  }

  const categoryProps: ICategoryLevel = {} as ICategoryLevel;
  const tempCategory = [];

  categoryHierarchyArr.forEach((item: string, i: number) => {
    tempCategory.push(item);
    categoryProps[`category_level_${i + 1}`] = tempCategory.join('/');
  });

  if (!isShort) {
    categoryProps.category_string = [categoryHierarchyArr.join('/')];

    categoryProps.category_level = [String(categoryHierarchyArr.length)];
  }

  return categoryProps;
}

export function getMultipleEntriesCategoryHierarchyProps(
  entries: IOrderEntry[]
) {
  const categoryHierarchyArr = entries.map((entry) =>
    getCategoryHierarchyProps(entry.category, true)
  );

  const categoryProps = {};

  categoryHierarchyArr.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (!categoryProps[key]) {
        categoryProps[key] = [];
      }
      categoryProps[key].push(item[key]);
    });
  });

  return categoryProps;
}
