export class BffEndpoint {
  static defaultPath = '/yml/bff-sf';
  /**
   * Orders
   */
  orderDetails = '/storefront/orders/:orderId/';
  ordersList = '/storefront/orders/';
  orderRecipientDetails = '/storefront/orders/recipient/:orderId/';
  orderCancellationReasons = '/storefront/orders/:orderId/cancellation-reason';
  orderAddRecipients = '/storefront/orders/:orderId/recipients/:phoneNumber';
  orderDeleteRecipients = '/storefront/orders/:orderId/recipients/';

  /**
   * Refunds
   */
  refundDetails = '/storefront/refunds/details/:id';
  refundDetailsByCode = '/storefront/refunds/details/code/:code';
  refundsList = '/storefront/refunds/';

  /**
   * Price-trends
   */
  masterDiscounts = '/price-trends/master/discounts';

  /**
   * Other
   */
  userProfile = '/customers/search';
  checkCertificateRecipient = '/customers/certificate-recipient';

  constructor(path = BffEndpoint.defaultPath) {
    for (const key of Object.keys(this)) {
      this[key] = path + this[key];
    }
  }
}
