import { Sentry } from '../../../../../sentry';
import { CONSTANTS } from '../../const/Constants';
import { getInstallId } from '../../helpers/getInstallId';

if (process.env.NODE_ENV === 'development') {
  window.hj = (q: string) => {
    console.warn(`HotjarMapper hj ${q}`);
  };
}

export class HotjarMapper {
  readonly installId: string = getInstallId();

  constructor() {
    if (CONSTANTS.isMobileApp && this.installId) {
      this.initHotjarIdentify();
    }
  }

  initHotjarIdentify() {
    try {
      window.hj('identify', this.installId, {});
    } catch (error) {
      console.error(error);
      Sentry?.captureException(error);
    }
  }
}
