interface IAnalyticSupplierOffers {
  supplier?: string[];
}

export function getSupplier(product: any): IAnalyticSupplierOffers {
  if (!product?.supplierOffers) {
    return {};
  }

  const top_offers = product.supplierOffers.slice(0, 3).map((offer) => offer.supplier.name);

  return {
    supplier: top_offers,
  };
}
