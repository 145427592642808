import { StorageModel } from './StorageModel';

export class SessionStorageModel extends StorageModel {
  constructor({ prefix } = { prefix: null }) {
    super({
      prefix,
      storage: window.sessionStorage,
    });
  }

  static supportedStorage() {
    return StorageModel.supported(window.sessionStorage);
  }

  supportedStorage() {
    return SessionStorageModel.supportedStorage();
  }
}
