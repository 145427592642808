export enum DateType {
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
}

// import { Locales } from 'ks-store/common/const/Locales';
// u can use Locales. but now local by default is ru-RU
function getLocaleDateString(date: Date, locale?: string): string {
  const ru_RULocale = 'ru-RU';
  return date.toLocaleDateString(locale || ru_RULocale, {
    day: 'numeric',
    month: 'long',
  });
}

/**
 * @param dateISO - data in YYYY-MM-DD or YYYY-MM-DDTHH:MM:SS... formats
 */
export function getDateWithoutHours(dateISO: string): Date {
  if (!guardDateISOFormat(dateISO)) {
    return null;
  }

  // eslint-disable-next-line prefer-const
  const { year, month, day } = splitDateByDayMonthYear(dateISO);
  return new Date(+year, +month - 1, +day);
}

/**
 * data formatter, return data in format like "21 february"
 * @param dateISO - data in YYYY-MM-DD or YYYY-MM-DDTHH:MM:SS... formats
 * @param withYear - false by default, if true - return data with year ("21 february, 2022" for example)
 * @param withTodayTomorrow - true by default - return "today" or "tomorrow" text if dates is today or tomorrow
 */
export function getFormattedDate(
  dateISO: string,
  withYear: boolean = false,
  withTodayTomorrow = true
): string {
  if (!guardDateISOFormat(dateISO)) {
    return '';
  }

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const { year } = splitDateByDayMonthYear(dateISO);
  const date = getDateWithoutHours(dateISO);

  const localeDate = getLocaleDateString(date);
  if (localeDate === getLocaleDateString(today) && withTodayTomorrow) {
    return DateType.TODAY;
  }

  if (localeDate === getLocaleDateString(tomorrow) && withTodayTomorrow) {
    return DateType.TOMORROW;
  }

  return withYear ? `${localeDate}, ${year}` : localeDate;
}

/**
 * data formatter, return data in format like "21 february"
 * @param dateISO - data in YYYY-MM-DD or YYYY-MM-DDTHH:MM:SS... formats
 * @param withYear - false by default, if true - return data with year ("21 february, 2022" for example)
 * @param withTodayTomorrow - true by default - return "today" or "tomorrow" text if dates is today or tomorrow
 */
export function dateToFullNumberData(dateISO: string): string {
  if (!guardDateISOFormat(dateISO)) {
    return '';
  }

  const { year, month, day } = splitDateByDayMonthYear(dateISO);

  return `${day}.${month}.${year}`;
}

export function dateToFullISOFormat(dateISO: string): string {
  if (!guardDateISOFormat(dateISO)) {
    return '';
  }

  const { year, month, day } = splitDateByDayMonthYear(dateISO);

  return `${year}-${month}-${day}`;
}

export function splitDateByDayMonthYear(dateISO: string) {
  // eslint-disable-next-line prefer-const
  let [year, month, day] = dateISO.split('-');
  day = day.includes('T') ? day.split('T')[0] : day;

  return {
    year,
    month,
    day,
  };
}

export function guardDateISOFormat(dateISO: string) {
  if (!dateISO) {
    return false;
  }

  if (typeof dateISO !== 'string') {
    return false;
  }

  const dateRegex = /\d{4}-[01]\d-[0-3]\d/;
  // const dateTimeRegex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+/;
  if (!dateISO.match(dateRegex)) {
    return false;
  }

  return true;
}

export function getNextDay(date: Date) {
  return date.getTime() + 24 * 60 * 60 * 1_000;
}