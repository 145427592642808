export const getMerchantsInfo = (products: Array<{ merchantId: string; merchant: string }>) => {
  const merchantsIds = Array.from(
    new Set(products?.map((product) => product.merchantId))
  );

  const merchantsNames = Array.from(
    new Set(products?.map((product) => product.merchant))
  );

  return {
    merchantsIds,
    merchantsNames,
  };
};
