// Use AccountType.ts for constants 'red', 'gold', 'bonus'

export enum PaymentStepTypes {
  credit = 'credit',
  pay_with_credit = 'pay_with_credit',
  installment = 'installment',
  red = 'red',
  gold = 'gold',
  bonus = 'bonus',
  prepaid = 'prepaid', // gold, red
  cash = 'cash', // deprecated
  wallet = 'wallet', // deprecated
  certificate = 'certificate', // opay only method
}
