import { DeliveryOptionIdTypes } from '../../../pages/checkout/const/DeliveryOptionIdTypes';
interface IAvailableDeliveryMethod {
  available_delivery_method: string[];
}

export const getAvailableDeliveryMethod = (
  deliveryOptions
): IAvailableDeliveryMethod => ({
  available_delivery_method: Array.from(
    new Set(deliveryOptions.map((option) => option.id))
  ),
});
