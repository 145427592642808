const localStorageCart = 'ks.checkout.cart';

function getCheckoutData() {
  const cart = JSON.parse(sessionStorage.getItem(localStorageCart) || null);
  return cart ? cart : {};
}

// function setCheckoutData(cart) {
//   sessionStorage.setItem(localStorageCart, JSON.stringify(cart));
// }

export const checkoutData = {
  // set: setCheckoutData,
  // update: updateCheckoutData,
  getSessionCart: getCheckoutData,
};
