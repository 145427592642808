export enum FilterTypes {
  RANGE = 'range',
  REFINE = 'refine',
  MULTISELECT = 'multiselect',
  SELECT = 'select',
  SWITCH = 'switch',
}

export enum FilterIdTypes {
  ALL_MERCHANTS = 'allMerchants',
  MANUFACTURER_NAME = 'manufacturerName',
  DELIVERY_TIME = 'deliveryTime',
  CATEGORY = 'category',
  DISCOUNT = 'discount',
}

export enum FilterTitleTypes { // TODO: consider removing as backend haven't implemented this
  ALPHABETIC = 'alphabetic',
  NUMERIC = 'numeric',
}

export enum FilterDisplayMode {
  EXPANDED = 'expanded',
  COLLAPSED = 'collapsed',
}

export enum FiltersRangeType {
  INPUT = 'input form',
  SLIDER = 'slider',
}

export enum FilterAppliedFrom {
  FILTER_TAGS = 'Filter Tags',
  FILTER_DIALOG = 'Open Filters',
  SWITCH = 'Switch',
  FILTER_SEARCH_DIALOG = 'Filter Values Search',
}
