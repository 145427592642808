import { EventTypes } from '../const/events';
import { addService } from '../services/ksServices';
import {
  AnalyticService,
  IAnalyticEventData,
  IAnalyticEventDataParameters,
  IAnalyticServiceLogger,
} from './AnalyticService';
import { EventType } from './const/EventType';
import { EventServiceClass } from './EventService';
import { DigitalDataMapper } from './mappers/ddMapper';
import { GTMMapper } from './mappers/gtmMapper';
import { HotjarMapper } from './mappers/HotjarMapper';
import { MobappMapper } from './mappers/mobappMapper';
import { TestEventsMapper } from './mappers/testEventsMapper';

export const EventService = new EventServiceClass();
export { EventType };

document.addEventListener(EventTypes.VIEWED_EXPERIMENT, (event: any) => {
  EventService.viewedExperiment(event.detail);
});

const serviceName = 'EventService';
addService(serviceName, EventService);

export const init = (m: any, serviceLogger: IAnalyticServiceLogger) => {
  addService(AnalyticService.ServiceName, new AnalyticService(serviceLogger));

  if (process.env.NODE_ENV === 'development') {
    addSendAnalyticHooksForDev();
  }

  EventService.registrationMapper(new DigitalDataMapper());
  EventService.registrationMapper(new MobappMapper(m));
  EventService.registrationMapper(new TestEventsMapper(m));
  EventService.registrationMapper(new GTMMapper());
  EventService.registrationMapper(new HotjarMapper());

  if (process.env.NODE_ENV === 'development') {
    logUserProperties();
  }
};

function addSendAnalyticHooksForDev() {
  const logEvent = (
    event: string,
    parameters: IAnalyticEventDataParameters
  ) => {
    const isPromoted =
      parameters?.is_promoted?.[0] === 'true' ? 'is_promoted ' : '';
    const title =
      event === 'shop_product_teaser_view' ||
      event === 'shop_product_teaser_click'
        ? `%c${event} %c(${isPromoted}listing_position=${parameters.listing_position?.[0]} product_id=${parameters.product_id})`
        : `%c${event}%c`;

    console.groupCollapsed(title, 'color: #1b9c35', 'color: gray');
    console.table(parameters);
    console.groupEnd();
  };

  window.ksService.AnalyticService.addLogHook(
    (eventData: IAnalyticEventData) => {
      logEvent(eventData.event, eventData.parameters);
    }
  );

  // for kaspi.send fallback
  window.mobapp = window.mobapp || {};
  window.mobapp.logAnalyticEvent = (data: any) => {
    const eventData = JSON.parse(data);
    logEvent(eventData.event, eventData.parameters);
  };
  window.mobapp.setAwsUserProperties = (properties: any) => {
    logEvent('setAwsUserProperties', JSON.parse(properties));
  };
}

function logUserProperties() {
  const userProperties = window.ksService.AnalyticService?.getUserProperties();
  if (userProperties) {
    // eslint-disable-next-line no-console
    console.log('%c setAwsUserProperties', 'color: #1b9c35', userProperties);
  }
}
