import { getCategoriesParams } from './getCategoriesParams';

interface IGetShareCartReturnParams {
  cart_id: string;
  cart_quantity: number;
  cart_size: number;
  promo_sku_count: number;
  cart_quantity_not_available: number;
  category_level_1: string[];
  category_level_2: string[];
  category_level_3: string[];
  category_level_1_count: number;
  category_level_2_count: number;
  category_level_3_count: number;
}

export const getShareCartParams = (
  shareCart: IGetShareCartParams
): IGetShareCartReturnParams => {
  const cartCategoryParams = getCategoriesParams(
    shareCart.sharedEntries?.map((item) => item.categoryNames)
  );

  return {
    cart_id: shareCart.shareLink?.replace('/shop/cart?share=', ''),
    cart_quantity: shareCart.totalQuantity,
    cart_size: shareCart.sharedEntries?.length,
    promo_sku_count: shareCart.sharedEntries?.reduce(
      (acc, item) => (item?.promo?.length ? acc + 1 : acc),
      0
    ),
    cart_quantity_not_available: shareCart.sharedEntries?.filter(
      (entry) => entry.outOfStock
    ).length,
    ...cartCategoryParams,
  };
};
