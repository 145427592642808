export const getCategoriesParams = (lineItems) => {
  let categoryLevel1 = [];
  let categoryLevel2 = [];
  let categoryLevel3 = [];

  lineItems?.forEach((category = {}) => {
    category[0] && categoryLevel1.push(category[0]);
    category[1] && categoryLevel2.push(category[1]);
    category[2] && categoryLevel3.push(category[2]);
  });

  categoryLevel1 = Array.from(new Set(categoryLevel1));
  categoryLevel2 = Array.from(new Set(categoryLevel2));
  categoryLevel3 = categoryLevel3?.length > 0 ? Array.from(new Set(categoryLevel3)) : null;

  const params = {
    category_level_1: categoryLevel1,
    category_level_2: categoryLevel2,
    category_level_3: categoryLevel3,
    category_level_1_count: categoryLevel1.length,
    category_level_2_count: categoryLevel2.length,
    category_level_3_count: categoryLevel3?.length ?? 0,
  };

  return params;
};
