import { StorageModel } from './StorageModel';

export class LocalStorageModel extends StorageModel {
  constructor({ prefix } = { prefix: null }) {
    super({
      prefix,
      storage: window.localStorage,
    });
  }

  static supportedStorage() {
    return StorageModel.supported(window.localStorage);
  }

  supportedStorage() {
    return LocalStorageModel.supportedStorage();
  }
}
