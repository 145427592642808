'use strict';
/**
 *  KS Service
 *  @module ksService
 *  @description service for adding othen services to publish
 *  @example window.ksService.ItemConfigurator.initDialog({});
 */

window.ksService = window.ksService || {};

export function addService(name, service) {
  window.ksService[name] = service;
}

export function getService(name) {
  return window.ksService[name];
}
