import { getStartedFrom } from './getStartedFrom';
import { getFromUrl } from './getParamFromSearchQuery';
import { getBaseParams } from './getBaseParams';

export function getMyOrderDetailsParam(data: IParamOrderPage) {
  const startedFrom = getStartedFrom();
  const parameters = {
    path: [document.location.pathname + document.location.hash],
    ref: getFromUrl.ref(),
    ...startedFrom,

    order_id: data.orderId,
    suborder_id: data.orderId,

    current_status: data.currentStatus,
    order_quantity: data.orderQuantity,
    order_size: data.orderSize,

    delivery_cost: data.totalPrice.delivery,
    subtotal: data.totalPrice.price,
    total: data.totalPrice.delivery + data.totalPrice.price,

    supplier: data.merchant.name,
    supplier_id: data.entries.filter((entry) => entry.merchantId)[0]
      ?.merchantId,

    payment_method: data.paymentData.type,
    delivery_method: data.delivery.type,

    cart_quantity_missing: data.entries.filter((entry) => !entry.quantity)
      .length,
  };

  return parameters;
}

export function getMyOrdersParam(data: IMyOrderAnalytic) {
  const order_counts = data.total;
  const baseParams = getBaseParams();

  const parameters = {
    ...baseParams,
    requests_count: data.requestsCount,
    order_counts,
    path: [document.location.pathname + document.location.hash],
    source: getFromUrl.source(),
    ref: getFromUrl.ref(),
  };
  return parameters;
}
