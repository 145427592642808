import cookies from 'ks-utilities/lib/cookies';
import { validateNotEmptyArray } from 'ks-utilities/lib/validateNotEmptyArray';

import { AvailableZonesApiProvider } from '../../apiProvider/AvailableZonesApiProvider';
import { magnumId } from '../../const/MagnumCodes';
import { getInstallIdForPCM } from '../../helpers/getInstallId';
import { getUserIdHashForPCM } from '../../helpers/getUserIdHash';
import { defaultCityId } from '../locationService';
import { ProductCardService } from '../ProductCardService';
import { CarouselRotationService } from './CarouselRotationService';

export enum EventName {
  getCrossSellProducts = 'GET_CROSSELL_PRODUCTS',
  getItemCardProducts = 'GET_ITEM_CARD_PRODUCTS',
  getShopEvent = 'GET_SHOP_EVENT',
  getShopBackdropEvent = 'GET_SHOP_BACKDROP_EVENT',
  getCrossSellRelatesProducts = 'GET_CROSSELL_BACKDROP',
}

const GET_SHOP_EVENTS = [EventName.getShopEvent];

export enum SourceSystem {
  kaspiShop = 'KASPISHOP',
  kaspiKz = 'KASPIKZ',
}

export enum PCMBlockStyles {
  GOODS_LIST = 'GOODS_LIST', // pcm teasers carousel
  CAROUSEL = 'CAROUSEL', // pcm banners carousel
  LIST_INFINITY = 'LIST_INFINITY', // pcm teasers grid infinity
  ADVT = 'ADVT', // pcm advertisement banner
  LIST4 = 'LIST4', // pcm brand/category tiles 2 * 2
  LIST6 = 'LIST6', // pcm brand/category tiles 3 * 2
  BACKDROP = 'BACKDROP', // pcm backdrop
}

export enum PCMBlockTypes {
  MAGNUM_GOODS_HORIZONTAL_CAROUSEL = 'MAGNUM_GOODS_HORIZONTAL_CAROUSEL',
  ANALOG_GOODS_CAROUSEL = 'ANALOG_GOODS_CAROUSEL',
  AUCTION_MERCHANT_GOODS_HORIZONTAL_CAROUSEL = 'AUCTION_MERCHANT_GOODS_HORIZONTAL_CAROUSEL',
}

export const pcmBlocksWithSkus = [
  PCMBlockStyles.LIST_INFINITY,
  PCMBlockStyles.GOODS_LIST,
  PCMBlockStyles.BACKDROP,
];

export const pcmTeasersBlockStyles = [
  PCMBlockStyles.GOODS_LIST,
  PCMBlockStyles.LIST_INFINITY,
];

export interface PCMBlock {
  code: string;
  type: string;
  style: PCMBlockStyles;
  actionUrl: string;
  logoUrl?: string;
  title: string;
  textUrl: string;
  rotation: boolean;
  random: boolean;
  eventId: string;
}

export interface PCMRecommendBlock extends PCMBlock {
  list: string[] | number[];
}

export interface PCMAdsBlock extends PCMBlock {
  list: IAdsResponseItem[] | IAdsPcmResponseItem[];
}

export abstract class PCMService {
  readonly adsBlockTypePart = 'AUCTION';
  m: any; // mithril
  data: any = null;
  isSkeleton: boolean = true;
  mUid: string;
  readonly userId = window.digitalData?.user?.userId;
  availableZoneProvider: AvailableZonesApiProvider;

  protected pcmData: any = null;

  protected url: string = null;
  protected headers: any = null;
  productCardService: ProductCardService;

  abstract sourceSystem: SourceSystem;
  abstract eventName: EventName;

  constructor(m, endpoint) {
    console.assert(endpoint, 'pcm endpoint is needed');

    this.m = m;
    this.url = endpoint;
    this.productCardService = new ProductCardService(m);
    this.availableZoneProvider = new AvailableZonesApiProvider(m);
  }

  get userIdHash() {
    return getUserIdHashForPCM();
  }

  get installId() {
    return getInstallIdForPCM();
  }

  get cityCode(): string {
    const cookieName = 'kaspi.storefront.cookie.city';
    return cookies.get(cookieName) || defaultCityId;
  }

  get isPCMRequestAllowed() {
    // array of eventNames that are not allowed to make request if userIdHash is -1;
    const eventNames = [
      EventName.getCrossSellProducts,
      EventName.getShopBackdropEvent,
      EventName.getItemCardProducts,
    ];

    return this.userIdHash !== '-1' || !eventNames.includes(this.eventName);
  }

  get pcmGoodsSkus() {
    return (this.pcmData || [])
      .filter(
        (block) =>
          pcmBlocksWithSkus.includes(block.style) &&
          !block.type.includes(this.adsBlockTypePart)
      )
      .reduce((acc, cur) => {
        const list = cur.list.map(
          (item) => (item.id || item.sku || item.productId) ?? item
        );
        return acc.concat(list);
      }, []);
  }

  get adsGoods() {
    return (this.pcmData || [])
      .filter(
        (block) =>
          pcmBlocksWithSkus.includes(block.style) &&
          block.type.includes(this.adsBlockTypePart)
      )
      .reduce((acc, cur) => acc.concat(cur.list), []);
  }

  async fetchData(body) {
    console.assert(
      typeof this.eventName === 'string',
      'PCMService: eventName must be a string'
    );

    const defaultHeaders = {
      'Event-name': this.eventName,
      'Source-system': this.sourceSystem,
      'Content-Type': 'text/plain; charset=utf-8',
    };

    const headers = this.headers ?? defaultHeaders;

    if (!this.isPCMRequestAllowed) {
      return null;
    }

    try {
      const resp = await this.m.request({
        url: this.url,
        method: 'POST',
        headers,
        body,
      });
      return resp;
    } catch (error) {
      this.data = null;
      throw error;
    }
  }

  async getChunkedGoods(): Promise<IItemCard[]> {
    try {
      const responseGoods: any = await Promise.all([
        this.productCardService.getProductCardsByChunks(
          this.pcmGoodsSkus,
          this.mUid
        ),
        this.productCardService.getAdCardsWithOffersByChunks(this.adsGoods),
      ]);

      const data = responseGoods.reduce((acc, cur) => acc.concat(cur), []);

      return data;
    } catch (error) {
      this.data = null;
      throw error;
    }
  }

  filterPCMBlocksByAvailableGoods(
    pcmGoodsList: any[],
    availableGoodsList: any[],
    isAd = false
  ) {
    const filteredList = [];

    pcmGoodsList?.forEach((pcmGood) => {
      const good = availableGoodsList?.find((g) => {
        const id = pcmGood.id || pcmGood.sku || pcmGood.productId;
        const pcmGoodId = id ? String(id) : String(pcmGood);
        // ? all ads items have campaignId
        const isCheckAd = isAd ? !!g.campaignId : true;
        return isCheckAd && pcmGoodId === g.id;
      });

      if (good) {
        if (pcmGood.sourceSku) {
          good.sourceSku = pcmGood.sourceSku;
        }
        filteredList.push(good);
      }
    });

    return filteredList;
  }

  getBlockWithAvailableList(block, availableGoods?: any[]) {
    const isAd = block.type.includes(this.adsBlockTypePart);
    const list = availableGoods
      ? this.filterPCMBlocksByAvailableGoods(block.list, availableGoods, isAd)
      : block.list;

    if (list.length) {
      return {
        ...block,
        list,
        header: block.title || block.header,
        bottom: block.textUrl || block.bottom,
        isRawList: !availableGoods,
      };
    }

    return null;
  }

  setProductsCarousel(block) {
    if (block?.rotation || block?.random) {
      const prefix = `product-rotation.${block.code}`;
      const carouselRotationService = new CarouselRotationService({
        prefix,
        items: block.list,
        isRandom: block.random,
      });
      return { ...block, list: carouselRotationService.getRotatedItems() };
    }

    return block;
  }

  getPCMDataWithAvailableGoods(availableGoods?: any[]) {
    const pcmBlocksWithNonEmptyGoodsList = [];

    if (validateNotEmptyArray(this.pcmData)) {
      for (const block of this.pcmData) {
        switch (block.style) {
          case PCMBlockStyles.ADVT:
          case PCMBlockStyles.LIST4:
          case PCMBlockStyles.LIST6: {
            pcmBlocksWithNonEmptyGoodsList.push({
              ...block,
              category_id: block.categoryId,
              header: block.title,
              list: block.list.map((item) => ({
                ...item,
                image_url: item.imageUrl,
                action_url: item.actionUrl,
              })),
              bottom: block.textUrl,
              bottom_url: block.actionUrl,
            });

            break;
          }
          case PCMBlockStyles.GOODS_LIST:
            const filteredBlock = this.getBlockWithAvailableList(
              block,
              availableGoods
            );
            if (filteredBlock) {
              pcmBlocksWithNonEmptyGoodsList.push(
                this.setProductsCarousel(filteredBlock)
              );
            }

            break;
          case PCMBlockStyles.LIST_INFINITY:
          case PCMBlockStyles.BACKDROP: {
            const blockWithList = this.getBlockWithAvailableList(
              block,
              availableGoods
            );
            if (blockWithList) {
              pcmBlocksWithNonEmptyGoodsList.push(blockWithList);
            }

            break;
          }
        }
      }
    }

    return pcmBlocksWithNonEmptyGoodsList;
  }

  async setMagnumPrice(availableGoods: IItemCard[]) {
    if (
      !this.pcmData.some(
        (block) => block.type === PCMBlockTypes.MAGNUM_GOODS_HORIZONTAL_CAROUSEL
      )
    ) {
      return availableGoods;
    }

    this.mUid = magnumId;
    const filteredGoods = await this.filterByDeliveryZoneMagnumGoods(
      availableGoods
    );

    const products = await this.productCardService.updatePriceByMerchant(
      filteredGoods,
      magnumId
    );
    return products;
  }

  async filterByDeliveryZoneMagnumGoods(list: IItemCard[]) {
    const zoneIds = await this.availableZoneProvider.getAvailableZones();
    return list.filter((item) => {
      if (item.id && item.deliveryZones) {
        return item.deliveryZones?.some((zone) => zoneIds.includes(zone));
      }

      return true;
    });
  }

  abstract init(): void;

  abstract createMockData(): void;
}

// ? DEV:
if (process.env.NODE_ENV === 'development') {
  // * PCM's installId for tests
  cookies.set('installId', 'D8857714-FAFA-417B-86EF-232FAA9D3405', {
    path: '/',
  });

  // * PCM's userIdHash for tests
  cookies.set('pd', 'RMqiiygehVnN4YJRhfvUxD', {
    path: '/',
  });
}
