import { IAnalyticEventRawData } from '../AnalyticService';
import { combineOffersFiltersEventProperties } from '../helpers/combineOffersFiltersEventProperties';
import { combineProductEventProperties } from '../helpers/combineProductEventProperties';
import { getCategoryHierarchyProps } from '../helpers/getCategoryHierarchyProps';
import { getProductPromo } from '../helpers/getProductPromo';

export class TestEventsMapper {
  m: any; // mithril

  constructor(m: any) {
    this.m = m;
  }

  sendEvent(eventData: IAnalyticEventRawData) {
    window.ksService.AnalyticService.log(eventData);
  }

  viewedButtonConvenientReturn(data: IParamConvenientReturn) {
    const eventName = 'test_shop_return_button_view';

    const { category_level, category_string, ...categoryLevels } =
      getCategoryHierarchyProps(data.product.category);

    const parameters = {
      product_id: data.productId,
      ...categoryLevels,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickedBackdropConvenientReturn(data: IParamConvenientReturn) {
    const eventName = 'test_shop_return_backdrop_click';

    const { category_level, category_string, ...categoryLevels } =
      getCategoryHierarchyProps(data.product.category);

    const parameters = {
      product_id: data.productId,
      ...categoryLevels,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedBackdropConvenientReturn(data: IParamConvenientReturn) {
    const eventName = 'test_shop_return_backdrop_view';

    const { category_level, category_string, ...categoryLevels } =
      getCategoryHierarchyProps(data.product.category);

    const parameters = {
      product_id: data.productId,
      ...categoryLevels,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  closeBackdropConvenientReturn(data: IParamConvenientReturn) {
    const eventName = 'test_shop_return_backdrop_close';

    const { category_level, category_string, ...categoryLevels } =
      getCategoryHierarchyProps(data.product.category);

    const parameters = {
      product_id: data.productId,
      ...categoryLevels,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedProductDescriptionTest(data: IParamViewedProductPage) {
    const eventName = 'test_shop_product_description';
    const globalPromotion = data.promo;
    const hasConfigurator = data.hasConfigurator;
    const { promo = null } = getProductPromo(globalPromotion);

    const parameters = {
      ...combineProductEventProperties(data),
      promo,
      has_configurator: !!hasConfigurator,
      is_discount: data.discountType,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedOffersSortBtn(data: IOffersFiltersAnalyticParams) {
    const eventName = 'test_shop_product_sort_click';

    const parameters = {
      ...combineOffersFiltersEventProperties(data),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  appliedOffersSorting(data: IOffersFiltersAnalyticParams) {
    const eventName = 'test_shop_product_sort_apply';

    const parameters = {
      ...combineOffersFiltersEventProperties(data),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedOffersDeliveryFiltersBackdrop(data: IOffersFiltersAnalyticParams) {
    const eventName = 'test_shop_product_delivery_backdrop';

    const parameters = {
      ...combineOffersFiltersEventProperties(data),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  appliedOffersFilter(data: IOffersFiltersAnalyticParams) {
    const eventName = 'test_shop_product_filter_apply';

    const parameters = {
      ...combineOffersFiltersEventProperties(data),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  resetOffersFilter(data: IOffersFiltersAnalyticParams) {
    const eventName = 'test_shop_product_filter_reset';

    const parameters = {
      ...combineOffersFiltersEventProperties(data),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  startedOffersSearchQuery(data: IOffersFiltersAnalyticParams) {
    const eventName = 'test_shop_product_search_query_start';

    const parameters = {
      ...combineOffersFiltersEventProperties(data),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedOffersSearchResult(data: IOffersFiltersAnalyticParams) {
    const eventName = 'test_shop_product_search_results_view';

    const parameters = {
      ...combineOffersFiltersEventProperties(data),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedLandingDresses(categories: string[]) {
    const eventName = 'test_shop_dresses_viewed_landing';

    const { category_level, category_string, ...categoryLevels } =
      getCategoryHierarchyProps(categories);

    const parameters = {
      ...categoryLevels,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickedModelLandingDresses(categories: string[]) {
    const eventName = 'test_shop_dresses_clicked_model';

    const { category_level, category_string, ...categoryLevels } =
      getCategoryHierarchyProps(categories);

    const parameters = {
      ...categoryLevels,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickedBrandLandingDresses(categories: string[]) {
    const eventName = 'test_shop_dresses_clicked_topbrand';
    const brandName = categories[categories.length - 1];
    categories.pop();

    const { category_level, category_string, ...categoryLevels } =
      getCategoryHierarchyProps(categories);

    const parameters = {
      ...categoryLevels,
      brand_name: brandName,
    };

    this.sendEvent({ event: eventName, parameters });
  }
}
