import { isPrevPageEquals } from '../helpers/windowHistoryHelper';
import { parseQueryString } from '../../helpers/parseQueryString';

export function getSearchTermFromPrevSearchPage() {
  if (
    isPrevPageEquals('search') &&
    document.location.pathname.includes('/p/')
  ) {
    const refQueryStr = document.referrer.substring(
      document.referrer.indexOf('?')
    );
    return parseQueryString(refQueryStr)?.text;
  }
  return null;
}
