import { mutateProductCardsByOffer } from '../../apiProvider/helpers/mutateProductCards';
import { OffersApiProvider } from '../../apiProvider/offers/OffersApiProvider';
import { ProductListApiProvider } from '../../apiProvider/ProductListApiProvider';
import { CardModel } from '../../CardModel';
import { ProductCardService } from '../ProductCardService';
import { EventName, PCMService, SourceSystem } from './pcmService';

type CrossSellEventName =
  | EventName.getCrossSellProducts
  | EventName.getCrossSellRelatesProducts;
type CrossSellEventBody =
  | IGetCrossSellRelatesProductsBody
  | IGetCrossSellProductsBody;

interface IGetCrossSellProductsBody {
  userIdHash: string;
  languageType?: string;
  cityId: string;
  sku: string[];
  products: Array<{ sku: string; merchantId: string }>;
}

interface IGetCrossSellRelatesProductsBody {
  shop_merchant_id: string;
  article_number: string;
  cityId: string;
  productId: string;
  merchantId: string;
}

interface IPcmCrossSellServiceParams {
  m: any;
  endpoint: string;
  cartItemsSkus: string[];
  eventName?: CrossSellEventName;
  params?: Partial<CrossSellEventBody>;
  mUid?: string;
  city?: string;
  headers?: object;
}

export const crossSellAnalyticName = 'crossSellBackdrop';

export class PCMCrossSellService extends PCMService {
  eventName = EventName.getCrossSellProducts;
  sourceSystem = SourceSystem.kaspiKz;
  skus: string[] = [];
  params: Partial<CrossSellEventBody>;
  offersApiProvider = new OffersApiProvider(this.m);
  productCardService = new ProductCardService(this.m);
  productListApiProvider: ProductListApiProvider = new ProductListApiProvider(
    this.m
  );
  readonly PRODUCTS_SKELETON_AMOUNT = 6;

  constructor(params: IPcmCrossSellServiceParams) {
    super(params.m, params.endpoint);

    this.headers = {
      ...(params.headers ?? {}),
      'Event-name': params.eventName || this.eventName,
      'Source-system': this.sourceSystem,
      'Content-Type': 'application/json',
    };

    if (params.eventName) {
      this.eventName = params.eventName;
    }
    if (params.params) {
      this.params = {
        ...params.params,
        sku: params.cartItemsSkus,
        languageType: this.m.t.locale,
      };
    }
    if (params.mUid) {
      this.mUid = params.mUid;
    }

    this.skus = params.cartItemsSkus;
  }

  async init() {
    this.data = [];

    const defaultBody: CrossSellEventBody = {
      userIdHash: this.userIdHash,
      sku: this.skus,
      products: [],
      cityId: this.cityCode,
    };

    try {
      this.pcmData = await this.fetchData(this.params || defaultBody);

      if (!this.pcmData) {
        return;
      }

      const response = await this.getMerchantOffer();
      const bestOffers = response.offers;

      const isPcmDataArray: boolean = Array.isArray(this.pcmData);
      const translation =
        this.productListApiProvider.productListApiProviderTranslate;

      if (!isPcmDataArray) {
        this.pcmData = [this.pcmData];
      }

      let availableGoods = await this.getChunkedGoods();
      availableGoods = await this.setMagnumPrice(availableGoods);

      if (bestOffers?.length) {
        availableGoods = mutateProductCardsByOffer({
          products: availableGoods,
          offers: bestOffers,
          translation,
          pcmDataList: this.pcmData[0]?.list
        });
      }

      this.data = this.getPCMDataWithAvailableGoods(availableGoods);
    } catch (error) {
      //  eslint-disable-next-line:no-console
      console.error(error);
    } finally {
      this.isSkeleton = false;
    }
  }

  // override
  async fetchData(params): Promise<any> {
    const defaultHeaders = {
      'Event-name': this.eventName,
      'Source-system': this.sourceSystem,
      'Content-Type': 'text/plain; charset=utf-8',
    };

    const headers = this.headers ?? defaultHeaders;

    if (!this.isPCMRequestAllowed) {
      return null;
    }

    try {
      const resp = await this.m.request({
        url: this.url,
        method: 'GET',
        headers,
        params,
      });

      return resp;
    } catch (error) {
      this.data = null;
      throw error;
    }
  }

  getMerchantOffer() {
    const entries = this.pcmData.list.map((item) => ({
      productCode: item.productId,
      merchantId: item.merchantId,
    }));

    return this.offersApiProvider.getOffersByMerchant(entries);
  }

  createMockData() {
    const model = new CardModel();
    const list = new Array(this.PRODUCTS_SKELETON_AMOUNT).fill(model.card);

    return [{ list }];
  }
}
