// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm

const gtm = {
  push(data: IGTMEventData) {
    if (window.dataLayer) {
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object. //https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clear_the_ecommerce_object
      window.dataLayer.push(data);
    }
  },
};

interface IGTMEventData {
  event: string;
  ecommerce: Record<string, any>;
}

// standard fields for GoogleAnalytics 4 events' "Item" object
export interface IBaseItem {
  item_id: string; // * One of item_id or item_name is required.
  item_name: string; // * One of item_id or item_name is required.
  affiliation?: string;
  coupon?: string;
  discount?: number;
  index?: number;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string; // * The ID of the list in which the item was presented to the user. If set, event-level item_list_id is ignored. If not set, event-level item_list_id is used, if present.
  item_list_name?: string; // * The name of the list in which the item was presented to the user. If set, event-level item_list_name is ignored. If not set, event-level item_list_name is used, if present.
  item_variant?: string;
  location_id?: string;
  price?: number;
  quantity?: number;
}

export interface IViewItemList {
  item_list_id?: string; // * The ID of the list in which the item was presented to the user. Ignored if set at the item-level.
  item_list_name?: string; // * The name of the list in which the item was presented to the user. Ignored if set at the item-level.
  items: IBaseItem[];
}

export interface IViewItem {
  currency: string; // * If you set value then currency is required for revenue metrics to be computed accurately.
  value: number; // * If you set value then
  items: IBaseItem[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAddToCart extends IViewItem {}

export interface IPurchase extends IAddToCart {
  transaction_id: string;
  coupon?: string;
  shipping?: number;
  tax?: number;
}

export default gtm;
