import { getFromUrl } from './getParamFromSearchQuery';
import { getBaseParams } from './getBaseParams';
import { getProductInfo } from './getProductInfo';
import { getCategoryHierarchyProps } from './getCategoryHierarchyProps';
import { getCategoriesParams } from './getCategoriesParams';

export const getCartParams = (cart: any) => {
  const suppliers = cart.lineItems?.reduce((acc, item) => {
    if (acc.some((i) => i === item.supplier?.name)) {
      return acc;
    }
    acc.push(item.supplier?.name);
    return acc;
  }, []);

  const cartCategoriesParams = getCategoriesParams(cart.lineItems?.map((item)=> item.product.category));

  const cartParams = {
    checkout_method: cart.checkoutMethod,
    cart_id: cart.id,
    cart_quantity: cart.lineItems?.reduce(
      (acc, item) => acc + item.quantity,
      0
    ),
    cart_size: cart.lineItems?.length,
    promo_sku_count: cart.lineItems?.reduce(
      (acc, item) => (item.product?.promo ? acc + 1 : acc),
      0
    ),
    total_price: cart.total,
    subtotal_price: cart.subtotal,
    suppliers,
    supplier_count: suppliers?.length || 0,
    saved_address_count: cart.savedAddressesCount,
    ...cartCategoriesParams,
  };

  return cartParams;
};

export const getProductInCartParams = (data: IParamChangeProductInCart) => {
  const baseParams = getBaseParams();
  const productInfoData = getProductInfo(data.product);
  const categoryInformation = getCategoryHierarchyProps(data.product?.category);
  const cartParams = getCartParams(data.cart);

  const parameters = {
    ...baseParams,
    ...productInfoData,
    ...categoryInformation,
    ...cartParams,
    quantity: data.quantity,
    suborder_count: data.groupsLength,
    ref: getFromUrl.ref()
  };

  return parameters;
};

export const getProductWithOneCategoryInCartParams = (data: IParamChangeProductInCart) => {
  const baseParams = getBaseParams();
  const productInfoData = getProductInfo(data.product);
  const categoryInformation = getCategoryHierarchyProps(data.product?.category);
  const cartParams = getCartParams(data.cart);

  const parameters = {
    ...baseParams,
    ...productInfoData,
    ...cartParams,
    ...categoryInformation,
    quantity: data.quantity,
    suborder_count: data.groupsLength,
    ref: getFromUrl.ref()
  };

  return parameters;
};
