import {
  getBaseDeliveryOptions,
} from './delivery/getBaseDeliveryOptions';

interface IAnalyticOfferInfo {
  cartStatus?: string;
  categoryCode?: string;
  deliveryOptions?: IOfferDeliveryOption[];
  id?: string;
  kaspiDelivery?: boolean;
  monthlyInstallments?: any[];
  name?: string;
  promo?: any[];
  rating?: number;
  reviewsLink?: string;
  reviewsQuantity?: number;
  shopLink?: string;
  unitPrice: number;
  unitSalePrice?: any;
}

export function getOfferBaseParams(offer: IAnalyticOfferInfo) {
  if (!offer) {
    return;
  }

  const deliveryOptions = getBaseDeliveryOptions(offer.deliveryOptions);

  const data = {
    supplier_id: offer.id,
    supplier: offer.name,
    supplier_rating: offer.rating,
    supplier_review_count: offer.reviewsQuantity,
    supplier_price: offer.unitPrice,
    ...deliveryOptions,
  };

  return data;
}
