import { DateType, getFormattedDate } from './getFormattedDate';

export function getFormattedDeliveryDate(
  dateISO: string,
  t: { today: string; tomorrow: string } = { today: null, tomorrow: null }
) {
  let deliveryDate = '';
  const formattedDate = getFormattedDate(dateISO);

  switch (formattedDate) {
    case DateType.TODAY:
      deliveryDate = t.today;
      break;
    case DateType.TOMORROW:
      deliveryDate = t.tomorrow;
      break;
    default:
      deliveryDate = formattedDate;
      break;
  }

  return deliveryDate;
}
