export enum FilterDeliveryType {
  EXPRESS = 'EXPRESS',
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  TODAY_TOMORROW = 'TODAY_TOMORROW',
  TILL_2_DAYS = 'TILL_2_DAYS',
  TILL_5_DAYS = 'TILL_5_DAYS',
  TILL_7_DAYS = 'TILL_7_DAYS',
  OTHER = 'OTHER',
}

// Product List filter where id is a number
export enum FilterDeliveryTime {
  EXPRESS = 'e',
  TODAY = 0,
  TOMORROW = 1,
  TILL_2_DAYS = 2,
  TILL_5_DAYS= 5,
  TILL_7_DAYS= 7,
  OTHER = 'x',
}

export const filterDeliveryTypeFromTime = {
  [FilterDeliveryTime.EXPRESS]: FilterDeliveryType.EXPRESS,
  [FilterDeliveryTime.TODAY]: FilterDeliveryType.TODAY,
  [FilterDeliveryTime.TOMORROW]: FilterDeliveryType.TOMORROW,
  [FilterDeliveryTime.TILL_2_DAYS]: FilterDeliveryType.TILL_2_DAYS,
  [FilterDeliveryTime.TILL_5_DAYS]: FilterDeliveryType.TILL_5_DAYS,
  [FilterDeliveryTime.TILL_7_DAYS]: FilterDeliveryType.TILL_7_DAYS,
  [FilterDeliveryTime.OTHER]: FilterDeliveryType.OTHER,
};

export const filterDeliveryId = 'deliveryTime';
