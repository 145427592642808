import { Cookies } from 'ks-utilities/lib/cookies';
import { toVw } from 'ks-utilities/lib/toVw';

import { EventTypes } from './const/events';
import { fireNativeEvent } from './services/eventEmitterService';
import { getMobileOperatingSystem } from './services/osService';
import { LocalStorageModel } from './StorageModel/LocalStorageModel';

interface IExperimentUtilities {
  listCatchesForSentry: any[];
  sentryIsLoad: boolean;
  readyAppState: 'loading' | 'ready';
  LS: LocalStorageModel;
  cookies: {
    get(name: string): string;
    remove(name: string, options?: any): void;
    set(name: string, value: string, options?: any): void;
  };
  getMobileOperatingSystem(): string;
  insertAfter(newNode: HTMLElement, referenceNode: HTMLElement): void;
  loadInit(func: () => void): void;
  pushExperiment(data: any): void;
  snippetDDM(): void;
  appendStyles(id: string, css: string): HTMLStyleElement;
  toVw(px: number, vp?: number): string;
  watchComponent(name: string, callback: () => void): void;
  wrapperPromise(wrapperData: {
    func(data: any, headers?: any): Promise<any>;
    checkUrl(data: any): boolean;
    mutateRequest(data: any, headers?: any): void;
    mutate(response: any): any;
  }): (data: any, headers?: any) => Promise<any>;
  asyncWrapperPromise(wrapperData: {
    func(data: any, headers?: any): Promise<any>; // original m.request
    checkUrl?(data: any): boolean | number;
    mutateError?(error: any, headers?: any): void;
    mutateRequest?(data: any, headers?: any): void;
    mutateResponse?(response: any): any;
  }): (data: any, headers?: any) => Promise<any>;
  getCookie(name: string): string; // ! is deprecated, use only for support older ab-tests
  catchBugsInSentry(func: any, param: any): void;  /* any function */
  fireNativeEvent(eventName: EventTypes, data: any): void;
  getUrlParams(): any;
  watchChildListMutations(target: HTMLElement, waitingForElSelector: string, func: (foundEl: HTMLElement) => void): void;
}

const USER_COOKIE_NAME_SLOT = 'ks.tg';
const USER_COOKIE_NAME_RANDOM = 'ks.rt';
const ALTERNATIVE_USER_COOKIE_NAME_RANDOM = 'ks.ab';
export const nameUtilities = 'testUtils';

let eventPushLoadInitStatusPushed = false;

export const experimentUtilities: IExperimentUtilities = {
  sentryIsLoad: false,
  readyAppState: 'loading',
  listCatchesForSentry: [],
  loadInit: (func) => {
    if (!eventPushLoadInitStatusPushed) {
      eventPushLoadInitStatusPushed = true;
      if (experimentUtilities.readyAppState === 'ready') {
        experimentUtilities.fireNativeEvent(EventTypes.CAPTURE_MESSAGE, 'ab-test: loadInit with status ready');
      }
    }

    if (experimentUtilities.readyAppState === 'loading') {
      document.addEventListener(EventTypes.INIT_APP, func);
    } else {
      func();
    }
  },
  pushExperiment: (data) => {
    /**
     *
     * Example:
     * digitalData.events.push({
     *  name: 'Viewed Experiment',
     *  experiment: { id: '69cda099-bdd1-4b48-a6e2-0776f82e7502', variationId: 0 }
     * });
     *
     */

    let eventPushed = false;

    const pushEvent = (pushData) => {
      fireNativeEvent(EventTypes.VIEWED_EXPERIMENT, pushData);
      window.ddListener && window.ddListener.push(['on', 'event', (event) => {
        if (event.name === 'Viewed Page') {
          if (!eventPushed) {
            eventPushed = true;
            window.digitalData.events.push({
              type: 'ExperimentUtils pushEvent',
              name: 'Viewed Experiment',
              experiment: pushData,
            });
          }
        }
      }]);
    };

    const func = pushEvent.bind(this, data);

    if (document.readyState !== 'complete') {
      window.addEventListener('load', func, false);
    } else {
      func();
    }
  },
  insertAfter: (newNode, referenceNode) => {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  },
  watchComponent: (name, callbackFunction) => {
    /**
     * Watcher for Object
     */
    const watcher = (context: any) => (nameObject, setter) => {
        let value = context[nameObject];

        Object.defineProperty(context, nameObject, {
          get: () => value,
          set: (v) => {
            value = v;
            setter(value);
          }
        });
      };

    if (!window.backet) {
      window.backet = [];
    }

    if (!window.backet[name]) {
      window.backet[name] = [];

      const l = watcher(window.BACKEND.components);
      l(name, (v) => {
        window.backet[name].forEach((f) => {
          f(v);
        });
      });
    }

    window.backet[name].push(callbackFunction);

    /**
     *
     * Example:
     * window.backet['BACKEND'].push((v) => {
     *  var l = watcher(v.components);
     *
     *  l('catalogGrid', function (v) {
     *    init();
     *  });
     * });
     *
     */
  },
  // ! deprecated - use asyncWrapperPromise
  wrapperPromise: (wrapperData) => {
    const mutateRequest = wrapperData.mutateRequest;
    const mutateResponse = wrapperData.mutate;

    return (data, headers) => {
      const checkUrl = wrapperData.checkUrl && wrapperData.checkUrl(data);

      return new Promise((resolve, reject) => {
        if (mutateRequest && checkUrl) {
          data = mutateRequest(data, headers);
        }

        wrapperData.func(data, headers).then(
          (response: any) => {
            if (mutateResponse && checkUrl) {
              try {
                mutateResponse(response);
              } catch (error) {
                console.error('wrapperPromise mutate function error - ', error);
              }
            }

            resolve(response);
          },
          (error: any) => {
            reject(error);
          }
        );
      });
    };
  },
  asyncWrapperPromise: (wrapperData) => {
    const mutateRequest = wrapperData.mutateRequest;
    const mutateResponse = wrapperData.mutateResponse;

    return (data, headers) => {
      const checkUrl = wrapperData.checkUrl && wrapperData.checkUrl(data);

      return new Promise((resolve, reject) => {
        if (mutateRequest && checkUrl) {
          data = mutateRequest(data, headers);
        }

        wrapperData
          .func(data, headers)
          .then(
            async (response: any) => {
              if (mutateResponse && checkUrl) {
                try {
                  await mutateResponse(response);
                } catch (err) {
                  console.error('asyncWrapperPromise mutateResponse function error - ', err);
                }
              }
              resolve(response);
            },
            async (error: any) => {
              if (wrapperData.mutateError && checkUrl) {
                let response = null;
                try {
                  response = await wrapperData.mutateError(error, data.headers);
                } catch (err) {
                  console.error('asyncWrapperPromise mutateError error - ', err);
                }
                if (response) {
                  resolve(response);
                } else {
                  reject(response);
                }
                return;
              }
              reject(error);
            }
          );
      });
    };
  },
  snippetDDM: () => {
    const _variationsInfo = {}; //  eslint-disable-line

    // * для новой админки аб-тестов
    const _variationsAbInfo = {}; //  eslint-disable-line

    function getCookie(name) {
      const value = '; ' + document.cookie;
      const parts = value.split('; ' + name + '=');
      if (parts.length >= 2) {
        return decodeURIComponent(parts.pop().split(';').shift());
      }
      return null;
    }

    function loadVariationsInfoFromCookies(cookieName = USER_COOKIE_NAME_RANDOM, variationsInfo = _variationsInfo) {
      let cookieValue = getCookie(cookieName);
      if (cookieValue) {
        if (~cookieValue.lastIndexOf('"')) {
          cookieValue = cookieValue.replace(/"/g, '');
        }
        const experimentParts = cookieValue.split('|');

        for (const experiment of experimentParts) {
          const parts = experiment.split('=');
          variationsInfo[parts[0]] = Number(parts[1]);
        }

        // for (let j = 0; j < experimentParts.length; j += 1) {
        //   const parts = experimentParts[j].split('=');
        //   _variationsInfo[parts[0]] = Number(parts[1]);
        // }
      }
    }

    function getChosenVariation(experimentId, variationsInfo = _variationsInfo) {
      return (variationsInfo[experimentId] !== undefined) ? variationsInfo[experimentId] : -1;
    }

    window.DrivebackOnLoad = window.DrivebackOnLoad || [];

    window.testRandomize = (experimentId, handlers, params) => {
      const variation = getChosenVariation(experimentId);

      if (variation < 0) {
        console.log('Variants not found'); //  eslint-disable-line
      }

      if (variation >= 0) {
        if (handlers[variation] && typeof handlers[variation] === 'function') {
          try {
            handlers[variation](params);
          } catch (error) {
            console.error(error); //  eslint-disable-line
          }
        }
      }
    };

    // * для новой админки аб-тестов
    window.abTestRandomize = (experimentId, handlers, params) => {
      const variation = getChosenVariation(experimentId, _variationsAbInfo);

      if (variation < 0) {
        console.log('Variants not found'); //  eslint-disable-line
      }

      if (variation >= 0) {
        if (handlers[variation] && typeof handlers[variation] === 'function') {
          try {
            handlers[variation](params);
          } catch (error) {
            console.error(error); //  eslint-disable-line
          }
        }
      }
    };

    loadVariationsInfoFromCookies();
    loadVariationsInfoFromCookies(ALTERNATIVE_USER_COOKIE_NAME_RANDOM, _variationsAbInfo);

    document.addEventListener(EventTypes.INIT_SENTRY, (e: CustomEvent) => {
      experimentUtilities.sentryIsLoad = true;

      if (experimentUtilities.listCatchesForSentry.length) {
        experimentUtilities.listCatchesForSentry.forEach((errItem) => {
          fireNativeEvent(EventTypes.CAPTURE_EXCEPTION, errItem);
        });
        experimentUtilities.listCatchesForSentry = [];
      }
    });
  },
  getMobileOperatingSystem,
  LS: new LocalStorageModel({ prefix: null }),
  cookies: Cookies,
  toVw,
  getCookie: Cookies.get, // ! is deprecated, use only for support older ab-tests
  fireNativeEvent: (eventName, errorEvent) => {
    if (experimentUtilities.sentryIsLoad) {
      fireNativeEvent(eventName, errorEvent);
    } else {
      experimentUtilities.listCatchesForSentry.push(errorEvent);
    }
  },
  catchBugsInSentry: (func, param) => {
    let lineExperiment: string;
    let fileName: string;

    const err = new Error(`test error with ${param.codeExperiment}`);

    /**
     * for example
     * Error: test error with promo-back-to-school
     *   at Object.catchBugsInSentry (http://localhost:9000/dist/scripts/utilities.js:508:13)
     *   at test (http://localhost:9000/:59:41)
     *   at http://localhost:9000/:59:205
     */

    const line = err.stack
      .split('\n')
      .reverse()[0]
      .split(':')
      .reverse();

    lineExperiment = line[1];

    // get filename from string '   at http://localhost:9000/'
    fileName = line
      .slice(2) // remove two first numbers
      .reverse()
      .join(':')
      .split(' ')
      .reverse()
    [0];

    const errList: any = window.addEventListener('error', (target: ErrorEvent) => {
      if (target.lineno === Number(lineExperiment) && target.filename === fileName) {
        const errorMessage = `${target.message};\nCE: ${param.codeExperiment};\nCV: ${param.codeVariant};\ncolno: ${target.colno};`;
        const errorEvent = new ErrorEvent('error', {
          error: new Error(errorMessage),
          message: errorMessage,
          lineno: target.lineno,
          colno: target.colno,
          filename: target.filename,
        });
        experimentUtilities.fireNativeEvent(EventTypes.CAPTURE_EXCEPTION, errorEvent);
      }
    });
    func(param);
  },
  appendStyles(id: string, css: string) {
    const head = document.querySelector('head');

    if (!head) {
      return;
    }

    const style = document.createElement('style');
    style.id = id;
    style.innerHTML += css;
    head.appendChild(style);

    return style;
  },
  getUrlParams: () => {
    const search = window.location.search || '';
    const searchParams = window.m?.parseQueryString && window.m.parseQueryString(search);

    return searchParams;
  },

  /**
   * var mountPlace = document.querySelector('.mount-container'); // mount mithril
   * var insertBeforeElSelector = '.item__info.container';
   *
   * var element = document.createElement('div');
   * element.classList.add('container-open-in-app');
   * element.innerHTML = '<a class="button button-open-in-app" href="' + isTestItemPage.link +
   *   '" role="button" target="_blank">Открыть в приложении Kaspi.kz</a>';
   *
   * watchChildListMutations(
   *  mountPlace,
   *  insertBeforeElSelector,
   *  function() {
   *    mountPlace.insertBefore(element, document.querySelector(insertBeforeElSelector));
   *  }
   * );
   */
  watchChildListMutations: (target, waitingForElSelector, func) => {
    const callback = (mutationsList, observer: any) => {
      mutationsList.forEach((mutation) => {
        const waitingForEl = Object.values(mutation.addedNodes).find((item: any) => item.matches(waitingForElSelector)) as HTMLElement;

        if (waitingForEl) {
          func(waitingForEl);
          observer.disconnect();
        }
      });
    };

    new MutationObserver(callback)
      .observe(target, { childList: true });
  }
};

document.addEventListener(EventTypes.INIT_APP, () => {
  experimentUtilities.readyAppState = 'ready';
});

/**
 *  debugUtilities for cookies and components data
 */
export async function debugUtilities() {
  const search = experimentUtilities.getUrlParams() || {};

  if (search.debugger || search.rt) {
    const {
      debugUtilities: InitDebugger,
      tgSwitcher: InitTgSwitcher,
    } = await import(/* webpackChunkName: "debugger" */ './debugger');

    InitTgSwitcher();
    InitDebugger();
  }
}
